import React from 'react';
import Entities from '@toolkit/entities';
import _ from 'lodash';
import { Button } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import interiorDesigner from "@toolkit/lib/interiorDesigner";

class AccountCreated extends React.Component {

  constructor(props) {
    super(props);
      this.state = {
        
      }
    }

  getLabelValues = (formatter, _, transformers) => {
    return ({

    })
  }

  onClick = async () => {
    this.props.signInHelper.getOtp()
    this.props.history.push('/account/password')
  }

  renderSurface(values, {
    MainLayout,
    Layout,
    Block,
    Bits,
    View,
  }) {
    return (
      <MainLayout name='main-layout'>
        <View landing>
          <Layout name='login'>
            <Layout>
              <Block name="signin-close">
                <IconButton onClick={() => this.props.history.push('/cart')}>
                  <Bits.Icon name="close-icon" fontSize='medium'>Close</Bits.Icon>
                </IconButton>
              </Block>
              <Block>
                <Bits.Text type='h1' name='header'>
                  Successfully signed Up. Login with otp
                </Bits.Text>
                <Button onClick={this.onClick} variant="contained" endIcon={<ArrowForwardIcon />}  style={{ color:'white' ,background:interiorDesigner.colorPallete.primary}}>Send OTP</Button>
              </Block>
            </Layout>
            </Layout>
        </View>
      </MainLayout>
    );
  }


  getStylesheet = (colorPallet) => {
    return ({
      'login-header': {
        master: {
          display: 'flex',
          fontSize: 18,
        }
      },
      'signin-close': {
        master: {
          position: 'absolute',
          right: 20,
          top: 20,
          zIndex: 2
        },
        mobile: {
          right: 10,
          top: 10
        }
      },
      'close-icon': {
        master: {
          color: colorPallet.teritary
        }
      },
      'main-layout': {
        master: {
          backgroundColor: colorPallet.secondary,
        }
      },
      'signin-container': {
        master: {

          width: '100%'
          //margin:'50px 20px'
        }
      },
      'header': {
        master: {
          color: colorPallet.primary,
          marginRight: 56,
          zIndex: 1
        }
      },
      'submit': {
        master: {
          margin: 'auto'
        }
      },
      'login-message': {
        master: {
          display: 'flex',
          marginTop: 20
        }
      },
      'login-message-text': {
        master: {
          margin: 0,
          fontSize: 10,
          marginLeft: 10,
          color: colorPallet.primary
        }
      },
      'login': {
        master: {
          padding: '70px 40px',
          height: '100vh',
          maxWidth: '60%',
          margin: '0 auto',
        },
        mobile: {
          padding: '50px 20px',
          maxWidth: '100%',
          margin: '0px auto',
        },
        transition: 'slideUp'
      }
    })
  }
}


export default Entities.createPlace(AccountCreated, {
  name: 'AccountCreated'
});

// hasphoneauthenticated :