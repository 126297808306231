import React, { Component } from 'react';
import Entities from "@toolkit/entities";

export class InventoryImage extends Component {
    constructor(props) {
        super(props)
    }
    renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
        return (
            <Bits.Image noLazy name="media-block" src={this.props.url} />  
        )
    }

    getStylesheet = (colorPallete) => ({
        "media-block": {
            master: {
              width: "100%",
              height: "100%",
              objectFit: "contain",
            },
          },
    })
    
  
}

export default Entities.createPlace(InventoryImage, {
    name: "InventoryImage",
 });

