import React from 'react';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Chip, CircularProgress } from '@material-ui/core';
import _ from 'lodash';
import customFormHoc from './customFormHoc';
import { withFormsy } from 'formsy-react';
import { SelectNonFormInput } from './select';

// const filter = createFilterOptions();
// const [value, setValue] = React.useState('');
// const [key, updateKey] = React.useState(0)
const defaultProps = {
  freeSolo: true,
  selectedOptionProps: {}
}

class MultiSelect extends React.Component {

  constructor(props) {
    super(props);
    
    this.setValue(props.defaultValue || []);
  }


  setValue = (value) =>{
    this.props.setValue(value)
  }

  onSelect = (value) => {
    const list = this.props.value;
    this.setValue(_.concat(list, value));
  }

  onDelete = (option) => {
    const list = this.props.value;
    this.setValue(_.without(list, option.value))
  }

  render(){
    return (
      <div >
        <SelectNonFormInput
          key={_.size(this.props.value)}
          list={_.filter(this.props.list, i => !_.includes(this.props.value, i.value))}
          onChange={this.onSelect}
          label={this.props.label || "Choose tags"}
        />
        <div style={{ marginTop: 10, marinBottom: 10}}>
          {
            _.map(this.props.value, (selectedOption, index) => {
              const option = _.find(this.props.list, ({ value: selectedOption }));
              if (!option) return null;

              return (<Chip
                key={index}
                onDelete={() => this.onDelete(option)}
                className="margin-5"
                {...this.props.selectedOptionProps}
                variant="outlined"
                label={
                  <div className="flex-center">
                    <span>{option.label}</span>
                  </div>
              }/>)
            })
              
          }
        </div>
    </div>
  );
}
}

const nonFormsyHOC = customFormHoc(MultiSelect);

export { nonFormsyHOC as MultiSelect };
export default withFormsy(MultiSelect);
