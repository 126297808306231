import React from 'react';
import _ from 'lodash';
import createBit from '../lib/createBit';


const BulletList = ({ list }) => {
    return (
        <ul>
            {
                _.map(list, (item , index) => {
                    return <li key={index}>{item.label}</li>
                })
            }
        </ul>
    )
}

export default createBit(BulletList, {
    name: 'BulletList'
});