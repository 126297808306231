import { Avatar, CircularProgress } from "@material-ui/core";
import { Image } from "@material-ui/icons";
import { withFormsy } from "formsy-react";
import React, { Component } from "react";
import FileUploader from "react-firebase-file-uploader";
import customFormHoc from "./customFormHoc";
import _ from 'lodash'; 
import classnames from 'classnames';

class FirebaseFileUploader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      avatar: "",
      isUploading: false,
    };

    this.props.setValue(this.props.defaultValue);
  }

  handleUploadStart = () => {
    this.setState({ isUploading: true })
  };

  handleUploadError = error => {
    this.setState({ isUploading: false });
    throw error;
  };

  handleUploadSuccess = filename => {
    this.props.getFirebase()
    .storage()
    .ref(this.props.mediaHelper.uploadDestinationFolder)
    .child(filename)
    .getDownloadURL()
    .then(url => {
      this.props.onChange(url)
      this.props.setValue(filename)
      this.setState({
        avatar: filename,
        isUploading: false
      });
    });
  }

  getURL = () => {
    const value = this.props.value || null;

    if (value) {
      return this.props.mediaHelper.getOriginalFileURL(value);
    } else return null;
  }
 
  render() {
    return (
      <div className={classnames('f-f-uploader', this.props.className)}>
          <div className="f-f-upload-avatar">
              {
                  this.state.isUploading ?
                      <div className="f-f-progress" ><CircularProgress size="30px" thickness={5} color="primary" /> </div>
                    : <Avatar src={this.getURL()} className="f-f-upload-btn" color="secondary" variant="rounded" ><Image  className="f-f-upload-icon"  color="primary"/></Avatar>
              }
              {
                !this.props.value && (this.props.helperText && this.state.isUploading ? <p className="helper-text italic">Uploading..</p> : <p className="helper-text">{this.props.helperText}</p>)
              }
          </div>
        <div className="f-f-upload-hidden">
            <FileUploader
                accept="image/*"
                name={this.props.name}
                storageRef={this.props.getFirebase().storage().ref(this.props.mediaHelper.uploadDestinationFolder)}
                onUploadStart={this.handleUploadStart}
                onUploadError={this.handleUploadError}
                onUploadSuccess={this.handleUploadSuccess}
                filename={this.props.mediaHelper.generateRID}
          />
        </div>
      </div>
    );
  }
}

FirebaseFileUploader.defaultProps = {
  onChange: _.noop,
  onError: _.noop
}
 
export default {
    formInput: withFormsy(FirebaseFileUploader),
    input: customFormHoc(FirebaseFileUploader),
};