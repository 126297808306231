import React, { useState } from "react";
import Entities from "@toolkit/entities";
import { TextField, InputAdornment } from '@material-ui/core';
import _ from "lodash";
import AutoComplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import "./styles.scss";
import SearchIcon from "@material-ui/icons/Search";
import interiorDesigner from "@toolkit/lib/interiorDesigner";

const filterOptions = createFilterOptions({
  stringify: (option) => option.name + option.id,
});

//Create dropdown and pass dummy data

class SearchBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inventories: [],
    };
  }

  onChange = async (e, value) => {
    let searchedInventories = await this.props.onSearchInventory(value);
    const inventories = searchedInventories.map((item) => {
      return {
        label: item.name,
        id: item._id,
      };
    });
    this.setState({
      inventories,
    });
  };

  renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
    return (
      <Layout name="nav-search-outer">
        <AutoComplete
          className="search-autocomplete"
          freeSolo
          onInputChange={this.onChange}
          onChange={this.props.onSelectedOption}
          disablePortal
          id="search-input"
          // filterOptions={filterOptions}
          disableClearable
          options={this.state.inventories}
          getOptionLabel={(option) => option.label}
          // sx={{ width: 700 }}
          size="medium"
          renderInput={(params) => (
            <TextField
              {...params}
              className="search-input-contain"
              variant="filled"
              placeholder={this.props.placeholder}
              InputProps={{
                ...params.InputProps,
                type: "search",
                startAdornment: (
                  <SearchIcon  style={{
                      margin: "0px 7px 0px 0px",
                      color: interiorDesigner.colorPallete.subtext,
                    }}
                  />
                ),
              }}
              style={{ width: "100%" }}
            />
          )}
        />
      </Layout>
    );
  }

  defaultProps = {
    placeholder: 'Search for anything...',
  }

  getStylesheet = (colorPallete) => {
    return {
      "nav-search-outer": {
        master: {
          width: 450,
        },
        mobile: {
          width: 160,
        },
      },
    };
  };
}

export default Entities.createPlace(SearchBar, {
  name: "SearchBar",
});




