import GenerateNumber from '@utils/lib/generateNumber';
import api from '@api';
import tokenManager from '@utils/lib/tokenManager';
import OrdersHelper from './orderHelper';
import config from '@config/index';
import interiorDesigner from '@toolkit/lib/interiorDesigner'
import _ from 'lodash'
class TransactionHelper {
    
    constructor() {
        this.cart = {};
        this.transaction = {}
        this.orderHelper = new OrdersHelper()

        this.shop = config.getConfig('shop');
        this.razorpayCreds = config.getConfig('razorpay')
        // console.log(this.shop.paytm.mid)
    }

    createNewTransaction = async (cart, email, successCb, errorCb) =>{
        const generateNumber = new GenerateNumber()
        const number = generateNumber.generate()

        //var transaction = await api.Transactions.create()
        this.cart = cart;

        var checkOutDetails = {
            "websiteName"   : 'DEFAULT',
            "orderId"       : number,
            //"   : `${this.shop.webAddress}`,
            "txnAmount"     : {
                "value"     : this.cart.billableAmount,
                "currency"  : "INR",
            },
            "userInfo"      : {
                "custId"    : tokenManager.user,
                "email"     : email
            },
            "merchant":{
                "name": this.shop.name
              }
        }
        let response = await api.Transactions.initializeTransaction(checkOutDetails)
        this.initiateTransaction(response, checkOutDetails, async (paymentStatus) =>{
                await this.createTransaction(paymentStatus, number, (order, transaction) =>{
                    this.transaction = transaction
                    if(paymentStatus.STATUS === 'TXN_SUCCESS'){
                        successCb(order)
                    }else{
                        errorCb()
                    }
                
        }, () =>{errorCb()})
        
        //return orderDetails
    })
}

    initiateTransaction= (response,checkOutDetails, cb) =>{
        var config = {
            "root":"",
            "payMode": {
                "labels": {},
                "filter": {
                "exclude": []
                },
                "order": [
                    "CC",
                    "DC",
                    "NB",
                    "UPI",
                    "PPBL",
                    "PPI",
                    "BALANCE"
                ]
            },
             "merchant": {
                "mid": this.shop.paytm.mid,
                "name" : this.shop.name,
                "redirect": false
            },
            "data": {
                "token": response.body.txnToken, /* update token value */
                "tokenType": "TXN_TOKEN",
                ...checkOutDetails
            },
            "website": this.shop.webAddress,
            "flow": "DEFAULT",
            "handler": {
                "transactionStatus": function transactionStatus(paymentStatus){
                    try{
                        cb(paymentStatus)
                        window.Paytm.CheckoutJS.close()
                        // this.succeedTransaction(paymentStatus, successCb)
                    }catch(e){
                        console.log(e) 
                    }
                    
                    //successCb()
                },
                "notifyMerchant": function notifyMerchant(eventName,data){
                console.log("Closed",eventName,data);
                }
            }
        };
        
        if (window.Paytm && window.Paytm.CheckoutJS) {
            window.Paytm.CheckoutJS.init(config).then(function onSuccess() {
                window.Paytm.CheckoutJS.invoke();
            }).catch(function onError(error) {
                console.log("Error => ", error);
            });
        }	
    }

    createTransaction = async (paymentStatus, orderId, cb, eb) => {
        var data = {
            paymentId : paymentStatus.TXNID,
            status :_.split(paymentStatus.STATUS, '_')[1],
            externalPlatform :'PAYTM',
            checksumHash : paymentStatus.CHECKSUMHASH,
            initiatedBy : tokenManager.user,
            amount : parseInt(paymentStatus.TXNAMOUNT),

        }
        //var transaction = {}
        // let authentic_source = await api.Transactions.chechAuth(paymentStatus)
        // if(authentic_source){
        var transaction = await api.Transactions.create(data);
            if(_.split(paymentStatus.STATUS, '_')[1] === 'SUCCESS'){
                var order =await this.orderHelper.placeOrder(this.cart, transaction._id)
                cb(order,transaction)
            }else{
                eb(transaction)
            }
            //console.log(3)
        //}else{
            //console.log(cb)
            //eb(transaction)
        //}
    }

    findTransaction = async (query) => {
        const transaction = await api.Transactions.find({query})
        return transaction[0]
    }

    getTransactionById = async (id) =>{
        const transactionDetails = await api.Transactions.getById(id)
        return transactionDetails
    }

    // aptForRefund = async (order) =>{
    //     await api.Transactions.refund(order, this.razorpayCreds.key_id, this.razorpayCreds.key_secret)
    // }
}

export default TransactionHelper