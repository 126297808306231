import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from "react-router-dom";

export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onItemClick = (item) => () => {
    
    item.link ? history.push(item.link) : item.onClick();
    setAnchorEl(null);
  }
  return (
    <div>
    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
      {
        props.buttonLabel ||
        <MenuIcon fontSize="large" color='action'/>
      }
    </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
      {
        _.map(props.items.list, item=>{
          return(
            <MenuItem onClick={onItemClick(item)}>{item.label}</MenuItem>
          )
        })
      }
      </Menu>
    </div>
  );
}

