import React from 'react';
import Helper from '@core/helpers';
import toolkit from '@toolkit';
import transitionRegistry from '@toolkit/lib/transitionRegistry';
import persistentStorage from '@utils/lib/localStorage';
import UserDetails from '@core/modules/User/views/Places/create/userDetails'
import { Switch, Route } from 'react-router';
import AddAddress from '@core/modules/User/views/Places/address/addAddress';

class SignIn extends React.Component {

  constructor(props) {
    super(props);
      this.state = {
      
      }
      this.signinHelper = new Helper.UserSigninHelper()
  }

  async getData() {
    
  }

  getLabelValues = ( _, transformers) => {
    return ({
      
    })
}

  renderSurface(values, {

    }) {
    return (
      <Switch>
        <Route
          path="/createUser/details/:id"
          render={props => <UserDetails {...props} {...this.state}  signinHelper ={this.signinHelper}/>}
        />
        <Route
            strict
            exact
            path="/createUser/address"
            render={props => <AddAddress {...props} {...this.state} />}
        />
      </Switch>
    );
  }

  getStylesheet = (colorPallet) => {
    return ({
      heading: {
        master: {
          margin: '10px 20px'
        }
      },
      'main-container': {
        transtion: 'slideRight'
      }
    })
  }
  
}

export default  toolkit.Entities.createPlace(SignIn, {
  name: 'SignIn'
});
