import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import _ from 'lodash';
import Entities from "@toolkit/entities";

class DialogBox extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
        
      }
      
  }

  // DialogTitle = withStyles(styles)((props) => {
  //   const { children, classes, onClose, ...other } = this.props;
  //   return (
      
  //   );
  // });

  renderSurface(values, {
    MainLayout,
    Layout,
    Block,
    Bits,
    View,
    }) {
    return (
        <MainLayout>
          <Layout>
              <Block>
                <Dialog className="select-address" fullWidth onClose={this.props.handleClose} aria-labelledby="customized-dialog-title" open={this.props.open}>
                  <Block>
                    <Block name="dialogbox-title-block">
                      {this.props.heading && <Bits.Text name="dialogbox-title"  {...this.props}>{this.props.heading}</Bits.Text>}
                      {this.props.subText && <Bits.Text name='dialogbox-subtext'>{this.props.subText}</Bits.Text>}
                    </Block>
                    <Bits.IconButton onClick={this.props.handleClose} name='close-button'>
                      <Bits.Icon>Close</Bits.Icon>
                    </Bits.IconButton>  
                  </Block>                  
                  <Block name='render-content-block'>
                    {this.props.renderItem()}
                  </Block>
                </Dialog>
              </Block>
            </Layout>
        </MainLayout>
    );
}
getStylesheet = (colorPallete) => {
  return ({
    'dialogbox-title':{
      master:{
        marginTop: 8,
        fontWeight:600, 
        textAlign:'center', 
        color:'#498a31', 
        fontSize:24
      }
    },
    'dialogbox-subtext':{
      master:{
        marginTop: 12, 
        fontSize: 14, 
        color:'#498a31', 
        opacity:0.5
      }
    },
    'close-button':{
      master:{
        position:'absolute',
        top: 0,
        right: 0
      }
    },
    'render-content-block':{
      master:{
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    }


  })
}}

export default Entities.createPlace(DialogBox, {
    name: 'DialogBox'
});