import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';


export default (Component, showSpinner = true) => {
    return class extends Component {

        constructor(props) {
            super(props);
            this.state = {
                ...this.state,
                showSpinner,
                _dataFetched: false
            };
        }

        componentWillMount = async () => {
            this.setState({
                showSpinner,
            });
            await this.getData();
            this.setState({
                showSpinner: false,
                _dataFetched: true
            });
        }

        getData = async (...args) => {
            if (_.isFunction(this.componentGetData)) {
                await this.componentGetData(...args);
            }
        }

        render = () => {
            return !this.state._dataFetched ?
                this.state.showSpinner ? (<div style={{ position: 'absolute', top: '50%', left: '50%', transform: [{ translateX: '-50%' }, { translateY: '-50%' }] }}>
                    <CircularProgress color="#041f40"/>
                </div>) : null:
                this._render()
        }
    }
}