import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import config from '@config/index';
import _ from 'lodash';


class SentryTrace {

    constructor() {
        this.trace = Sentry;
    }

    wakeup = () => {
        if(config.getConfig('sentry')){
            Sentry.init({
                dsn: config.getConfig('sentry'),
                integrations: [new Integrations.BrowserTracing()],
                tracesSampleRate: 1.0,
            });
        }
    }
}

export default new SentryTrace();