import React from 'react';
import _ from 'lodash';
import { concatNameChain } from './utils';
import interiorDesigner from '@toolkit/lib/interiorDesigner';
import config from '@config';
import firebase from '@firebaseConnect';


export default (function (Component, options) {
    return class extends React.PureComponent {

        constructor(props) {
            super(props);
            const name = props.name || options.name;
            this.namechain = concatNameChain(this.props.namechain, name);
            this.displayName = name;
            this.config = config;

        }

        getSS = () => {
            return (this.props.stylesheet || {});
        }

        render() {
            const name = this.props.name;

            const styles = this.styles || interiorDesigner
                .parentStylesheet(this.getSS())
                .getStyles(name, this.props.style, this.namechain);
            
            if (!this.styles) this.styles = styles;
            return (
                <Component
                    getFirebase={function() { return firebase }}
                    config={this.config}
                    {...this.props}
                    style={styles}
                >
                </Component>
            );
        }
    }
})