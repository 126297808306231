import React from 'react';
import _ from 'lodash';
import api from '@api';
// import interiorDesigner from '@toolkit/lib/interiorDesigner';
import Entities from '@toolkit/entities';
import endpoints from '@api';
import Form from "@web-bricks/Shared/form/components/form";
import FormMaker from '@web-bricks/Shared/form/components/formMaker';
// import Button from '@web-bricks/Shared/Input/button';
import Container from "@core/modules/shared/views/Things/container.js";
import Helper from '@core/helpers';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
// const html = ''

class CreateDraft extends React.Component {
    constructor(props) { 
        super(props)
        this.state = {
             users : [],
             selectedUsers: {},
             showTemplateDetails : false,
             searchedTemplates: [],
             showUsersTabel : false,
             showTimeDate : false,
             showSaveAndSend : false,
             key : 0,
             showRecurrenceForm : false
        }

        this.templateHelper = new Helper.TemplateHelper()
        this.draftsHelper = new Helper.DraftsHelper()
    }

    async getData() { 
        const users = await endpoints.User.find({query:{}});
        const templates = await endpoints.Templates.find({query:{}});
        let list = []
        _.map(templates, temp => {
            let info ={label :temp.subject, value : temp._id }
            list.push(info)
        })
        this.onTemplateSearch('')
        this.setState({
            users: users,
            templates : templates,
            list : list,
            //reccurentFeilds : reccurentFeilds
        })
        //console.log(this.state.reccurentFeilds)
    };

     
    getHeaders = () => {
        const columns = _.concat(
            [
                {
                    field: 'name',
                    headerName: 'Name',
                    type: "string",
                    width: 200,
                },
                {
                    field: 'phone',
                    headerName: 'Phone',
                    type: "number",
                    width: 200,
                },
                {
                    field: 'email',
                    headerName: 'Email',
                    type: "string",
                    width: 300,
                },
                {
                    field: 'all',
                    headerName: 'ALL',
                    width: 200,
                    type: 'string'
                }
            ],
        );
        return columns
    };



    onSubmit = async (values, pureValues)  => {
        await this.draftsHelper.create(pureValues)
        this.goToView('/vendor/customerEngagement')
    }

    onSelectionModelChange = (selectedUsers) => {
        this.setState({
            selectedUsers: selectedUsers
        })
    }


    getRows = (users) => {
           const userslist =  _.map((users), (user) => ({
                id: user._id,
                ...user,
            }))
        return userslist
    }

  
    getLabelValues = (formatter, _, transformers) => {
        return ({

        })
    }

    onTemplateSearch = async (input) => {
        let searchedTemplates = await this.templateHelper.search(input)
        searchedTemplates= _.map(searchedTemplates, template => ({
            label: template.subject,
            value: template._id
        }))
        searchedTemplates.push({label:'Add new template', value : 'NEW'})
        this.setState({
            searchedTemplates : searchedTemplates
        })
    }

    onUserSelect = (values) =>{
        if(values.target.value !== 'all'){
            this.setState({
                showUsersTabel: true
            })
        }
    }

    onScheduledraft = (values) =>{
        if(values.target.value === 'now'){
            this.setState({
                showSaveAndSend : true,
                showTimeDate : false,
                showRecurrenceForm : false
            })
        }else if(values.target.value === 'recurrence'){
            this.setState({
                showRecurrenceForm : true,
                showTimeDate : false,
                showSaveAndSend : false
            })
        }else{
            this.setState({
                showTimeDate: true,
                showSaveAndSend : false,
                showRecurrenceForm : false
            })
        }
    }

    onClickChip = (data) =>{
        if(!_.isNil(data)){
            if(data.value === 'NEW'){
                this.props.history.push('/vendor/template')
            }else{
            let pickedTemplate = _.find(this.state.templates, {_id : data.value})
            this.setState({
                showTemplateDetails : true,
                pickedTemplate: pickedTemplate
            })
        }
        }else{
            this.setState({
                showTemplateDetails : false,
            })
        }
       
    }

    onFrequencyChange = (value) =>{
        this.setState({
            frequency : value
        })
    }
    
    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        View,
        Bits,
        Switch
    }) {
        return (
            <MainLayout>
                <Container 
                    page={{ 
                        title: 'Create Draft',
                        secondaryText: 'Create and save drafts to manage customer engagement'
                    }}
                    previousPage={[{title:'Drafts', onClick: () => this.props.history.push('/vendor/customerengagement')}]}
                    activePage='Create draft'
                    rightButton={{ buttonLabel: 'Templates', onClick: () => this.goToView('/vendor/templateList') }}
                >    
                <View>
                    <Layout>
                            <Block name="user-list">
                                <Form 
                                    key = {this.state.key}
                                    submitButton={{ label: this.state.showSaveAndSend ? 'save & send' :'save & continue', variant: 'contained' }}
                                    onSubmit={this.onSubmit} 
                                >
                                    <FormMaker fields={[
                                        {
                                            type: 'AutoComplete',
                                            title: 'Pick a template',
                                            props: {
                                                //label: 'Select Template',
                                                placeholder: `search 'sale'`,
                                                name: 'template',
                                                isSearch: true,
                                                fullWidth:true,
                                                onChange: (value) => this.onClickChip(value),
                                                onInputChange: _.debounce(
                                                    (event) => this.onTemplateSearch(event),
                                                    1000
                                                  ),
                                                options: this.state.searchedTemplates,
                                                required: true,
                                                getOptionLabel: (option) => option,
                                                getOptionSelected: (option) => {
                                                    return option.label;
                                                },
                                            }
                                          
                                        },
                                        
                                        this.state.showTemplateDetails && {
                                               
                                                type: 'Block',
                                                props: {
                                                    render: () => {
                                                      return (
                                                        <Block>
                                                            <Bits.Text name='template-type'>{this.state.pickedTemplate.type}</Bits.Text>
                                                            <Bits.Text name='template-subject'>{_.capitalize(this.state.pickedTemplate.subject)}</Bits.Text> 
                                                            <Bits.Text name='template-body' dangerouslySetInnerHTML={{__html: this.state.pickedTemplate.body}} />
                                                        </Block>
                                                      )
                                                    }
                                                  }
                                                
                                        },
                                        {
                                            type: 'radioGroup',
                                            title: 'Schedule Draft',
                                            props: {
                                                onChange: this.onScheduledraft,
                                                displayInRow: true,
                                                name: 'schedule',
                                                required: true,
                                                list: [{
                                                    label: 'Send now',
                                                    value: 'now',
                                                },
                                                {
                                                    label: 'Schedule',
                                                    value: 'schedule',
                                                    //disabled : true
                                                },
                                                // {
                                                //     label: 'Recurrence',
                                                //     value: 'recurrence',
                                                //     //disabled : true
                                                // }
                                            ],
                                            }
                                        },
                                        this.state.showTimeDate && 
                                        { 
                                            type: 'DateTime',
                                            title:'Pick date & time',
                                            props: {
                                                dateTimePicker: true,
                                                name: 'scheduledAt',
                                                //label: 'Date & Time',
                                            }
                                        },
                                        this.state.showRecurrenceForm &&
                                        {
                                            type: 'select',
                                            props: {
                                                label: 'Repeat',
                                                name: 'frequency',
                                                list: [{label:"Monthly", value:"MONTHLY"},{label:"Weekly", value: "WEEKLY"},{label:"Daily", value :'DAILY'}],
                                                onChange: this.onFrequencyChange
                                            }
                                        },
                                        this.state.frequency === 'MONTHLY' && this.state.showRecurrenceForm &&
                                            {
                                                type: 'SingleGroupLine',
                                                //title: '',
                                                props: {
                                                    name: 'recurrence',
                                                    passCurrentValues: true,
                                                    //required: true,
                                                    radioButton : false,
                                                    list: [{
                                                        fields: [{
                                                            type: 'Block',
                                                            props: {
                                                                children: 'every '
                                                            }
                                                            }, {
                                                                type: 'textField',
                                                                props: {
                                                                    name: 'recurrence.INTERVAL',
                                                                    //label: 'frequenc',
                                                                    wrapperClass: 'coupon-field',
                                                                }
                                                            },{
                                                                type: 'Block',
                                                                props: {
                                                                    children: 'monthly(s)'
                                                                }
                                                            }
                                                        ]},
                                                    ]
                                                }
                                            },
                                            this.state.frequency === 'MONTHLY' &&  this.state.showRecurrenceForm &&
                                            {
                                                type: 'SingleGroupLine',
                                                //title: '',
                                                props: {
                                                    name: 'monthly',
                                                    passCurrentValues: true,
                                                    //required: true,
                                                    radioButton : true,
                                                    list: [{
                                                        fields: [{
                                                            type: 'Block',
                                                            props: {
                                                                children: 'on day '
                                                            }
                                                        },{
                                                            type: 'select',
                                                            props: {
                                                                //label: 'Operator',
                                                                name: 'recurrence.BYMONTHDAY',
                                                                list: [
                                                                    {label:"1", value:"1"},{label:"2", value: "2"},{label:"3", value :'3'} ,{label:"4", value:"4"},{label:"5", value: "5"},{label:"6", value :'6'} ,
                                                                    {label:"7", value:"7"},{label:"8", value: "8"},{label:"9", value :'9'} ,{label:"10", value:"10"},{label:"11", value: "11"},{label:"12", value :'12'} ,
                                                                    {label:"13", value:"13"},{label:"14", value: "14"},{label:"15", value :'15'} ,{label:"16", value:"16"},{label:"17", value: "17"},{label:"18", value :'18'} ,
                                                                    {label:"19", value:"19"},{label:"20", value: "20"},{label:"21", value :'21'} ,{label:"22", value:"22"},{label:"23", value: "23"},{label:"24", value :'24'} ,
                                                                    {label:"25", value:"25"},{label:"26", value: "26"},{label:"27", value :'27'} ,{label:"28", value:"28"},{label:"29", value: "29"},{label:"30", value :'30'} ,
                                                                    {label:"31", value :'31'}
                                                                ]
                                                            }
                                                        }]
                                                    }]
                                                }
                                            },
                                            this.state.frequency === 'MONTHLY' && this.state.showRecurrenceForm &&
                                            {
                                                type: 'SingleGroupLine',
                                                //title: '',
                                                props: {
                                                    name: 'monthly',
                                                    passCurrentValues: true,
                                                    //required: true,
                                                    radioButton : true,
                                                    list: [{
                                                        fields: [{
                                                            type: 'Block',
                                                            props: {
                                                                children: 'on the '
                                                            }
                                                        },{
                                                            type: 'select',
                                                            props: {
                                                                //label: 'Operator',
                                                                name: 'recurrence.BYSETPOS',
                                                                list: [
                                                                    {label:"First", value:"first"},{label:"Second", value: "second"},{label:"Third", value :'third'} ,{label:"Fourth", value:"fourth"},{label:"Last", value: "last"}   
                                                                ]
                                                            }
                                                        },{
                                                            type: 'select',
                                                            props: {
                                                                //label: 'Operator',
                                                                name: 'recurrence.BYDAY',
                                                                list: [
                                                                    {value:"MO", label:"monday"},
                                                                    {value:"TU", label: "tuesday"},
                                                                    {value:"WE", label :'wednesday'} ,
                                                                    {value:"TH", label:"thursday"},
                                                                    {value:"FR", label: "friday"},
                                                                    {value:"SA", label: "saturday"},
                                                                    {value:"SU", label: "sunday"}   
                                                                ]
                                                            }
                                                        },
                                                    ]}
                                                ]}
                                            },
                                            this.state.frequency === 'WEEKLY' && this.state.showRecurrenceForm &&
                                            {
                                                type: 'SingleGroupLine',
                                                //title: '',
                                                props: {
                                                    name: 'recurrence',
                                                    passCurrentValues: true,
                                                    //required: true,
                                                    radioButton : false,
                                                    list: [{
                                                        fields: [{
                                                            type: 'Block',
                                                            props: {
                                                                children: 'every '
                                                            }
                                                            }, {
                                                                type: 'textField',
                                                                props: {
                                                                    name: 'recurrence.INTERVAL',
                                                                    //label: 'frequenc',
                                                                    wrapperClass: 'coupon-field',
                                                                }
                                                            },{
                                                                type: 'Block',
                                                                props: {
                                                                    children: 'week(s)'
                                                                }
                                                            }
                                                        ]},
                                                    ]
                                                }
                                            },
                                            this.state.frequency === 'WEEKLY' && this.state.showRecurrenceForm && {
                                                type: 'checkboxList',
                                                props: {
                                                    name: 'recurrence.BYDAY',
                                                    displayInRow: true,
                                                    sortByLabel: false,
                                                    list: [
                                                        {value:"MO", label:"monday"},
                                                        {value:"TU", label: "tuesday"},
                                                        {value:"WE", label :'wednesday'} ,
                                                        {value:"TH", label:"thursday"},
                                                        {value:"FR", label: "friday"},
                                                        {value:"SA", label: "saturday"},
                                                        {value:"SU", label: "sunday"} 
                                                    ]
                                                }
                                            },
                                            this.state.frequency === 'DAILY' && this.state.showRecurrenceForm &&
                                            {
                                                type: 'SingleGroupLine',
                                                //title: '',
                                                props: {
                                                    name: 'recurrence',
                                                    passCurrentValues: true,
                                                    //required: true,
                                                    radioButton : false,
                                                    list: [{
                                                        fields: [{
                                                            type: 'Block',
                                                            props: {
                                                                children: 'every '
                                                            }
                                                            }, {
                                                                type: 'textField',
                                                                props: {
                                                                    name: 'recurrence.INTERVAL',
                                                                    //label: 'frequenc',
                                                                    wrapperClass: 'coupon-field',
                                                                }
                                                            },{
                                                                type: 'Block',
                                                                props: {
                                                                    children: 'days(s)'
                                                                }
                                                            }
                                                        ]},
                                                    ]
                                                }
                                            },
                                            {
                                                type: 'radioGroup',
                                                title: 'Send to',
                                                props: {
                                                    onChange: this.onUserSelect,
                                                    displayInRow: true,
                                                    name: 'sendTo',
                                                    //required: true,
                                                    list: [{
                                                        label: 'All',
                                                        value: 'all',
                                                    },
                                                    {
                                                        label: 'Include',
                                                        value: 'include',
                                                    },{
                                                        label: 'Exclude',
                                                        value: 'exclude',
                                                    }],
                                                }
                                            },
                                            this.state.showUsersTabel &&
                                            {
                                                type: "DataTable", 
                                                title: 'Customer info', 
                                                props: {
                                                    name: 'userList',
                                                    columns: this.getHeaders(),
                                                    handleSelectionModelChange: this.onSelectionModelChange,
                                                    rows: this.getRows(this.state.users),
                                                    checkboxSelection : true
                                                }
                                            },
                                        ]} 
                                    />   
                            </Form>
                        </Block>
                    </Layout>
                </View>
            </Container>
        </MainLayout>
        )
    }

    getStylesheet = (colorPallete) => {
        return({
            'template-type':{
                master:{
                    fontSize:12,
                    color:colorPallete.subtext,
                    margin:0
                }
            },
            'template-subject':{
                master:{
                    fontFamily:"Times New Roman",
                    fontSize: 24,
                    fontWeight: 'bold',
                    margin:0
                }
            },
            'template-body':{
                master:{
                    margin:0,
                    fontFamily:"Times New Roman",
                    fontSize: 18,
                }
            },
            'add-template':{
                master:{
                    display:'flex',
                    justifyContent:'flex-end'
                }
            },
            'template-button': {
                master:{
                    padding:'30px 0px'
                }
            },
            'button-flex-align': {
                master: {
                    display: 'flex',
                    justifyContent: 'space-between'
                }
            },
            'action-buttons': {
                master: {
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    margin: '0 auto',
                    width: '50%'
                },
                mobile: {
                    width: '100%'
                }
            },
        })
    }
    
}


export default Entities.createPlace(CreateDraft, {
    name: 'CreateDraft'
})