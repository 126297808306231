import React from "react";
import _ from 'lodash';
import Form from '@web-bricks/Shared/form/components/form';
import FormMaker from "@web-bricks/Shared/form/components/formMaker";
import interiorDesigner from '@toolkit/lib/interiorDesigner';
import Helper from '@core/helpers'
import Entities from '@toolkit/entities';

class FilterList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            list : []
        }
        this.inventoryHelper = new Helper.InventoryHelper()
        this.filterHelper = new Helper.FilterHelper()
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({

        })
    }

    getFields = (values, label) =>{
        let list = []
        _.forEach(values, item =>{
            let info = {
                label : _.capitalize(item.value),
                value : item.value,
            }
            list.push(info)
        })
        let fields =[]
        const item =  {
            type:'checkboxList',
            props: {
                name: label,
                // label : 'Mark this as delivering address',
                list : list,
                defaultValue: _.map(_.filter(values, 'opted'), 'value')
            }
        }
        fields.push(item)
        return fields;
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }) {
        if(this.props.list.length>0){
            return(
                <MainLayout>
                    <View>
                        <Layout>
                            <Block name='filter-list'>
                                <Form
                                    submitButton={{
                                        label: 'Filter now',
                                        variant: 'outlined',
                                        className: "filter-submit"
                                    }}
                                    noValidate onSubmit={this.props.onSubmit}
                                >
                                    <Block name='filter-items'>
                                    {
                                        _.map(this.props.list, (item, index) =>{
                                            return (item.values.length > 0) && (
                                                <Block key={index} name = 'item-block'>
                                                     <Bits.Text name = "label" >{_.upperFirst(item.label)}</Bits.Text>
                                                    <FormMaker fields={this.getFields(item.values, item.label)} />
                                                </Block>
                                            )
                                        })
                                    }
                                    </Block>
                                </Form>
                            </Block>
                        </Layout>
                    </View>
                </MainLayout>
            )
        }else{
            return(
                <p>Nothing to filter!</p>
            ) 
        }
    }

    getStylesheet = (colorPallete) => {
        return ({
            'item-block':{
                master:{
                    display: 'inline-block'
                }
            },
            'filter-list':{
                master:{
                    overflow: 'auto',
                }
            },
            'filter-items':{
                master:{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 1fr))'
                }
            },
            'label': {
                master: {
                    margin: '5px 0px',
                    padding: '5px 20px',
                    fontSize: 16,
                    fontWeight: 'bolder',
                    backgroundColor: '#735d3c4a',
                    border: '1px solid #bfbfbf',
                    color: colorPallete.primary
                }
            }
        })
    }
}

export default Entities.createPlace(FilterList, {
    name: 'FilterList'
});
