import React from "react";
import _ from "lodash";
import Helper from "@core/helpers";
import Entities from "@toolkit/entities";
import ItemModal from "@core/modules/Inventory/views/Things/itemModal";


class InventoryViewById extends React.Component {
  constructor(props) {
    super(props);
    console.log(props.match.params.id)
    this.state = {
     inventoryId : props.match.params.id,
     openView : true
    }
    this.inventoryHelper = new Helper.InventoryHelper();
  }

  async getData() { 
    //let inventoryName = _.replace(this.state.inventoryName, '-', ' ')
    //let inventory = await this.inventoryHelper.getInventoryById(this.state.inventoryId)
    let expandedInventory = await this.inventoryHelper.getExpandedInventory(this.state.inventoryId)
    let groupedInventories = await this.inventoryHelper.getByLabelGroups([expandedInventory])
    let value
    _.mapValues(groupedInventories , values =>{
      value = values
    })
    console.log(value)
    this.setState({
        inventory : expandedInventory,
        groupedInventories: groupedInventories,
        group: value
    })
  }

  getLabelValues = (_, transformers) => {
   
  };

  pushToCart = () => {
    this.goToView("/cart");
  };

  onClose = () => {
    this.setState({
      openView: false
    });
    this.goToView("/");
  }

  cartUpdate = (count, cartItem) => {
    Helper.CartHelper.updateCart(count, cartItem)
  }

  imageClick = (group) => {
    this.props.onClick(group)
    this.setState({
        group: group
    })
  }

  // onClick = async () => {
  //   // let link = await api.Inventories.getHtml('http://34.93.118.183:81/inventory/Jowar-flour')
  //   await api.Inventories.getHtml('Jowar-flour')
  //   window.navigator.share({url :`https://smartshop-cloud.ebrdesignstudio.in/inventory/getHtml/Jowar-flour`, title:'fb share'})
  // }

  renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
    return (
      <MainLayout>
        <Layout name="modal-container">
          <Block>
          <ItemModal
              open={this.state.openView}
              onBack={this.onClose}
              cart={this.props.cart}
              cartHelper={Helper.CartHelper}
              onClick={this.imageClick}
              onCountChange={this.cartUpdate}
              onStackEmpty={this.onClose}
              group={this.state.group}
              groupedinventories={this.state.groupedInventories}
          /> 
          </Block>
        </Layout>
      </MainLayout>
    );
  }

  getStylesheet = (colorPallete) => {
    return {

    }
  };
}

export default Entities.createThing(InventoryViewById, {
  name: "InventoryViewById",
});
