import _ from 'lodash'
import config from '@config/index'
import api from '@api'
import persistentStorage from '@utils/lib/localStorage'

class ShopHelper {

    constructor() {
        this.shopDetails = {}
        this.shopId = config.getConfig('shopId');
        //console.log(this.shopId)
    }

    async fetchMyShopDetails() {
        const info = await api.ShopDetails.getById(this.shopId)
        this.shopDetails = info
        return info;
    }

    findUser = async (data) => {
        let query = {
            "phone" : _.toString(data)
        }
        let user = await api.User.find({query})
        return user[0]
    }

    isOwner = (staff) =>{
        const isOwner = _.includes(staff.roles, 'OWNER');
        return isOwner
    }


    getDeliveryZones = async () =>{
        const shopDetails = await this.fetchMyShopDetails()
        let ZonesList = []
        _.map(shopDetails.deliveryZones, Zones => {
            const label = Zones.zone
            const value = Zones._id
            const item = {label, value }
            ZonesList.push(item)
        })
        return ZonesList
    }

    updateShopDetails = async (data) =>{
        const shopDetails = await this.fetchMyShopDetails()
        await api.ShopDetails.update(shopDetails._id, data)
    }

    updateBankDetails = async (values) =>{
        const shopDetails = await this.fetchMyShopDetails()
        let message = {
            body :`${shopDetails.shopName} requested to change bank details with account number : ${values.accountNumber}, beneficiary: ${values.beneficiary}, IFSC code: ${values.IFSC}`,
            subject:'Request to change bank account details'
        }
        await api.ShopDetails.sendRequestMail(message)
    }

    updateOtherDetails = async (values) =>{
        const shopDetails = await this.fetchMyShopDetails()
        let message = {
            body :`${shopDetails.shopName} requested to change the following details. GSTIN : ${values.GSTIN}, fsaai: ${values.fsaai}`,
            subject:'Request to change details'
        }
        await api.ShopDetails.sendRequestMail(message)
    }

    addZones = async (values) =>{
        let zipCodes = _.map(values.zipCodes, code => {
            return parseInt(code)
        })
        const data ={
            zone : values.zoneName,
            charge : parseInt(values.ZoneCharges),
            estTime : values.estTime,
            zipCodes : zipCodes
        }
        await api.ShopDetails.UpdateZones(this.shopId, data)
    }

    editZone = async (values, zoneId) =>{
        let zipCodes = _.map(values.zipCodes, code => {
            return parseInt(code)
        })
        const data = {
            zone : values.zoneName,
            charge : parseInt(values.ZoneCharges),
            _id : zoneId,
            estTime : values.estTime,
            zipCodes : zipCodes
        }
        await api.ShopDetails.editZones(this.shopId, data)
    }

    setZone = (zipCode) =>{
        let data = {
            selectedDeliveryZone : zipCode
        }
        persistentStorage.set(data)
    }

    hasDeliveryZones = async () =>{
        const shopDetails = await this.fetchMyShopDetails()
        if(shopDetails.deliveryZones && (shopDetails.deliveryZones.length > 0)){
            if(!_.isNull(persistentStorage.get("selectedDeliveryZone"))){
                return false
            }
            else {
                return true
            }
        }else return false
        
    }

    getDeliveryCharges = async (zipCode) =>{
        const shopDetails = await this.fetchMyShopDetails()
        let charge = 0
        if(shopDetails.deliveryZones && shopDetails.deliveryZones.length > 0){
            const selectedDeliveringZone =  zipCode || persistentStorage.get("selectedDeliveryZone") 
            _.map(shopDetails.deliveryZones, zone =>{
                const flag = _.includes(zone.zipCodes, parseInt(selectedDeliveringZone))
                if(flag){
                    charge = zone.charge
                }
            });

        }else{
            charge = 0
        } 
        return charge
    }

    getZoneDetails = async (id) =>{
        const shopDetails = await this.fetchMyShopDetails()
        let zone = {}
        _.map(shopDetails.deliveryZones, item =>{
            if(item._id == id){
                zone = item
            }
        })
        return zone
    }

    getZoneName = async  (zoneId) =>{
        const shopDetails = await this.fetchMyShopDetails()
        let name = ''
        _.map(shopDetails.deliveryZones, item =>{
            if(item._id == zoneId){
                name = item.zone
            }
        })
        return name
    }

    getStaff = async () =>{
        const staff = await api.User.getStaffDetails()
        return staff
    }

    checkZoneAvailability = async (zipCode) =>{
        const shopDetails = await this.fetchMyShopDetails()
        let zip 
        _.forEach(shopDetails.deliveryZones , zone =>{
            const falg = _.includes(zone.zipCodes , parseInt(zipCode))
            if(falg){
                zip = parseInt(zipCode)
            }
        })
        if(zip){
            return zip
        }else return null
    }

    changeReferralStatus = (status) => {
        api.ShopDetails.changeReferralStatus( { status : status ? 'ON' : 'OFF'})
    }
    getReferralFeatureStatus = () => {
        // const info = api.ShopDetails.getById(this.shopId)
        // const status = info.referralSetting.status
        // return status

        // const status = await api.get(this.shopId, ShopDetails.referralSetting.status)
        // return status
    }

    deleteZone = async(zone) =>{
        await api.ShopDetails.deleteDeliveryZone(config.getConfig('shopId'), zone)
    }
}

export default ShopHelper
