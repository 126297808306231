import React from 'react';
import _ from 'lodash';
import { withFormsy } from 'formsy-react';
import CustomFormHOC from './customFormHoc';

class TextArea extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            value: props.value || props.defaultValue,
        }
        this.changeValue = this.changeValue.bind(this);
        this.props.setValue(this.props.value);
    }

    changeValue(e){
        const value = e.target.value;
        this.setState({ 
            value,
        })
        this.props.setValue(value);
        this.props.onChange(value, e);
    }

    componentWillReceiveProps(nextProps){
        if(this.props.value !== nextProps.value){
            this.setState({ 
                value: nextProps.value,
            })
            this.props.setValue(nextProps.value);
        }
    }

    render() {
        return (
            <div className="textarea__container caption">
                <textarea
                    {...this.props}
                    className = {this.props.className}
                    name={this.props.name}
                    disabled={!this.props.edit}
                    placeholder={this.props.placeholder}
                    onFocus={this.props.onFocus}
                    onBlur={this.props.onBlur}
                    onChange={this.changeValue}
                    value={this.state.value}
                />
            </div>
        );
    }
}


TextArea.defaultProps = {
    onBlur: _.noop,
    onFocus: _.noop,
    edit: true,
    onChange: _.noop,
};

export default {
    formInput: withFormsy(TextArea),
    input: CustomFormHOC(TextArea),
};
