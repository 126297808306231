import React from 'react';
import { Motion, spring } from 'react-motion';
import './transition.css';

const transitionRegistry = {
    'slideUp': function ({ render, index }) {
        return (
            <div className="transition-slideUp" >
                {render()}
            </div>
        )
    },
    'slideDown': function ({ render, index }) {
        return (
            <div className={`transition-slideDown`} >
                {render()}
            </div>
        )
    },
    'slideRight': function ({ render, index }) {
        return (
            <div className="transition-slideRight" >
                {render()}
            </div>
        )
    },
    'fadeIn': function ({ render, index }) {
        return (
            <div className="transition-slideLeft">
                {render()}
            </div>
        )
    },
}

export default transitionRegistry;
