import React from 'react';
import _ from 'lodash';
import Entities from "@toolkit/entities";
import InventoryMedia from "@core/modules/Inventory/views/Pieces/inventoryMedia";
import withCart from "@core/modules/Cart/views/lib/withCart";



class SecondarySelfItem extends React.Component {

    constructor(props) {
      super(props);
    }
  
    renderSurface(values, {
      MainLayout,
      Layout,
      Block,
      Bits
    }) {
      const { inventory } = this.props;
      return (
        <MainLayout name="secondary-info-container">
          <Layout
            name="secondary-info-block"
            onClick={this.expandView}
          >
            <Block name="grid-block-image">
              <InventoryMedia media={inventory.media} />
            </Block>
            <Block name="grid-blocks">
              <Bits.Text name="grid-block-text">
                {_.capitalize(inventory.label)}
              </Bits.Text>
              <Block name="counter-block">
                {/* <Bits.Text name="grid-block-price">
                  &#8377;
                  {`${_.capitalize(inventory.rates.saleRate)}`}
                </Bits.Text> */}
                <Bits.Currency
                    name="grid-block-price"
                    value={`${_.capitalize(inventory.rates.saleRate)}`}
                />
                {this.renderQuickAdd(false)}
              </Block>
            </Block>
            </Layout>
            {this.renderExpandView()}
        </MainLayout>
      );
    }
  
    getStylesheet = (colorPallete) => ({
      "counter-block": {
        master: {
          display: "flex",
          justifyContent: "space-between",
        },
      },
      'secondary-info-container': {
        master: {
          width: '100%'
        }
      },
      "secondary-shelf": {
        master: {
          boxSizing: "border-box",
          marginBottom: 50,
          width: "100%",
        },
        mobile: {
          margin: 0,
          padding: "0 0px",
          marginLeft: 0,
        },
        transition: "children-slideUp",
      },
      "grid-block": {
        master: {
          margin: 15,
        },
      },
      "grid-block-image": {
        master: {
          width: 150,
          height: 150,
          background: "#f9f1e9",
          borderRadius: 4,
          cursor: "pointer",
          padding: "50px 30px",
        },
        mobile: {
          width: 100,
          height: 100,
          padding: "40px 25px",
        },
      },
      "grid-block-more": {
        master: {
          width: 210,
          fontWeight: 600,
          color: colorPallete.primary,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        },
        mobile: {
          width: 100,
          height: 100,
          padding: "40px 20px",
        },
      },
      "grid-block-text": {
        master: {
          textTransform: "capitalize",
          textAlign: "left",
          fontSize: 16,
          fontWeight: 600,
          color: colorPallete.black,
          maxWidth: 210,
          margin: "7px 0px",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        mobile: {
          fontSize: 14,
        },
      },
      "grid-block-price": {
        master: {
          margin: 0,
          fontSize: 13,
          color: colorPallete.Black,
        },
      },
      "secondary-info-block": {
        master: {
          marginRight: 40,
          display: "inline-block",
        },
        mobile: {
          marginRight: 20,
          width: 150,
        },
      },
      "secondary-shelf-item-grid": {
        master: {
          justifyContent: "flex-start",
          whiteSpace: "nowrap",
          padding: "10px 0px 20px 0px",
          display: "flex",
          overflow: "auto",
          alignItems: "center",
        },
        mobile: {
          padding: 20,
        },
        transition: "children-slideUp",
      },
    })
  }
  
export default Entities.createThing(withCart(SecondarySelfItem), {
    name: 'SecondarySelfItem'
  })