// takeaway there could be ORDER - PROCESS - READY TO TAKE AWAY - TOOK AWAY
// for internal delivery - ORDER - PROCESS-DISPATCHED - DELIVERED
// for external delivery - ORDER - PROCESS - READY TO DISPATCH - DISPATCHED - DELIVERED

const external_delivery_process = [
    {
        status : "ORDERED",
        next : "Processing",
        setStatus : "PROCESSING",
        label: 'Order Placed',
    },
    {
        status : "PROCESSING",
        next : "Ready to dispatch",
        setStatus : "READY_TO_DISPATCH",
        label: 'Processing'
    },
    {
        status : "READY_TO_DISPATCH",
        next : "Dispatching",
        setStatus : "DISPATCHED",
        label: 'Ready to dispatch'
    },
    {
        status: "DISPATCHED",
        next : "Delivery",
        setStatus : "DELIVERED",
        label: 'Out for delivery'
    },
    {
        status:"DELIVERED",
        label: 'Delivered'
    },
]

const internal_delivery_process = [
    {
        status : "ORDERED",
        next : "Processing",
        setStatus : "PROCESSING",
        label: 'Order Placed',
    },
    {
        status : "PROCESSING",
        next : "Dispatching",
        setStatus : "DISPATCHED",
        label: 'Processing'
    },
    {
        status: "DISPATCHED",
        next : "Delivery",
        setStatus : "DELIVERED",
        label: 'Out for delivery'
    },
    {
        status:"DELIVERED",
        label: 'Delivered'
    },
]

const takeawayProcess = [
    {
        status : "ORDERED",
        next : "Processing",
        setStatus : "PROCESSING",
        label: 'Order Placed'
    },
    {
        status : "PROCESSING",
        next : "Take away",
        setStatus : "READY_TO_TAKEAWAY",
        label: 'Processing'
    },
    {
        status:"READY_TO_TAKEAWAY",
        next : "Took away",
        setStatus: 'TOOK_AWAY',
        label: 'Ready to take away'
    },
    {
        status:"TOOK_AWAY",
        label: 'Took Away'
    },
]

const cancelledProcess = [
    {
        status : "ORDERED",
        next : "Processing",
        setStatus : "CANCELLED",
        label: 'Order cancelled'
    },
    {
        status:"CANCELLED",
        setStatus: 'CANCELLED',
        label: 'Order is cancelled'
    },
]

export default {
    takeawayProcess,
    external_delivery_process,
    internal_delivery_process,
    cancelledProcess
}