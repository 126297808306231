import React from 'react';
import Entities from '@toolkit/entities';
import TextBanner from './message';
import MultiImages from './multiImages';

class TextWithImage extends React.PureComponent {
    constructor(props) {
        super(props)
    }
    renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
        return (
            <Layout name="intro-layout">
                {/* <Block name="text-block">
                    <Bits.Text name="text-line">Website story lines will be displayed here</Bits.Text>
                </Block> */}
                <TextBanner storyLine={this.props.storyLine} caption={this.props.caption}/>
                <Layout name="intro-image-outer">
                    {/* <Block name="intro-image" /> */}
                    <MultiImages images={this.props.images}/>
                </Layout>
                
            </Layout>
            
        )
    }

    getStylesheet = (colorPallete) => {
        return {
            'intro-layout': {
                master: {
                    display: 'flex',
                    height: '100%',
                }
            },
            // 'text-block': {
            //     master: {
            //         display: 'flex',
            //         justifyContent: 'center',
            //         alignItems: 'center',
            //         margin: '0px 50px'
            //     },
            // },
            // 'text-line': {
            //     master: {
            //         textAlign: 'center',
            //         fontSize: 74,
            //         lineHeight: '100px',
            //         fontWeight: 'bold',
            //         fontFamily: 'Quicklight',
            //     }
            // },
            'intro-image-outer': {
                master: {   
                    minWidth: '45%',
                    maxWidth: '50%',
                    height: '100vh',
                }
            },
            'intro-image': {
                master: {
                    width: '100%',
                    height: '100%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }
            }
        }
    }
}

export default Entities.createPlace(TextWithImage, {
    name: "TextWithImage",
  });
