import api from '@api';
import _ from 'lodash';
import InventoryHelper from './inventoryHelper';

class TagHelper {

    constructor() {
        this.inventoryHelper = new InventoryHelper()
    }


    getAllTags = async () =>{
        const tags = await api.Tags.find({query:{}})
        return tags
    }

    createTag = async (values) =>{
        const data = {
            label : values.label,
            type : values.type,
            color : values.color
        }
        await api.Tags.create(data)
    }

    editTag = async (values, id) =>{
        const data = {
            label : values.label,
            type : values.type,
            color : values.color
        }
        await api.Tags.update(id, data)
    }

    deleteTag = async (id) =>{
       await api.Tags.deleteMany([id]) 
    }

    getTagOptions = async () =>{
        const tags = await api.Tags.find({query:{}})
        let list = []
        _.map(tags, tag =>{
            let data = {
                label : tag.label,
                value : tag._id
            }
            list.push(data)
        })
        return list
    }

    getTags = async (tags) =>{

        let tagsList = []
        let allTags = await this.getAllTags()
        _.map(tags, tag =>{
            const list = _.find(allTags, {_id : tag})
            tagsList.push(list)
        })
        return tagsList
    }

    getAllTagsWithInventories = async (query) =>{
        const inventriesByTag = await api.Tags.getInventoriesByTag({query})
        return inventriesByTag
    }
}

export default TagHelper
