import React from 'react';
import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
// import TagFacesIcon from '@material-ui/icons/TagFaces';
import { withFormsy } from 'formsy-react';
import customFormHoc from './customFormHoc';
import _ from 'lodash';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing.unit / 2,
  },
  chip: {
    margin: theme.spacing.unit / 2,
  },
});

class ChipsArray extends React.Component {
  state = {
    chipData: [ ],
  };

  componentDidMount(){
      this.setState({ chipData : this.props.list});
  }

  handleDelete = data => () => {
      alert('Why would you want to delete React?! :)', data); // eslint-disable-line no-alert
      return;

    this.setState(state => {
      const chipData = [...state.chipData];
      const chipToDelete = chipData.indexOf(data);
      chipData.splice(chipToDelete, 1);
      return { chipData };
    });
  };

  onClick = (value) =>{
    this.props.onClick(value)
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper >
        {this.state.chipData.map(info => {
          let icon = null;
          return (
            <Chip
            style={{margin:5}}
              key={info.id}
              icon={icon}
              label={info.data}
              onDelete={this.props.delete && this.handleDelete(info.data)}
              onClick = {() => this.onClick(info.id)}
              //className={classes.chip}
            />
          );
        })}
      </Paper>
    );
  }
}

ChipsArray.defaultProps = {
  classes: PropTypes.object.isRequired,
  onKeyDown: _.noop,
  onEnter: _.noop,
  onInteractionComplete: _.noop,
  onChange: _.noop,
};

const nonFormsyHOC = customFormHoc(ChipsArray);

export { nonFormsyHOC as ChipsArray };
export default withFormsy(ChipsArray);