import React from 'react';
import _ from 'lodash';
import Entities from "@toolkit/entities";
import SliderComp from "@web-bricks/Shared/Image/slidercomp";
import Helper from "@core/helpers";
import PrimaryShelfItem from "@core/modules/shared/views/Things/primaryShelfItem";

class ProductSliderForPrimaryShelf extends React.Component {


    constructor(props) {
        super(props);
    }


    getLabelValues() {
        return ({
            topShelfItems: this.props.topShelfItems,
        })
    }


    renderSurface(values, {
        Bit,
        Layout,
        MainLayout,
        Block,
    }) {
        return (
            <MainLayout>
                <SliderComp>
                    {_.map(values.topShelfItems, (shelfItem, index) => <PrimaryShelfItem
                        key={index}
                        shelfItem={shelfItem}
                        {...Helper.CartHelper.makeProps([shelfItem])}
                    />)}
                </SliderComp>
            </MainLayout>
        )

    }
}


export default Entities.createThing(ProductSliderForPrimaryShelf, {
    name: 'ProductSliderForPrimaryShelf'
})


/*{values.topShelfItems && (
                    <Layout
                    onClick={() => this.onItemClick(values.topShelfItems[0])}
                    name="front-courousel"
                    >
                    <Block name="priamry-image-block">
                        <InventoryMedia
                        media={values.topShelfItems[0].media}
                        name="primary-item-image"
                        />
                    </Block>

                    <Layout name="primary-item-name-block">
                        <Block>
                        <Bits.Text name="primary-block-title" type="p">
                            Exclusives
                        </Bits.Text>
                        </Block>
                        <Bits.Text name="name" type="p">
                        {_.capitalize(values.topShelfItems[0].label)}
                        </Bits.Text>
                        <Bits.Text name="description" type="p">
                        {_.capitalize(values.topShelfItems[0].description)}
                        </Bits.Text>
                        <Bits.Text
                        name="moreInfo"
                        type="p"
                        onClick={() =>
                            this.props.onItemClick(values.topShelfItems[0])
                        }
                        >
                        Know More
                        </Bits.Text>
                    </Layout>
                    </Layout> */