import React from 'react';
import { Checkbox } from '@material-ui/core';
import _ from 'lodash'
import { withFormsy } from 'formsy-react';


class GroupLine extends React.PureComponent {


    constructor(props) {
        super(props);

        this.state = {
            selectedLines: this.getDefaultSelectedLines()
        }

        if (!_.isEmpty(this.props.defaultValue)) {
            this.props.setValue(this.props.defaultValue)
        }
    }

    getDefaultSelectedLines = () => {
        if (!_.isEmpty(this.props.defaultValue)) {
            const selectedLines = _.filter(_.map(this.props.defaultValue, (lineValue) => {
                return this.getLineIndexFromValue(lineValue)
            }), _.isNumber)

            return selectedLines;

        } else return [];
    }

    getLineIndexFromValue = (lineValue) => {
        return _.findIndex(this.props.list, line => _.get(line, `defaultValue.${this.props.lineKey}`) === lineValue[this.props.lineKey])
    }

    componentDidUpdate = (prevProps) => {
        if (!_.isEqual(prevProps.formCurrentValues, this.props.formCurrentValues)) {
            let value = _.cloneDeep(this.props.value);
            value = _.map(value, (v, k) => {

                const indexInList = _.findIndex(this.props.list, i => (_.get(i, 'defaultValue.fact') === v.fact))
                return indexInList > -1 ? ({
                    ...v,
                    ..._.get(this.props.formCurrentValues, `__${this.props.name}.${indexInList}`, {})
                }) : null
            })
            this.props.setValue(_.compact(value))

            
        }
    }


    onLineActivate = (group, index) => (event) => {
        const isChecked = event.target.checked;
        let value = _.cloneDeep(this.props.value || []);

        if (isChecked) {
            value = _.uniqBy(_.concat(value, {
                ...group.defaultValue,
                ..._.get(this.props.formCurrentValues, `__${this.props.name}.${index}`, {})
            }), this.props.lineKey);

            this.setState({
                selectedLines: _.concat(this.state.selectedLines, index)
            })
        } else {
            value = _.reject(value, item => {
                return item[this.props.lineKey] === group.defaultValue[this.props.lineKey];
            })
            this.setState({
                selectedLines: _.pull(this.state.selectedLines, index)
            })
        }

        this.props.setValue(value);
    }

    getLineValueFromObj = (group) => {
        return _.find(this.props.defaultValue, lineValue => {
            return _.isEqual(_.get(lineValue, this.props.lineKey), _.get(group, `defaultValue.${this.props.lineKey}`));
        })
    }

    render() {
        const FormMaker = this.props.FormMaker;
        return (
            <div>
                {
                    _.map(this.props.list, (group, index) => {
                        const isSelected = _.includes(this.state.selectedLines, index)
                        const lineValue = this.getLineValueFromObj(group)
                        return (
                            <div className="group-line flex-middle">
                                <Checkbox
                                    onChange={this.onLineActivate(group, index)}
                                    checked={isSelected}
                                />
                            <div className={!isSelected && 'line-disabled'}>
                                    {FormMaker({
                                        ...group,    
                                        wrapperClassName: 'flex-middle',
                                        fields: _.map(group.fields, (field, fieldIndex) => {

                                            return ({
                                                ...field,
                                                style:  field.type === 'HiddenInput' ? {} : {
                                                    minWidth: field.type !== 'Block' && 150,
                                                    margin: '0px 10px'
                                                },
                                                props: {
                                                    ...field.props,
                                                    name: `__${this.props.name}.${index}.${field.props.name}`,
                                                    defaultValue: _.get(lineValue, `${field.props.name}`),
                                                    required: isSelected
                                                }
                                            })
                                        }),
                                    })}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}


export default withFormsy(GroupLine);