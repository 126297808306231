import React from "react";
import _ from "lodash";
import { Tabs as OrgTabs, Tab } from "@material-ui/core";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import Entities from '@toolkit/entities';

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      isForward: true,
    };
    //console.log(this.props, this.porps.history)
  }

  onChange = (e, tabIndex) => {
    const isForward = tabIndex > this.state.tabIndex;
    this.setState({
      tabIndex,
      isForward,
    });
  };

  async getData() {
    const pathname = this.props.location.pathname;
    const lastRoute = _.last(_.split(pathname, "/"));

    const currentActiveTabIndex = _.findIndex(this.getList(), {
      path: lastRoute,
    });
    this.setState({
      tabIndex: currentActiveTabIndex === -1 ? 0 : currentActiveTabIndex,
    });
  };

  getList = () => _.compact(_.flatten(this.props.list));

  onAddNewTab = () => {
    this.props.onAddNewTab();
  };

  onCloseTab = (e, tab) => {
    e.stopPropagation();
    this.props.onRemoveTab(tab);
  };

  renderSurface(values, {
    MainLayout,
    Layout,
    Block,
    Bits,
    View,
    Switch
  }) {
    const tabList = this.getList();
    return _.isEmpty(tabList) ? null : (
      <div style={{ width: "100%" }} className={this.props.className}>
        <OrgTabs
          variant="scrollable"
          onChange={this.onChange}
          className="slide-children-top"
          value={this.state.tabIndex}
          orientation={this.props.orientation || "horizontal"}
        >
          {_.map(tabList, (tab, index) => {
            return (
              <Tab
                key={index}
                onClick={() => {
                  this.props.history.push(`${this.props.match.path}/${tab.path}`);
                }}
                {...tab}
                label={
                  tab.count ? (
                    <div>
                      <span>{tab.label}</span>
                    </div>
                  ) : (
                    <div className="flex-middle">
                      <span style={{ fontSize: 18 }}>{tab.label}</span>
                      <CloseIcon
                        onClick={(e) => this.onCloseTab(e, tab)}
                        style={{ fontSize: 24, paddingLeft: 10 }}
                      />
                    </div>
                  )
                }
                index={index}
              ></Tab>
            );
          })}
        </OrgTabs>
        <div
          style={{ width: "100%" }}
          className={classnames(
            this.state.isForward ? "slide-left" : "slide-right",
            "full-width"
          )}
        >
          <Switch {...this.props.history}>
            {_.map(tabList, (tab, index) => {
              return (
                <Route {...tab} key={index} path={`${this.props.match.path}/${tab.path}`} />
              );
            })}
            <Redirect
              from={this.props.match.path}
              to={`${this.props.match.path}/${_.first(tabList).path}`}
            />
          </Switch>
        </div>
      </div>
    );
  }
  getStylesheet = (colorPallete) => {
    return ({
    })
  }
}

export default Entities.createThing(Tabs, {
  name: 'Tabs'
});

