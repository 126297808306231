import tokenManager from '@utils/lib/tokenManager';
import shopHelper from './ebrShopHelper'
import api from '@api';
import _ from "lodash";
import persistentStorage from '@utils/lib/localStorage';
import Helper from '@core/helpers'

class UserSigninHelper {

    constructor() {
        this.state = {
            key: null,
            // password: null,
        };
        this.shopHelper = new shopHelper();
        this.phoneNumber = ''
    }
    
    updateUser = async (values) => {
        const data = {
            name : values.fullName,
            phone: _.toString(values.phone),
            email: values.email,
            registrationStatus: values.registrationStatus ? values.registrationStatus : 'PENDING',
            registrationCompletedAt: values.registrationCompletedAt ? values.registrationCompletedAt :null,
            referrerCode: values.referrerCode ? values.referrerCode : '',
        }
        if(values.role){
            data.role = values.role
        }
        let userId = persistentStorage.get("userDetails")._id
        await api.User.update(userId, data)
        let user = await api.User.getById(userId)
        this.setUser(user)
        this.store(user)
    }

    onUserLookup = async (data)=> {
        this.state.key = data;
        let user = await api.User.signIn(data)
        this.setUser(user)
        //scb() 
    }

    // setRegistrationStatus = (data) =>{
    //     persistentStorage.set({"completedRegistration": data.status})
    // }

    onLookUpVendor = async (data, scb, ecb) =>{
        this.state.key = data
        let shopDetails = await this.shopHelper.fetchMyShopDetails()
        let staff = _.find(shopDetails.staff, {phone:data})
        if(!_.isNil(staff)){
            let user = await api.User.create({phone:data, role:'ADMIN', registrationStatus:'PENDING'})
            let info ={
                owner : user._id
            }
            await api.ShopDetails.update(shopDetails._id, info)
            this.sendOtp(data)
            this.setUser(user)
            scb()
        }else{
            ecb()
        }
        
    }

    store = (response) => {
        const objectToStore = {
            user: response._id,
            //email: response.email,
            //token: response.token,
            //avatar: response.profile.avatar || '',
            name:  response.name ,
            phone:  response.phone,
            type: response.role
        };
        tokenManager.init(objectToStore);
    }
    
    onOtpCheck = async (code, cb) => {
        let user = persistentStorage.get('userDetails')
        let result = await api.User.checkOTP(code, user._id)
        if(result.output === 'MATCHED'){
            if(user.registrationStatus === 'COMPLETED'){
                this.store(user)
                this.removeUserDetails()
                cb(user.role)
            }else{
                cb('NEW-USER')
            }
        }else{
            cb(result.output)
        }
    }

    sendOtp = async (data) =>{
        let user = await api.User.signIn(data)
    }

    signOut = () => {
        Helper.CartHelper.clearCart()
        persistentStorage.remove('cart')
        persistentStorage.remove('name')
        persistentStorage.remove('phone')
        persistentStorage.remove('type')
        persistentStorage.remove('selectedDeliveryZone')
        tokenManager.reset();
    }

    getUserById = async (_id) => {
        const user = await api.User.getById(_id)
        return user
    }

    storePhoneNumber = (number) =>{
        persistentStorage.set({"phoneNumber": number})
    }

    getPhoneNumber = () =>{
        return persistentStorage.get("phoneNumber")
    }

    setUser = (user) =>{
        persistentStorage.set({"userDetails": user})
    }

    removeUserDetails = () =>{
        persistentStorage.remove('userDetails')
        persistentStorage.remove('phoneNumber')
    }

    getOtp = async () =>{
        let user = persistentStorage.get('userDetails')
        await this.sendOtp(user.phone)
    }
}

export default UserSigninHelper
