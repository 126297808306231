import React from 'react';
import toolkit from '@toolkit';
import LoginPage from '@core/modules/User/views/Places/login/logInPage';
import CreateUser from '@core/modules/User/views/Places/create/createUser';
import Cart from '@core/modules/Cart/views/Places/cart';
import Categories from '@core/modules/Shop/views/Places/categories';
import AddAddress from '@core/modules/User/views/Places/address/addAddress';
import _ from 'lodash';
import EditProfile from '@core/modules/User/views/Places/Profile/editProfile';
import Profile from '@core/modules/User/views/Places/Profile/profile';
import ManageAddresses from '@core/modules/User/views/Places/address/manageAddresses';
import OrderSummary from '@core/modules/Order/views/Places/orderSummary';
import AllOrders from '@core/modules/Order/views/Places/allOrders';
import frontWall from '@core/modules/Shop/views/Places/frontwall';
import ShopSections from '@core/modules/Shop/views/Places/sections';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { AccountCircle, Apps, OfflineBolt, FontDownload } from '@material-ui/icons';
import tokenManager from '@utils/lib/tokenManager';
import SearchPage from '@core/modules/Shop/views/Places/search';
import AboutUs from '@core/modules/Shop/views/Places/aboutUs';
import ContactUs from '@core/modules/Shop/views/Places/contactUs';
import PrivacyPolicy from '@core/modules/Shop/views/Places/privacyPolicy';
import RefundPolicy from '@core/modules/Shop/views/Places/refundPolicy';
import shippingPolicy from '@core/modules/Shop/views/Places/shippingPolicy';
import TermsConditions from '@core/modules/Shop/views/Places/terms&conditions';
import AddCategory from '@core/modules/Vendor/views/Places/addCategory'
import ManageTags from '@core/modules/Vendor/views/Places/manageTags'
import VendorEntry from '@core/web/lib/prefetcher';
import RouteRenderer from './routeRenderer';
import ReviewOrder from '@core/modules/Review/views/Places/reviewOrder'
import VendorSignIn from '@core/modules/Vendor/views/Places/vendorLogin/vendorSignin';
import TemplateList from '@core/modules/Vendor/views/customerEngagement/templates/templateList';
import InventoryView from '@core/modules/Inventory/views/Things/inventoryView'
import AddTemplate from '@core/modules/Vendor/views/customerEngagement/templates/addOrUpdateTemplate';
import CreateDraft from '@core/modules/Vendor/views/customerEngagement/drafts/createDraft';
import CustomerEngagement from '@core/modules/Vendor/views/customerEngagement';
import InventoryViewById from '@core/modules/Inventory/views/Things/inventoryViewById'

const routes = [
    {
        path:"/item/:name",
        comp:InventoryView
    },
    {
        path:"/product/:id",
        comp:InventoryViewById
    },
    {
        path:"/orderSummary/writeReview/:_id",
        comp: ReviewOrder
    },
    {
        path:'/signup/vendor',
        comp: VendorSignIn,
        hideNav: true
        // withNav: false,
        // exact:true
    },
    {
        path: "/aboutUs",
        comp: AboutUs,
    },
    {
        path: '/manageTags',
        comp: ManageTags,
        bottomNavSection: 'Dashboard',
    },
    {
        path: "/contactUs",
        comp: ContactUs,
        bottomNavSection: 'Profile',
    },
    {
        path: '/manageTags',
        comp: ManageTags,
        bottomNavSection: 'Dashboard',
    },
    {
        path: "/termsOfService",
        comp: TermsConditions,
        bottomNavSection: 'Profile',
    },
    {
        path: "/refundCancellationPolicy",
        comp: RefundPolicy,
        bottomNavSection: 'Profile',
    },
    {
        path: "/privacyPolicy",
        comp: PrivacyPolicy,
        bottomNavSection: 'Profile',
    },
    {
        path: "/shippingPolicy",
        comp: shippingPolicy,
        bottomNavSection: 'Profile',
    },
    {
        path: '/addCategory/:_id?',
        comp: AddCategory,
        bottomNavSection: 'Dashboard',
    },
    {
        path: '/search',
        comp: SearchPage,
    },
    {
        path: "/allOrders",
        comp: AllOrders,
        bottomNavSection: 'Profile',
    },
    {
        path: "/account",
        comp: LoginPage,
        hideNav: true
    },
    {
        path: "/orderSummary/:_id",
        comp: OrderSummary
    },
    {
        path: "/shop/:_id",
        comp: ShopSections,
        withNav: false,
        bottomNavSection: 'Store',
    },
    {
        path: "/manageAddresses",
        comp: ManageAddresses,
        bottomNavSection: 'Profile'
    },
    {
        path: "/profile",
        comp: Profile,
        bottomNavSection: 'Profile'
    },
    {
        path: "/vendor",
        comp: VendorEntry,
        withDrawer: true,
    },
    {
        path: "/createUser",
        comp: CreateUser,
        withNav: false,

    },
    {
        path: "/cart",
        comp: Cart,
        hideNav: true,
    },
    {
        path: "/categories",
        comp: Categories,
        withNav: true,
        bottomNavSection: 'Menu',
        hideBackButton: true
    },
    {
        path: "/addAddress/:_id?",
        comp: AddAddress
    },
    {
        path: "/edit",
        comp: EditProfile
    },
    {
        path: "/",
        comp: frontWall,
        bottomNavSection: 'Store',
    },
    {
        path:'/vendor/customerEngagement',
        comp: CustomerEngagement,
        bottomNavSection: 'Dashboard'
    },
    {
        path:'/vendor/templateList',
        comp: TemplateList,
        //bottomNavSection: 'Dashboard'
    },
    {
        path: "/vendor/draft/create",
        comp: CreateDraft
    },
    {
        path: "/vendor/template/:_id?",
        comp: AddTemplate
    },
    {
        path: "/vendor/templateList",
        comp: TemplateList
    }
];

class Routes extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeBottomNavSection: 'Store',
        };
    }


    list = [
        {
            label: 'Store',
            name: 'Store',
            icon: <StorefrontIcon />,
            path: '/'
        }, {
            label: 'Menu',
            name: 'Menu',
            icon: <Apps />,
            path: '/categories'
        }, tokenManager.isStaff() ? {
            label: 'Dash',
            name: 'Dashboard',
            icon: <OfflineBolt />,
            path: '/vendor/dashboard'
        } : {
                label: 'Profile',
                name: 'Profile',
                icon: <AccountCircle />,
                path: '/profile'
            },
    ];

    goBack = (state) => {
        if (state === true) {
            // <ArrowBackIcon onClick={() => this.history.push('/vendor')} />
        }
    }

    getLabelValues() {
        return ({
            activeBottomNavSection: this.state.activeBottomNavSection,
        })
    }

    setNavSection = (section) => {
        this.setState({
            activeBottomNavSection: section
        })
    }

    renderSurface(values, {
        Layout,
        MainLayout,
        Block,
        Bits,
        View
    }) {
        return (
            <MainLayout>
                <RouteRenderer
                    routes={routes}
                    activeBottomNavSection={values.activeBottomNavSection}
                    setNavSection={this.setNavSection}
                />
                {
                    values.activeBottomNavSection &&
                        <Block name="app-bottom-nav-container">
                            <Bits.BottomNav
                                name="app-bottom-nav"
                                activeSection={values.activeBottomNavSection}
                                list={this.list}
                                defaultPath='/'
                                onChange={(item) => {
                                    if (item)
                                        this.goToView(item.path)
                                }}
                            />
                        </Block>
                }
            </MainLayout>
        );
    }

    getStylesheet() {
        return ({
            'app-bottom-nav': {
                master: {
                    root: {
                        position: 'fixed',
                        display: 'flex',
                        flexDirection: 'column',
                        ZIndex: 2
                    },
                    action: {
                        root: {

                        }
                    }
                }
            }
        })
    }
}

export default toolkit.Entities.createPlace(Routes, {
    name: 'EntryPoint'
});