import React from "react"
import _ from 'lodash';
import tokenManager from '@utils/lib/tokenManager';
import Avatar from '@web-bricks/Shared/view/components/customAvatar';
import Form from '@web-bricks/Shared/form/components/form'
import FormMaker from '@web-bricks/Shared/form/components/formMaker'
import Helper from '@core/helpers';
import Entities from '@toolkit/entities';
import transitionRegistry from '@toolkit/lib/transitionRegistry';
import Container from '@core/modules/shared/views/Things/container.js';


class EditProfile extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            profileId: tokenManager.user,
            user: {}
        }
        this.helper = new Helper.UserSigninHelper()
        this.userHelper = new Helper.UserHelper()
    }

    onProfileEdit = async (values) => {
        const updatedInfo = {
            name: values.name,
            email: values.email ? values.email : null,
        }
        await this.userHelper.updateUserProfile(updatedInfo)
        this.props.history.push('/profile')
    }

    async getData() {
        const user = await this.helper.getUserById(tokenManager.user)
        const fields = [
            {
                type: "textField",
                props: {
                    name: "name",
                    label: "Full Name",
                    defaultValue: user.name ? user.name : null
                }
            },
            {
                type: "textField",
                props: {
                    name: "email",
                    label: "Email id",
                    defaultValue: user.email ? user.email : null
                }
            },
            {
                type: "textField",
                props: {
                    name: "phone",
                    label: "Phone Number",
                    defaultValue: user.phone ? user.phone : null,
                    disabled: true
                }
            },
        ]
        this.setState({
            user: user,
            fields: fields
        })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({
            fields: this.state.fields
        })
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }) {
        return (
            <Container
            page={{ title: 'Edit Profile' }}
            previousPage={[{title:'Profile', onClick: () => this.props.history.push('/profile')}]}
            activePage={'Edit'}
        >
                <View>
                    <Layout name="edit-container">
                        <Layout name='avatar'>
                            <Block name='profile-avatar'>
                                <Avatar user={this.state.user} />
                            </Block>
                        </Layout>
                        <Layout>
                            <Block name="edit-form">
                                <Form submitButton={{ label: 'Update' }} onSubmit={this.onProfileEdit}>
                                    <FormMaker Transition={transitionRegistry.slideDown}  fields={values.fields} />
                                </Form>
                            </Block>
                        </Layout>
                    </Layout>
                </View>
            </Container>
        )
    }

    getStylesheet = () => {
        return ({
            'main-layout': {
                master: {
                    maxWidth: '95%',
                    margin: '40px auto',
                }
            },
            'top-bar-outer': {
                master: {
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative'
                }
            },
            'top-bar-heading': {
                master: {
                    width: '100%',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    fontSize: 30,
                    textAlign: 'center',
                    zIndex: -1
                }
            },
            'edit-container': {
                master: {
                    marginTop: 30,
                },

            },
            'avatar': {
                master: {
                    width: '100%',
                    margin: '0 auto'
                }
            },
            'profile-avatar': {
                master: {
                    margin: 'auto',
                    width: '30%'
                }
            },
            'back': {
                master: {
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                },
                mobile: {
                    fontSize: 16
                }
            },
            'header': {
                master: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxWidth: '25%',
                }
            },
            'edit-header': {
                master: {
                   
                },
                mobile: {
                }
            },
            "edit-form": {
                master: {
                    width: 300,
                    margin: '0 auto'
                },
                mobile: {

                }
            },
            'top-bar': {
                master: {
                    cursor: 'pointer'
                }
            }
        })
    }

}

export default Entities.createPlace(EditProfile, {
    name: 'EditProfile'
});
