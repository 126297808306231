import React from "react";
import Slider from "react-slick";
import './styles.scss';


export default function ImageSlider(props) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    autoplay: true,
    className: 'image-list'
  };
  const { images } = props;
  return (
    <Slider {...settings}>
        {images.map((img) => <div><img src={img}/></div>)}
    </Slider>
  );
}