import { propTypes } from "formsy-react"
import React from "react"


const BackgroundVideo = (props) => (
    <div id="theater" style={{ width: '100%', height : '100%'}}>
        <video
            autoPlay loop muted playsInline webkit-playsInline
            preload="auto"
            src={props.link} 
            // controls="false" 
            style={{ width: '100%', top:'0', left: '0%', objectFit: 'cover', zIndex: '-1' , height:'100%'}}>
        </video>
        <br />
    </div>
)


export default BackgroundVideo