import React from "react";
import _ from "lodash";
import withSpinner from "@web-bricks/modules/components/withSpinner";
import Layout from "../surfaces/Layout";
import Block from "../surfaces/Block";
import * as Bits from "../bits";
import { concatNameChain } from "./utils";
import { Route, Switch, withRouter } from "react-router-dom";
import interiorDesigner from "@toolkit/lib/interiorDesigner";
import config from "@config";
import toolkit from '@toolkit'
import WidgetManager from "./widgetManager";
import PropTypes from 'prop-types';

export default function (Component, options) {

  const getComponentDefaultProps = () => {

    const defaultPropsFromSchema = Component.prototype.getPropsSchema ? _.mapValues(Component.prototype.getPropsSchema(), p => p['default']) : {}
    const defaultProps = _.merge({}, Component.defaultProps || {}, _.omitBy(defaultPropsFromSchema, _.isUndefined))
    return defaultProps;
  }
  const entityPropsFromConfig = interiorDesigner.getPropsFromConfig(options.name)
  const hasGetData = _.isFunction(Component.prototype.getData);
  const mainconfig = config.getConfig();
  class Entity extends Component {
    name = options.name;
    namechain = concatNameChain(this.props.namechain, options.name);

    constructor(props) {
      super(props);

      if (!this.state) this.state = {};

      this.state.gotData = !hasGetData;

      if (this.getStylesheet)
        this.getStylesheet = this.getStylesheet.bind(this);

      this.getRouteParams = this.getRouteParams.bind(this);
      this.placeWidget = this.placeWidget.bind(this);
      this.setNextRoute();
    }

    goToView = (viewName) => {
      this.props.history.push(`${viewName}${this.props.location.search}`);
    };

    goBack = () => {
      this.props.history.goBack()
    }

    getSS = () => {
      return _.merge(
        {},
        this.getStylesheet ? this.getStylesheet(interiorDesigner.colorPallete, config.getConfig()) : {},
        this.props.stylesheet || {}
      );
    };

    getBits = () => {
      const self = this;
      return _.mapValues(Bits, function (Bit, index) {
        return function (props) {
          return (
            <Bit
              key={index}
              {...props}
              stylesheet={self.getSS()}
              namechain={`${self.namechain}`}
            />
          );
        };
      });
    };

    getLocationSearchParams(key) {
      const urlSearchTerm = this.props.location && this.props.location.search;
      if (urlSearchTerm) {
        const a = JSON.parse(
          '{"' +
            decodeURI(urlSearchTerm.substring(1))
              .replace(/"/g, '\\"')
              .replace(/&/g, '","')
              .replace(/=/g, '":"') +
            '"}'
        );

        if (key) {
          return _.get(a, key)
        } else {
          return a;
        }
      } else {
        return {}
      }
    }

    getRouteParams(key) {
      return _.get(this.props.match.params, key)
    }

    setNextRoute = () => {
      
      const a = this.getLocationSearchParams()
      this.nextRoute = a.nextRoute;
    };

    onComplete = ({ nextRoute }) => {
      const nextRouteFromFlow = this.nextRoute;
      const route = nextRouteFromFlow || nextRoute;

      if (route) this.props.history.push(route);
    };

    onLoad = () => {
      this.setState({
        gotData: true,
      });
    };

    deps = {
      MainLayout: (props) => {
        return (
          <Layout
            key={this.namechain + props.name}
            namechain={`${this.namechain}`}
            {...props}
            stylesheet={this.getSS()}
          />
        );
      },

      Layout: (props) => {
        return (
          <Layout
            key={this.namechain + props.name}
            namechain={`${this.namechain}`}
            {...props}
            stylesheet={this.getSS()}
          />
        );
      },

      View: Route,
      Switch: (props) => {
        const self = this;
        return (
          <Switch>
            {React.Children.map(props.children, function (Child, index) {
              return React.cloneElement(Child, {
                key: index,
                path: `/${_.chain(self.props.match.path)
                  .split("/")
                  .compact()
                  .join("/")
                  .value()}/${Child.props.landing ? "" : Child.props.name}`,
              });
            })}
          </Switch>
        );
      },

      Block: (props) => {
        return (
          <Block
            key={this.namechain + props.name}
            namechain={`${this.namechain}`}
            {...props}
            stylesheet={this.getSS()}
            _isParentLoading={!this.state._dataFetched}
          />
        );
      },

      Bits: this.getBits(),
      interiorDesigner,
      mainconfig,
    };

    placeWidget(defaultProps = {}) {

      return <WidgetManager {...defaultProps} />;
    }

    render = () => {
      const conf = config.getConfig();
      let values = {};

      if (this.getLabelValues) values = this.getLabelValues(_, conf);

      return this.renderSurface(values, this.deps, conf);
    };

    componentGetData = this.getData;
    _render = this.render;
  }

  Entity.defaultProps = _.merge({}, getComponentDefaultProps(), entityPropsFromConfig || {})

  if (_.isFunction(Component.prototype.getPropsSchema))
    Entity.propTypes = _.omitBy(_.mapValues(Component.prototype.getPropsSchema(), p => {
      return p.type ? PropTypes[p.type] : undefined;
    }), _.isUndefined)


  const wrapper = hasGetData ? withSpinner : null;
  const wrappedComp = wrapper ? wrapper(Entity, !options.hasSkeleton) : Entity;
  wrappedComp.displayName = options.name;
  return options.noRouter ? wrappedComp : withRouter(wrappedComp);
}
