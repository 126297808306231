import React from 'react';
import Entities from '@toolkit/entities';

class InfoImage extends React.PureComponent {
    constructor(props) {
        super(props)
    }
    renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
        return (
            <Block name='info-image'>
                <Bits.Image
                    src={this.props.url}
                    alt=''
                    name="info-banner"
                />
            </Block>
            
        )
    }

    getStylesheet = (colorPallete) => {
        return {
            'info-image': {
                master: {
                    maxWidth: "50%",
                    // backgroundRepeat: 'no-repeat',
                    // backgroundSize: 'contain',
                },
                mobile: {
                    maxWidth: "100%",
                    // width: 60,
                    // height: 60,
                    //marginLeft: '-6px'
                }
            },
        }
    }
}

export default Entities.createPlace(InfoImage, {
    name: "InfoImage",
  });



