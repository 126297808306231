import React from 'react';
import _ from 'lodash';
import Entities from '@toolkit/entities';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Helper from '@core/helpers';
import { Button } from '@material-ui/core';
import interiorDesigner from '@toolkit/lib/interiorDesigner'
import EditIcon from '@material-ui/icons/Edit';
import Table from '@web-bricks/Shared/table/table'
import { Delete } from '@material-ui/icons';
import DialogBox from '@core/modules/shared/views/Things/dialogBox';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Form from '@web-bricks/Shared/form/components/form';
import FormMaker from '@web-bricks/Shared/form/components/formMaker';
import transitionRegistry from '@toolkit/lib/transitionRegistry';
// import SketchExample from '@web-bricks/Shared/Input/sketchPicker';
import Container from '@core/modules/shared/views/Things/container.js';

class ManageTags extends React.Component {

    constructor(props) {
        super(props);
        this.state ={
            showAddTagDailog : false,
            tag : null
        }
        this.tagsHelper = new Helper.TagsHelper()
    }

    async getData() {
        const tags = await this.tagsHelper.getAllTags()
        this.setState({
            tags: tags
        })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({
            tags : this.state.tags
        })
    }

    editTag = (value) => {
        this.setState({
            tag: value,
            showAddTagDailog : true
        })
    }

    deleteTag = async (values) =>{
        this.setState({
            showDeleteAlert : true,
            tagToDelete : values
        })
    }

    getTagsList = ({ Block, Bits }) => {
        const tags = this.state.tags || [];

        const headers = [{
            label: 'Tag Label',
        }, {
            label: 'Type',
            align: 'right'
        }, {
            label : 'Color',
            align: 'right'
        },{
            label: 'Edit',
            align: 'right'
        },{
            label: 'Delete',
            align: 'right'
        }];

        const rows = _.map(tags, tag => ([{
            column: 'Tag Label',
            value: tag.label
        }, {
            column: 'Type',
            value: tag.type
        },{
            column: 'Color',
            align: 'right',
            value: tag.color,
            render: () =>{
                return(
                    <Block style={{backgroundColor: tag.color, borderRadius: 15, width:27, height:27, marginLeft:'auto'}}></Block> 
                )
                
            }
        },{
            column: 'Edit',
            render: () => {
                return (
                    <Block name='edit-block' onClick={() => this.editTag(tag)}>
                        <EditIcon style={{ color: interiorDesigner.colorPallete.teritary, fontSize: 16, paddingRight: 5 }} />
                    </Block>
                )
            }
        }, {
            column: 'Delete',
            render: () => {
                return (
                    <Block name='edit-block' onClick={() => this.deleteTag(tag)}>
                        <Delete style={{ color: interiorDesigner.colorPallete.teritary, fontSize: 16, paddingRight: 5 }} />
                    </Block>
                )
            }
        }]));

        return { rows, headers };
    }

    goBack = () =>{
        this.setState({
            showDeleteAlert : false,
        })
    }

    onTagDelete = async () =>{
        await this.tagsHelper.deleteTag(this.state.tagToDelete._id)
        const tags = await this.tagsHelper.getAllTags()
        this.setState({
            tags: tags,
            showDeleteAlert : false,
            tagToDelete : null
        })
    }

    getRenderItems = (Block, Bits) =>{
        return(
            <Block name='delete-message'>
                <Bits.Text>{`Are you sure you want to delete '${this.state.tagToDelete.label}' from Tags`}</Bits.Text>
                <Button variant="outlined" color="secondary" size={'large'} onClick={this.onTagDelete} style={{ margin: 'auto' }}>continue</Button>
            </Block>
        )
    }

    onAddNewTag = () =>{
        this.setState({
            tag : null,
            showAddTagDailog : true
        })
    }

    getFeilds = () =>{
        let tag = this.state.tag
        const fields = [
            {
                type: "textField",
                props: {
                    name: "label",
                    label: "Tag label",
                    required: true,
                    defaultValue: !_.isEmpty(tag) ? tag.label : ''
                }
            },
            { 
                type: 'select', 
                // forceComponent: true, 
                props: { 
                    name: "type",
                    placeholder: "Type",
                    defaultValue: !_.isEmpty(tag) ? tag.type : '',
                    list: [
                        { label: 'OFFER', value: 'OFFERS' }, 
                        { label: 'INFO', value: 'INFO' }
                    ]
                }
            },
            {
                type: "ColorPicker",
                props: {
                    name: "color",
                    label: "Tag color",
                    required: true,
                    defaultValue: !_.isEmpty(tag) ? tag.color : '#1C53F8'
                }
            },
        ]
        return fields
    }

    getAddTagsRenderItems = (Block, Bits) =>{
        return(
            <Block>
                <Form submitButton={{ label: _.isEmpty(this.state.tag) ? 'add Tag' : "edit Tag", variant: "contained", color: "primary",  className: "add-submit-button" }} noValidate onSubmit={this.onSubmit}>
                    <FormMaker fields={this.getFeilds()} Transition={transitionRegistry.slideDown} />
                </Form>
            </Block>
        )
    }

    addTagDailogClose = () =>[
        this.setState({
            showAddTagDailog : false
        })
    ]

    onSubmit = async (values) =>{
        if( _.isEmpty(this.state.tag)){
            await this.tagsHelper.createTag(values)
        }else{
            await this.tagsHelper.editTag(values, this.state.tag._id)
        }
        
        const tags = await this.tagsHelper.getAllTags()
        this.setState({
            tags: tags,
            showAddTagDailog:false
        })
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }) {
        return (
            <Container
                page={{ title: 'Manage Tags'}}
                previousPage={[{title:'Manage Inventories', onClick: () => this.props.history.push('vendor/inventory/list')}]}
                activePage={'Tags'}
                rightButton={{buttonLabel : 'new', onClick:this.onAddNewTag, startIcon:<AddCircleIcon />}}
            >
                <View>
                    <Layout>
                        <Block name="tag-table">
                            {
                                values.tags.length > 0 ?
                                    <Table
                                        {...this.getTagsList({ Bits, Block })}
                                    />
                                : <Block><Bits.Text name='no-zone-message'>No Tags available right now! Click Add tags to enter tags</Bits.Text></Block>
                            }
                        </Block>
                    </Layout>
                    {
                        this.state.showDeleteAlert && <DialogBox open={this.state.showDeleteAlert} renderItem={() => this.getRenderItems(Block, Bits)} handleClose={this.goBack} dialogTitle="Delete" />
                    }
                    {
                        this.state.showAddTagDailog && <DialogBox open={this.state.showAddTagDailog} renderItem={() => this.getAddTagsRenderItems(Block, Bits)} handleClose={this.addTagDailogClose} dialogTitle = {_.isEmpty(this.state.tag) ? 'Add Tag' : "Edit Tag"}/>
                    }
                </View>
            </Container>
        )
    }
    getStylesheet = (colorPallet) => {
        return ({
            'top-title':{
                master:{
                    margin:0
                }
            },
            'top-layout':{
                master:{
                    display:'flex',
                    justifyContent:'space-between',
                    margin: '30px 25px 0px',
                },
                transition: 'children-slideRight'
            },
            'color-block':{
                master:{
                    backgroudColor:''
                }
            },
            'tags-container': {
                master: {
                    display: 'flex',
                    flexDirection: 'column',
                }
            },
            'tag-table': {
                master: {
                    maxWidth: 600,
                    margin: '0 auto'
                }
            },
            'tag-header': {
                master: {
                    fontSize: 25,
                    fontWeight: 600,
                    margin: '10px 0px'
                }
            },
            'no-zone-message': {
                master: {
                    color: colorPallet.subtext,
                    fontSize: 15,
                    textAlign: 'center',
                    margin: '30px'
                }
            },
            'zone-table-header': {
                master: {
                    fontSize: 16,
                    fontWeight: 300,
                    color: colorPallet.darkGrey,
                }
            },
            'zone-table-content': {
                master: {
                    fontSize: 15,
                    margin: 0
                }
            },
            'zone-table-text': {
                master: {
                    fontSize: 15,
                    margin: 0,
                    width: 200
                }
            },
            'zone-table': {
                master: {
                    display: 'flex',
                    alignItems: 'center',
                    margin: '10px auto'
                }
            },
            'header': {
                master: {
                    margin: 0,
                    marginLeft: 5,
                    fontSize: 20,
                    fontWeight: 600
                }
            },
            'tag-header-layout': {
                master: {
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: 24
                },
                mobile: {
                    margin: '30px 30px 10px',
                }

            },
            'top-bar': {
                master: {
                    // position: 'absolute',
                    // top: '10%',
                    // left: '10%',
                    marginLeft: 20,
                    color: colorPallet.teritary,
                    display: 'inline-flex',
                    alignItems: 'center',
                    fontSize: 18,
                    fontWeight: 400,
                    cursor: 'pointer',
                }
            },
            'header': {
                master: {
                    margin: 0,
                    marginLeft: 5,
                    fontSize: 20,
                    fontWeight: 600
                }
            },
            'edit-block': {
                master: {
                    display: 'flex',
                    alignItems: 'center',
                    color: colorPallet.teritary,
                    cursor: 'pointer',
                    justifyContent: 'flex-end'
                }
            },
            'bottom-botton': {
                master: {
                    width: 250,
                    margin: '50px auto',
                }

            }
        })
    }
}

export default Entities.createPlace(ManageTags, {
    name: 'ManageTags'
});
