import React from 'react';
import _ from 'lodash';
import SimpleNav from '@core/modules/shared/views/Things/nav'
import { Switch, Route, withRouter } from 'react-router-dom';



class RouteRenderer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            hideBackButton: true,
            hideNav: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEqual(this.state, nextState)) {
            return true;
        } else return false;
    }

    setBackVisibility = (state) => {
        if (!_.isNil(state) && (this.state.hideBackButton !== state)) {
            this.setState({
                hideBackButton: state,
            })
        }
    }

    render() {
        const self = this;
        return (<div>
            {
                !this.state.hideNav &&
                    <SimpleNav hideBackButton={this.state.hideBackButton} />
            }
            <Switch>
                {
                    _.map(this.props.routes, function (route, index) {
                        return <Route
                            path={route.path}
                            {...route}
                            key={index}
                            render={function (props) {
                                if (!_.isNil(route.hideBackButton) && (route.hideBackButton !== self.state.hideBackButton))
                                    self.setState({
                                        hideBackButton: route.hideBackButton,
                                    })

                                if ((route.hideNav !== self.state.hideNav))
                                    self.setState({
                                        hideNav: route.hideNav,
                                    })

                                if (self.props.activeBottomNavSection !== route.bottomNavSection)
                                    self.props.setNavSection(route.bottomNavSection)

                                return (
                                    <route.comp {...props}
                                        setBackVisibility={self.setBackVisibility}
                                    />
                                );
                            }}
                        />
                    })
                }
            </Switch>
        </div>
    )}
}

export default RouteRenderer;
