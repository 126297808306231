import React from 'react';
import { Checkbox, Radio } from '@material-ui/core';
import _ from 'lodash'
import { withFormsy } from 'formsy-react';


class SingleGroupLine extends React.PureComponent {


    constructor(props) {
        super(props);

        this.state = {
            selectedLine: this.getDefaultSelectedLine()
        }

        if (!_.isEmpty(this.props.defaultValue)) {
            this.props.setValue(this.props.defaultValue)
        }
    }

    getDefaultSelectedLine = () => {
        return _.findIndex(this.props.list, ({ defaultSelected: true }));
    }

    componentDidUpdate = (prevProps) => {
        if (!_.isEqual(prevProps.formCurrentValues, this.props.formCurrentValues)) {
            let value = _.cloneDeep(this.props.value);
            value = _.get(this.props.formCurrentValues, `__${this.props.name}.${this.state.selectedLine}`)
            this.props.setValue(value)
        }
    }

    onSingleLineActive = (group, index) => (event) => {
        const isChecked = event.target.checked;

        let value;

        if (isChecked) {
            value = _.get(this.props.formCurrentValues, `__${this.props.name}.${index}`)
        } else {
            value = null;
        }

        this.setState({
            selectedLine: index
        })
        this.props.setValue(value);
    }

    isSelected = (index) => {
        return this.props.radioButton === true ? _.isEqual(this.state.selectedLine, index) : true;
    }

    render() {
        const FormMaker = this.props.FormMaker;
        return (
            <div>
                {
                    _.map(this.props.list, (group, index) => {
                        const isSelected = this.isSelected(index)
                        return (
                            <div className="group-line flex-middle">
                                {
                                    this.props.radioButton &&
                                    <Radio
                                        checked={isSelected}
                                        onChange={this.onSingleLineActive(group, index)}
                                        name={this.props.name}
                                    />
                                }
                                <div className={(!isSelected) && 'line-disabled'}>
                                    {FormMaker({
                                        ...group,    
                                        wrapperClassName: 'flex-middle',
                                        fields: _.map(group.fields, (field, fieldIndex) => {

                                            return ({
                                                ...field,
                                                style:  field.type === 'HiddenInput' ? {} : {
                                                    minWidth: field.type !== 'Block' && 150,
                                                    margin: '0px 10px'
                                                },
                                                props: {
                                                    ...field.props,
                                                    required: isSelected,
                                                    name: `__${this.props.name}.${index}.${field.props.name}`
                                                }
                                            })
                                        }),
                                    })}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}


export default withFormsy(SingleGroupLine);