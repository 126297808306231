import React from 'react';
import Entities from '@toolkit/entities';
import Helper from '@core/helpers';
import Form from '@web-bricks/Shared/form/components/form';
import Container from '@core/modules/shared/views/Things/container.js';
import _ from 'lodash';
import FormMaker from '@web-bricks/Shared/form/components/formMaker';
import transitionRegistry from '@toolkit/lib/transitionRegistry';
import tokenManager from '@utils/lib/tokenManager';

class ReviewOrder extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderId: this.props.match.params._id,
        };
        this.orderHelper = new Helper.OrderHelper()
        this.reviewHelper = new Helper.ReviewHelper()
        
    }

    async getData() {
      if(!tokenManager.user){
        this.onComplete({
          nextRoute: `/account/signin?nextRoute=/orderSummary/writeReview/${this.state.orderId}`,
      })}
        const order = await this.orderHelper.getById(this.state.orderId)
        this.setState({
            order : order,
        }) 
    }
    
    getFields = (Block, Bits) =>{
        
        let list= [
                _.map(this.state.order.orderedItems, (item, index) => {
                    return (
                        [{
                            type: 'Block',
                            props: {
                              render: () => {
                                return (
                                  <Block>
                                      {
                                          item.image &&
                                            <Bits.Image name='inventory-image' src={item.image} />
                                      }
                                      <Bits.Text>{item.name}</Bits.Text>
                                  </Block>
                                )
                              }
                            }
                          },{
                            type:'Rating',
                            //title: item.name,
                            props:{
                                name:`${item.id}.rating`,
                                label:'Rating'
                            }
                        },{
                            type: 'textArea',
                            props: {
                                name: `${item.id}.review`,
                                label: "Review",
                                //required: true,
                                //defaultValue: property.value
                                rows:"6",
                                placeholder:"Write your review here"
                            }
                        }] 
                    )
                })
            ]
            return _.flattenDeep(list)
    }

  getLabelValues = (formatter, _, transformers) => {
    return ({

    })
  }

  onSubmit = (values, pureValues) =>{
        this.reviewHelper.createReview(pureValues, this.state.order._id, this.state.order.orderedBy.id)  
        this.goToView(`/orderSummary/${this.state.orderId}`) 
  }

  renderSurface(values, {
    MainLayout,
    Layout,
    Block,
    Bits,
    View,
  }) {
    return (
        <Container
            page={{ title: 'Review and rating'}}
            previousPage={[{title:'Order summary', onClick: () => this.props.history.push(`/orderSummary/${this.state.orderId}`)}]}
            activePage={'Order review'}
        >
          <Layout>
            <Block name='order-review-block'>
              <Form submitButton={{ label: 'submit' }} noValidate onSubmit={this.onSubmit}>
                <FormMaker fields={this.getFields(Block,Bits)} Transition={transitionRegistry.slideDown} />
              </Form>
            </Block>
          </Layout>
        </Container>
    );
  }

  getStylesheet = (colorPallete) => {
    return ({
      'inventory-image':{
          master:{
              width:100,
              height:100
          }
      },
      'order-review-block':{
        master:{
          marginLeft:20
        }
      }
    })
  }
}

export default Entities.createPlace(ReviewOrder, {
  name: 'ReviewOrder',
});