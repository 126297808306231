import React from 'react';
import _ from 'lodash';
import toolkit from '@toolkit';

class WidgetManager extends React.Component {


    constructor(props) {
        super(props);
    }

    render() {
        const EntityNameToPlace = _.get(this.props.parentConfig, 'children.entity', this.props.defaultWidget);
        const EntityToPlace = _.get(toolkit.Widgets, EntityNameToPlace)
        const EntityProps = EntityToPlace ? _.get(this.props.parentConfig, 'children.props', {}) : {};
        const inhertingProps = _.get(this.props, 'props', {})
        return EntityToPlace ? <EntityToPlace {...inhertingProps} {...this.props} {...EntityProps} /> : null;
    }
}


export default WidgetManager;