import * as React from 'react';
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';

const defaultOptions = {
  checkboxSelection: true,
  components: {
    Toolbar: GridToolbar,
  },
  pageSize: 30,
  autoPageSize: false
}


export default function DataTable(props) {
  
  return (
    <div
      style={{
        height: 660,
        width: '100%'
      }}>
      <DataGrid
        //autoPageSize
        {...defaultOptions}
        {...props}
        checkboxSelection={props.checkboxSelection}
        //selectionModel={selectionModel}
      />
    </div>
  );
}