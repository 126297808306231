import superagent from 'superagent';
import config from '@config';
import _ from 'lodash';

const responseBody = res => {
    return res.body;
}

const responseHandler = (options) => {
    return options.responseHandler || responseBody;
}

const getUrl = url => `${config.api.baseUrl}/${url}`;

class Request {

    constructor() {

    }
    setHeaderGetter = (headerGetter) => {
        this.headerGetter = headerGetter;
    }

    getHeaders = () => {
        if (this.headerGetter)
            return this.headerGetter()
        else
            return {}
    }

    get = (url, data, options = {}) => {
        const req = superagent
        .get(options.absoluteUrl ? url : getUrl(url))
        .set(this.getHeaders())
        
        if (options.headers) {
            _.forIn(options.headers, (value, key) => {
                req.set(key, value);
            })
        }
        return req.then(responseHandler(options));
    }
    post = (url, data, options = {}) => {
        return (
            superagent
            .post(options.absoluteUrl ? url : getUrl(url))
            .set(this.getHeaders())
            .send(data)
            .then(responseHandler(options))
        )
    }
    patch = (url, data, options = {}) => {
        return (
            superagent
            .patch(getUrl(url))
            .set(this.getHeaders())
            .send(data)
            .then(responseHandler(options))
        )
    }
}


export default new Request();
