import _ from 'lodash';

const persistentStorage = {
    set: (keyValuePairs) => {
        _.each(keyValuePairs, (value, key) => {
            localStorage.setItem(key, _.isObject(value) ? JSON.stringify(value) : value);
        })
    },
    get: (key) => {
        let value = localStorage.getItem(key);
        try {
            value = JSON.parse(value);
        } catch (e) {
            
        }

        return value;
    },
    remove: (key) => {
        return localStorage.removeItem(key);
    },
    getAsync: (key) => {
        return localStorage.getItem(key) || '';
    }
};

export default persistentStorage;