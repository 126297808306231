import _ from 'lodash';
import React, {Component} from 'react';
import Rating from '@material-ui/lab/Rating';
import { withFormsy } from 'formsy-react';
import classNames from 'classnames';
import customFormHoc from './customFormHoc';
require('./styles.scss');

class RatingStars extends Component {
    constructor(props){
        super(props);
         if (props.defaultValue)
            props.setValue(props.defaultValue);
    }

    changeValue = (event, newValue) => {
        this.props.setValue(newValue);
        this.props.onChange(newValue, event);
    }

    render(){
        return(
            <Rating
                {...this.props}
                name="simple-controlled"
                value={this.props.value}
                onChange={this.changeValue}
                name={this.props.name}
                precision={0.5}
                color="primary"
                defaultChecked = {this.props.defaultValue}
            />
        ) 
    }
}

RatingStars.defaultProps = {
    onChange: _.noop,
};

const nonFormsyHOC = customFormHoc(RatingStars);

export { nonFormsyHOC as RatingStars };
export default withFormsy(RatingStars);
