import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';


export default function (Component) {
    return class extends Component {

        constructor(props) {
            super(props);
            this.state = {
                ...this.state
            };
        }

        componentWillMount = async () => {
            this.setState({
                showSpinner: true,
            });
            await this.getData();
            this.setState({
                showSpinner: false,
            });
        }

        getData = async (...args) => {
            if (_.isFunction(this.componentGetData)) {
                await this.componentGetData(...args);
            }
        }

        render = () => {
            return this.state.showSpinner ? <div style={{ position: 'absolute', top: '50%', left: '50%', transform: [{ translateX: -20 }, { translateY: -20 }] }}><CircularProgress color="#041f40"/></div> : this._render();
        }
    }
}