import React from 'react';
import _ from 'lodash'
import Entities from '@toolkit/entities';
import tokenManager from '@utils/lib/tokenManager';
import Helper from '@core/helpers';

class InventoryTags extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
        }
        this.tagHelper = new Helper.TagsHelper()
        this.inventoryHelper = new Helper.InventoryHelper()
    }

    async getData() {
        // const inventory = await this.inventoryHelper.getInventoryById(this.props.inventoryId)
        // this.setState({
        //   inventory
        // })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({
        })
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        View,
        Bits
    }) {
        return (
            <MainLayout>
                <View>
                    <Layout>
                        <Block name='tags-block'>
                            {
                                _.map(this.props.tags, tag => {
                                    return (
                                        <Block style={{
                                            background: tag.color,
                                            margin: '3px 0px',
                                            padding: '3px 8px',
                                            color: '#fff',
                                            borderRadius: 4,
                                            minWidth: 40
                                        }}>
                                            <Bits.Text name='tag-label'>{tag.label}</Bits.Text>
                                        </Block>
                                    )
                                })
                            }
                        </Block>
                    </Layout>
                </View>
            </MainLayout>
        );
    }

    getStylesheet = (colorPallete) => {
        return ({
            'tags-block': {
                master: {
                    display: 'flex',
                    flexDirection: this.props.direction === 'row' ? 'row' : 'column'
                }
            },
            'tag-label': {
                master: {
                    margin: 'auto',
                    textAlign: 'center',
                    fontSize: this.props.size === 'large' ? 15 : 10
                }
            }
        })
    }
}

export default Entities.createThing(InventoryTags, {
    name: 'InventoryTags'
});
