import api from '@api';
import _ from 'lodash';
import tokenManager from '@utils/lib/tokenManager'

class TemplateHelper {

    constructor() {

    }

    addNew(data) {
        return api.Templates.create({
                body: data.body,
                type: data.type,
                subject: data.subject 
        })
    } 

    UpdateTemplate = async (id, data) =>{
        const value = {
            body: data.body,
            type: data.type,
            subject: data.subject
        }
        await api.Templates.update(id, value)
    }

    getAllTemplates = async () =>{
        let templates = await api.Templates.find({
            query:{},
        })
        _.map(templates, template =>{
            template.label = template.subject
        })
        return templates
    }

    getTemplateDetails = async (id) =>{
        const template = await api.Templates.getById(id)
        return template
    }

    search = async (subject) => {
        //console.log(subject)
        return await api.Templates.search(subject)
    }
}


export default TemplateHelper;