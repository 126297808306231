import React from "react";
import _ from "lodash";
import Counter from "@core/modules/shared/views/Things/counter";
import Entities from "@toolkit/entities";
import tokenManager from "@utils/lib/tokenManager";
import Helper from "@core/helpers";
import { SelectNonFormInput } from "@web-bricks/Shared/Input/select";
import InventoryTags from "@core/modules/Inventory/views/Things/inventoryTags";
import interiorDesigner from "@toolkit/lib/interiorDesigner";
import InventoryMedia from "@core/modules/Inventory/views/Pieces/inventoryMedia";
import StarIcon from "@material-ui/icons/Star";
// import ReviewsIcon from "@material-ui/icons/Reviews";
// import ReviewsIcon from "@mui/icons-material/Reviews";

class InventoryCard extends React.Component {
  constructor(props) {
    super(props);
    this.reviewHelper = new Helper.ReviewHelper();
    let quantityVariants = [];
    _.forEach(this.props.group, (inventory) => {
      _.forEach(inventory.properties, (property) => {
        if (property.label === "quantity") {
          const data = {
            label: `${property.value}`,
            value: inventory._id,
            inventory: inventory,
          };
          quantityVariants.push(data);
        }
      });
    });
    let initialQuantity = "";
    let quantityId = "";
    _.forEach(_.head(this.props.group).properties, (property) => {
      if (property.label === "quantity") {
        initialQuantity = property.value;
        quantityId = _.head(this.props.group)._id;
      }
    });

    const rates = _.head(this.props.group).rates || {};
    const saleRate = rates.MRP === rates.saleRate ? 0 : rates.saleRate;
    const discountPercentage = ((rates.MRP - rates.saleRate) / rates.MRP) * 100;

    this.state = {
      quantity: "",
      showQunatityDailog: false,
      quantityVariants: quantityVariants,
      inventory: _.head(this.props.group),
      initialQuantity: initialQuantity,
      quantityId: quantityId,
      discountPercentage: _.ceil(discountPercentage),
    };
    this.getRatings();
  }

  getLabelValues = (formatter, _, transformers) => {
    return {};
  };

  // getQuantity = () =>{
  //   let quantity = ''
  //     _.forEach(this.state.inventory.properties, property=>{
  //       if(property.label === 'quantity'){
  //         quantity =  property.value
  //       }
  //     })
  //     console.log(quantity)
  //     return quantity
  // }

  onClick = (values) => {
    const inventory = _.find(this.props.group, { _id: values });
    this.setState({
      inventory: inventory,
    });
  };

  getRatings = async () => {
    let ratings = await this.reviewHelper.getAvgRatings(
      this.state.inventory._id
    );
    this.setState({
      ratings: ratings,
    });
  };

  onQuantitySelect = (inventoryId) => {
    let inventory = _.find(this.props.group, { _id: inventoryId });
    const discountPercentage =
      ((inventory.rates.MRP - inventory.rates.saleRate) / inventory.rates.MRP) *
      100;
    this.setState({
      inventory: inventory,
      discountPercentage: _.ceil(discountPercentage),
    });
  };
  cartValue = (inventory) => {
    const value = Helper.CartHelper.getItemCountInCart(inventory._id);
    return value;
  };

  renderSurface(values, { MainLayout, Layout, Block, View, Bits }) {
    const inventory = this.state.inventory;
    const rates = inventory.rates || {};
    return (
      <MainLayout name="item">
        <Layout
          onClick={() =>
            this.props.onClick(
              this.props.group,
              this.cartValue(this.state.inventory)
            )
          }
        >
          {this.state.discountPercentage > 0 && (
            <Block name="discount-badge">
              <Bits.Text name="discount-percentage">
                {this.state.discountPercentage}%
              </Bits.Text>
              <Bits.Text name="secondary-text">OFF</Bits.Text>
            </Block>
          )}
          <Block name="inventory-card-image-container">
            {!_.isEmpty(this.state.inventory.tags) && (
              <Block
                style={{
                  position: "absolute",
                  zIndex: 1,
                  right: 0,
                  bottom: 10,
                }}
              >
                <InventoryTags tags={this.state.inventory.tags} />
              </Block>
            )}
            <InventoryMedia media={inventory.media} />
          </Block>
          <Layout name="item-details-container" style={{ padding: "5px 10px" }}>
            {this.state.ratings && this.state.ratings.average && (
              <Layout name="rating-layout">
                <Block name="avg-ratings-block">
                  <Bits.Text name="avg-rating">
                    {this.state.ratings.average}
                  </Bits.Text>
                  <StarIcon
                    style={{
                      color: interiorDesigner.colorPallete.primary,
                      margin: 0,
                      fontSize: 18,
                    }}
                  />
                  {/* <Bits.Text name="rating-div">|</Bits.Text>
                  <ReviewsIcon
                    style={{
                      color: interiorDesigner.colorPallete.primary,
                      margin: 0,
                      fontSize: 14,
                    }}
                  />
                  <Bits.Text name="total-ratings">
                    {this.state.ratings.totalReviews}
                  </Bits.Text> */}
                </Block>
              </Layout>
            )}
            <Layout name="item-details">
              <Block name="card-text">
                <Bits.Text name="card-title">
                  {_.capitalize(inventory.label)}
                </Bits.Text>
              </Block>
            </Layout>
            <Layout name="price-block">
              <Block name="price-quantity-block">
                {rates.MRP === rates.saleRate ? (
                  <Bits.Currency value={rates.saleRate} name="card-content" />
                ) : (
                  <Block name="rates">
                    <Bits.Currency value={rates.saleRate} name="card-content" />
                    <Bits.Currency
                      value={rates.MRP}
                      name="card-content-strike"
                    />
                  </Block>
                )}
                {rates.MRP ? (
                  <Block name="inventory-quantity">
                    {this.state.quantityVariants.length !== 0 &&
                      (this.state.quantityVariants.length === 1 ? (
                        <Bits.Text name="quantity-block">
                          {this.state.quantityVariants[0].label}
                        </Bits.Text>
                      ) : (
                        <Block>
                          <SelectNonFormInput
                            name="quantity"
                            defaultValue={this.state.quantityId}
                            list={this.state.quantityVariants}
                            required
                            onChange={this.onQuantitySelect}
                          />
                        </Block>
                      ))}
                  </Block>
                ) : (
                  <Bits.Text name="out-of-stock">Out of Stock</Bits.Text>
                )}
              </Block>
              {!tokenManager.isStaff() &&
                (this.state.inventory.inStock !== "NO" ? (
                  <Block name="counter-block">
                    <Counter
                      inventory={this.state.inventory}
                      count={this.cartValue(this.state.inventory)}
                      onChange={this.props.onCountChange}
                      //variants = {this.state.quantityVariants}
                      //onQuantitySelect = {this.onQuantitySelect}
                    />
                  </Block>
                ) : (
                  <Bits.Text name="outofstock">Out of Stock</Bits.Text>
                ))}
            </Layout>
          </Layout>
        </Layout>
      </MainLayout>
    );
  }

  getStylesheet = (colorPallete) => {
    return {
      "total-ratings": {
        master: {
          margin: "0px 3px",
          fontSize: 14,
          // fontWeight:'bold',
          color: colorPallete.primary,
        },
      },
      "rating-div": {
        master: {
          margin: "0px 4px",
          paddding: "0px 10px",
          color: colorPallete.darkGrey,
        },
        mobile: {
          margin: "0px 4px",
          paddding: "0px 10px",
        },
      },
      "avg-rating": {
        master: {
          margin: "0px 3px",
          fontSize: 14,
          // fontWeight:'bold',
          //color: "white",
        },
      },
      "avg-ratings-block": {
        master: {
          display: "flex",
          backgroundColor: colorPallete.white,
          alignItems: "center",
          borderRadius: 10,
          width: "fit-content",
          padding: "3px 7px",
        },
      },
      "rating-layout": {
        mobile: {
          bottom: 93,
          position: "absolute",
        },
      },
      "counter-block": {
        master: {
          bottom: 0,
        },
      },
      "inventory-quantity": {
        master: {
          width: 100,
        },
      },
      outofstock: {
        master: {
          fontSize: 12,
          margin: "0px 0px",
          width: 115,
          color: "#e58483",
        },
        mobile: {
          width: "auto",
        },
      },
      "secondary-text": {
        master: {
          margin: 0,
          color: "white",
          fontSize: 9,
          fontWeight: 600,
          //paddingTop: 1
        },
        mobile: {
          fontSize: 8,
        },
      },
      "discount-percentage": {
        master: {
          color: "white",
          fontWeight: "bold",
          fontSize: 14,
          margin: "auto",
          //marginTop: 2
        },
        mobile: {
          fontSize: 13,
        },
      },
      "discount-badge": {
        master: {
          background: colorPallete.teritary,
          position: "absolute",
          right: 0,
          borderRadius: "50%",
          width: 30,
          height: 30,
          padding: 5,
          textAlign: "center",
          zIndex: 1,
        },
        mobile: {
          width: 28,
          height: 28,
          padding: 6,
        },
      },
      "inventory-card-image-container": {
        master: {
          position: "relative",
        },
      },
      "out-of-stock": {
        master: {
          fontSize: 12,
          margin: "0px 0px",
          color: colorPallete.subtext,
        },
      },
      "price-quantity-block": {
        master: {
          display: "flex",
          flexDirection:
            this.state.quantityVariants.length === 0 ? "row" : "column",
          justifyContent: "space-between",
          width: "180px",
        },
      },
      "price-block": {
        master: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        },
      },
      "quantity-block": {
        master: {
          fontSize: 14,
          color: colorPallete.subtext,
          margin: 0,
          padding: "5px 0px",
        },
      },
      item: {
        master: {
          width: 200,
          height: "auto",
          padding: "5px",
          position: "relative",
        },
        mobile: {
          width: "calc(100vw/2 - 40px)",
          margin: 0,
          padding: "5px 0px",
        },
      },
      "item-details": {
        master: {
          //marginTop: 10
        },
      },
      quantity: {
        master: {
          margin: 0,
          color: colorPallete.subtext,
          fontSize: 10,
        },
      },
      "card-title": {
        master: {
          fontSize: 17,
          color: colorPallete.black,
          marginBottom: tokenManager.isStaff() ? 0 : 10,
          fontWeight: 600,
          margin: "5px 0px",
          lineHeight: 1.5,
          marginBottom: 6,
          overflow: "hidden",
          textOverflow: "ellipsis",
          height: 51,
        },
        mobile: {
          fontSize: 16,
          lineHeight: 1.5,
          height: "auto",
          fontWeight: 500,
        },
      },
      rates: {
        master: {
          display: "flex",
          alignItems: "center",
        },
      },
      "card-image": {
        master: {
          margin: 5,
          borderRadius: 3,
        },
      },
      "card-content": {
        master: {
          fontSize: 16,
          fontWeight: 600,
          marginRight: 10,
          color: colorPallete.darkGrey,
        },
      },
      "card-content-strike": {
        master: {
          fontSize: 12,
          color: colorPallete.darkGrey,
          textDecoration: "line-through",
        },
      },
      "flex-row-space-btw": {
        master: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          //width:'100%'
        },
      },
      "flex-column": {
        master: {
          display: "flex",
          flexDirection: "column",
        },
      },
    };
  };
}

export default Entities.createThing(InventoryCard, {
  name: "InventoryCard",
});

const imageSample =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRGh5WFH8TOIfRKxUrIgJZoDCs1yvQ4hIcppw&usqp=CAU";
