import React from "react";
import _ from "lodash";
import Dialog from "@material-ui/core/Dialog";
import Counter from "@core/modules/shared/views/Things/counter";
import interiorDesigner from "@toolkit/lib/interiorDesigner";
import Helper from "@core/helpers";
import InventoryCard from "./inventoryCard";
import Entities from "@toolkit/entities";
import FabCart from "@core/modules/Cart/views/lib/cart";
import tokenManager from "@utils/lib/tokenManager";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import InventoryTags from "@core/modules/Inventory/views/Things/inventoryTags";
import InventoryMedia from "@core/modules/Inventory/views/Pieces/inventoryMedia";
import { RatingStars } from "@web-bricks/Shared/Input/rating";
import ReviewsList from "@core/modules/Review/views/Pieces/reviewsList";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const CustomCrossIcon = withStyles({
  root: {
    color: interiorDesigner.colorPallete.primary,
    padding: 15,
    fontSize: 32,
    "@media (max-width: 600px)": {
      backgroundColor: interiorDesigner.colorPallete.white,
      opacity: 0.5,
      borderRadius: "100%",
      padding: 5,
      fontSize: 24,
    },
  },
})(CloseIcon);

class ItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.inventoryHelper = new Helper.InventoryHelper();
    this.tagsHelper = new Helper.TagsHelper();
    this.reviewHelper = new Helper.ReviewHelper();
    //this.modalRef = React.createRef();
    let quantityVariants = [];
    _.forEach(this.props.group, (inventory) => {
      _.forEach(inventory.properties, (property) => {
        if (property.label === "quantity") {
          const data = {
            label: inventory.rates
              ? `${property.value} - ₹ ${inventory.rates.saleRate}`
              : `${property.value}`,
            value: inventory._id,
            inventory: inventory,
          };
          quantityVariants.push(data);
        }
      });
    });
    let initialQuantity = "";
    _.forEach(_.head(this.props.group).properties, (property) => {
      if (property.label === "quantity") {
        initialQuantity = property.value;
      }
    });
    const inventoryList =
      props.similarInventories ||
      this.inventoryHelper.getInventoryList(
        this.props.group,
        this.props.groupedinventories
      );
    this.state = {
      cart: 0,
      quantityVariants: quantityVariants,
      inventory: _.head(this.props.group),
      initialQuantity: initialQuantity,
      inventoryList,
    };
    console.log(this.state.inventory, typeof this.state.inventory.description);
    this.getRatings();
  }

  getLabelValues = (_, transformers) => {
    return {
      cart: Helper.CartHelper.getCart(),
      // cartCount: Helper.CartHelper.getCount(),
      imageSample:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRGh5WFH8TOIfRKxUrIgJZoDCs1yvQ4hIcppw&usqp=CAU",
      // inventory : this.state.inventory
      // description: _.tail(
      //   _.split(_.get(this.state.inventory, "description"), "•")
      // ),
    };
  };

  onQuantitySelect = (inventoryId) => {
    let inventory = _.find(this.props.group, { _id: inventoryId });
    this.setState({
      inventory: inventory,
    });
  };

  getInventory = (value) => {
    let inventory = _.find(this.props.group, { _id: value });
    return inventory;
  };

  pushToCart = () => {
    this.goToView("/cart");
  };

  cartValue = (inventory) => {
    if (!inventory) return null;
    const value = Helper.CartHelper.getItemCountInCart(inventory._id);
    return value;
  };

  onCountChange = (...args) => {
    Helper.CartHelper.updateCart(...args);
    this.setState({
      cart: this.state.cart + 1,
    });
  };

  onQuantitySelect = (inventoryId) => {
    let inventory = _.find(this.props.group, { _id: inventoryId });
    this.setState({
      inventory: inventory,
    });
  };

  getInventory = (value) => {
    let inventory = _.find(this.props.group, { _id: value });
    return inventory;
  };

  imageClick = (group) => {
    this.props.onClick(group);

    if (this.modalRef.current) {
      this.modalRef.current.scrollTo(0, 0);
    }
    let initialQuantity = "";
    _.forEach(_.head(group).properties, (property) => {
      if (property.label === "quantity") {
        initialQuantity = property.value;
      }
    });
    let quantityVariants = [];
    _.forEach(group, (inventory) => {
      _.forEach(inventory.properties, (property) => {
        if (property.label === "quantity") {
          const data = {
            label: inventory.rates
              ? `${property.value} - ₹ ${inventory.rates.saleRate}`
              : `${property.value}`,
            value: inventory._id,
            inventory: inventory,
          };
          quantityVariants.push(data);
        }
      });
    });
    const inventoryList = this.inventoryHelper.getInventoryList(
      group,
      this.props.groupedinventories
    );
    this.setState({
      inventoryList,
      group: group,
      inventory: _.head(group),
      initialQuantity: initialQuantity,
      quantityVariants: quantityVariants,
    });
  };

  getRatings = async () => {
    let ratings = await this.reviewHelper.getReviewDetails(
      this.state.inventory._id
    );
    this.setState({
      ratings: ratings,
    });
  };

  renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
    console.log(this.state.inventory.properties);
    return (
      <MainLayout>
        <Layout name="modal-container">
          <Block>
            <Dialog
              className="item-modal"
              fullScreen
              open={this.props.open}
              //TransitionComponent={Transition}
              PaperProps={{
                ref: this.modalRef,
                style: {
                  backgroundColor: interiorDesigner.colorPallete.bg,
                },
              }}
            >
              <Layout name="item-modal-container">
                <Block name="arrow-overlay">
                  <Bits.Icon
                    name="close-icon"
                    onClick={() => this.props.onBack()}
                  >
                    Close
                  </Bits.Icon>
                </Block>
                <Layout name="item-modal-layout">
                  <Layout
                    name="item-image-container"
                    key={this.state.inventory._id}
                  >
                    {!_.isEmpty(this.state.inventory.tags) && (
                      <Block
                        style={{
                          position: "absolute",
                          zIndex: 1,
                          right: 0,
                          bottom: 10,
                        }}
                      >
                        <InventoryTags
                          tags={this.state.inventory.tags}
                          size="large"
                        />
                      </Block>
                    )}
                    <Block name="item-modal-main-item-image">
                      <InventoryMedia
                        media={this.state.inventory.media}
                        type="slider"
                      />
                    </Block>
                  </Layout>
                  <Layout name="item-details-container">
                    <Block name="basic-info">
                      <Block name="name-price">
                        <Bits.Text name="product-name">
                          {_.capitalize(this.state.inventory.label)}
                        </Bits.Text>
                        {this.state.inventory.rates && (
                          <Bits.Currency
                            name="product-price"
                            value={this.state.inventory.rates.saleRate}
                          />
                        )}
                      </Block>
                      <Block name="product-description-container">
                        <Bits.Text
                          dangerouslySetInnerHTML={{
                            __html: this.state.inventory.description,
                          }}
                        />
                        {/* <Bits.BulletList
                          name="product-description"
                          list={_.map(values.description, (i) => ({
                            label: i,
                          }))}
                        /> */}
                        {
                          // _.map(values.description, (line, index) => {
                          //   return (
                          //     <li>
                          //       <Bits.Text key={index} name='product-description'>{`${line}`}</Bits.Text>
                          //     </li>
                          //   )
                          // })
                        }
                      </Block>
                      <Block>
                        {this.state.ratings && this.state.ratings.average > 0 && (
                          <Layout>
                            <Block name="rating-block">
                              <RatingStars
                                defaultValue={this.state.ratings.average}
                                readOnly
                                size="small"
                              />
                              <Bits.Text name="avg-rating">{`(${this.state.ratings.totalReviews})`}</Bits.Text>
                            </Block>
                          </Layout>
                        )}
                      </Block>
                      <Layout name="stack-indicator">
                        <Block>
                          {this.state.inventory.inStock === "NO" ? (
                            <Bits.Text name="stock-name">
                              Stock unavailable
                            </Bits.Text>
                          ) : (
                            <Bits.Text name="stock-name">
                              Stock available
                            </Bits.Text>
                          )}
                        </Block>
                        <Layout>
                          {this.state.inventory.inStock !== "NO" &&
                            !tokenManager.isStaff() &&
                            _.map(
                              this.state.quantityVariants,
                              (variant, index) => {
                                return (
                                  <Block key={index}>
                                    <Block name="price-table">
                                      <Bits.Text name="quantity">
                                        {variant.label}
                                      </Bits.Text>
                                      <Counter
                                        inventory={variant.inventory}
                                        count={this.cartValue(
                                          variant.inventory
                                        )}
                                        onChange={this.onCountChange}
                                        variants={[]}
                                        onQuantitySelect={this.onQuantitySelect}
                                      />
                                    </Block>
                                    <Divider />
                                  </Block>
                                );
                              }
                            )}
                        </Layout>
                      </Layout>
                      <Layout name="product-details">
                        <Block>
                          <Bits.Text name="details-heading">
                            Product Details
                          </Bits.Text>
                        </Block>
                        <Block name="property-block">
                          {_.map(
                            this.state.inventory.properties,
                            (property, index) => {
                              return (
                                <Block name="detail-row" key={index}>
                                  <Bits.Text name="details-title">
                                    {_.startCase(property.label)}
                                  </Bits.Text>
                                  <Bits.Text name="details-text">
                                    {_.capitalize(property.value)}
                                  </Bits.Text>
                                </Block>
                              );
                            }
                          )}
                        </Block>
                      </Layout>
                    </Block>
                  </Layout>
                </Layout>
                {this.state.ratings &&
                  this.state.ratings.reviews.length > 0 && (
                    <ReviewsList
                      reviewsList={this.state.ratings.reviews}
                      avgRating={this.state.ratings.average}
                    />
                  )}
                <Layout name="similar-bar">
                  <Block>
                    <Bits.Text name="similar-bar-title">
                      Similar items
                    </Bits.Text>
                  </Block>
                  <Layout name="inventory_list">
                    {_.map(this.state.inventoryList, (group, index) => {
                      return (
                        <Block name="inventory-list-card" key={index}>
                          <InventoryCard
                            key={index}
                            group={group}
                            onClick={() => this.imageClick(group)}
                            onCountChange={this.onCountChange}
                          />
                        </Block>
                      );
                    })}
                  </Layout>
                </Layout>
              </Layout>
              {tokenManager.type !== "staff" && (
                <Block name="cart-fab">
                  <Block>
                    <FabCart
                      pushToCart={this.pushToCart}
                      color={interiorDesigner.colorPallete.bg}
                    />
                  </Block>
                </Block>
              )}
            </Dialog>
          </Block>
        </Layout>
      </MainLayout>
    );
  }

  getStylesheet = (colorPallete) => {
    return {
      "avg-rating": {
        master: {
          margin: 0,
          paddingLeft: 5,
          fontSize: 14,
        },
      },
      "rating-block": {
        master: {
          display: "flex",
          marginBottom: 10,
        },
      },
      "item-modal-main-item-image": {
        master: {
          maxWidth: 300,
          width: "100%",
          height: "auto",
          margin: "auto",
        },
        mobile: {
          maxWidth: "100%",
        },
      },
      ItemModal: {
        master: {
          height: "100%",
          maxWidth: "100%",
        },
      },
      "cart-fab": {
        master: {
          backgroundColor: colorPallete.primary,
          borderRadius: 24,
          height: 50,
          width: 50,
          right: 40,
          bottom: 40,
          position: "fixed",
        },
      },
      "cart-fab.CartBasketIcon.cart-basket-icon-link": {
        master: {
          color: colorPallete.secondary,
        },
      },
      "item-modal-layout": {
        master: {
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "80%",
          position: "relative",
          alignItems: "center",
          maxWidth: "85%",
          margin: "0 auto",
          height: "80vh",
        },
        mobile: {
          flexDirection: "column",
          margin: 0,
          maxWidth: "100%",
          height: "auto",
        },
        // transition: 'children-slideDown'
      },
      "item-details-container": {
        mobile: {
          width: "100%",
        },
      },
      "inventory-list-card": {
        master: {
          width: "200px",
          marginRight: 50,
          cursor: "pointer",
          display: "inline-flex",
        },
        mobile: {
          // width: 300
        },
      },
      "modal-container": {
        master: {
          maxWidth: "80%",
        },
      },
      description: {
        master: {
          margin: "15px 0px",
        },
        mobile: {
          width: "100%",
        },
      },
      "product-details": {
        master: {
          margin: "0px 20px",
        },
      },
      "arrow-overlay": {
        master: {
          position: "absolute",
          right: 20,
          top: 20,
        },
      },
      "modal-arrow-overlay": {
        master: {
          position: "fixed",
          right: 30,
          top: 30,
          zIndex: 2,
          opaccity: 0.8,
        },
        mobile: {
          right: 20,
          top: 20,
          padding: 5,
        },
      },
      "item-image-container": {
        master: {
          width: "50%",
          height: "300px",
          overflow: "hidden",
          position: "relative",
        },
        mobile: {
          width: "100%",
          paddingTop: 0,
          height: "100%",
          minHeight: 300,
        },
      },
      "item-modal-mainblock": {
        master: {
          marginTop: 80,
          backgroundColor: "#f6eee2",
        },
      },
      "property-block": {
        master: {
          opacity: 0.9,
        },
      },
      "stock-name": {
        master: {
          fontSize: 20,
          color: colorPallete.teritary,
          margin: 0,
          fontWeight: 400,
        },
        mobile: {
          fontSize: 18,
        },
      },
      "product-description": {
        master: {
          fontSize: 16,
          margin: 0,
          color: colorPallete.black,
          padding: "2px 0px",
        },
        mobile: {
          fontSize: 14,
        },
      },
      "product-name": {
        master: {
          fontSize: 24,
          fontWeight: 300,
          margin: 0,
          maxWidth: 390,
        },
        mobile: {
          fontSize: 22,
        },
      },
      "product-price": {
        master: {
          fontSize: 28,
          marginTop: 10,
          fontWeight: 400,
          color: colorPallete.black,
        },
        mobile: {
          fontSize: 20,
          fontWeight: 500,
        },
      },
      image: {
        master: {
          width: "100%",
          height: "100%",
          position: "relative",
        },
      },
      "name-price": {
        master: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
        },
      },
      "stack-indicator": {
        master: {
          borderBottom: "1px solid #b9b8b8",
          paddingBottom: 20,
        },
        mobile: {
          marginTop: 20,
        },
      },
      "details-heading": {
        master: {
          fontSize: 18,
          letterSpacing: 0.37,
          padding: "20px 15px 15px 0px",
          fontWeight: 600,
        },
        mobile: {
          fontSize: 17,
        },
      },
      "price-table": {
        master: {
          margin: "10px 0px",
        },
      },
      quantity: {
        master: {
          margin: "20px 0px",
          fontWeight: 600,
        },
      },
      "detail-row": {
        master: {
          display: "flex",
          alignItems: "center",
          margin: "8px 0px",
          fontSize: 12,
        },
        mobile: {
          margin: "5px 0px",
        },
      },
      inventory_list: {
        master: {
          overflow: "auto",
          padding: "20px 40px 40px",
          maxWidth: "100vw",
          whiteSpace: "nowrap",
        },
        mobile: {
          paddingLeft: 5,
        },
        // transition: 'children-slideRight'
      },
      fab: {
        master: {
          backgroundColor: "#ffefef",
          position: "fixed",
          bottom: 30,
          right: 15,
        },
      },
      "basic-info": {
        master: {
          width: 500,
          minWidth: 500,
          margin: "20px 20px 20px",
        },
        mobile: {
          minWidth: "90%",
          margin: "0 auto",
          padding: 20,
        },
      },
      "details-title": {
        master: {
          margin: "10px 0px",
          fontSize: 14,
          fontWeight: 200,
          minWidth: 150,
          color: colorPallete.primary,
        },
        mobile: {
          margin: "5px 0px",
        },
      },
      "details-text": {
        master: {
          color: colorPallete.subtext,
          margin: 0,
          fontWeight: 400,
        },
      },
      "similar-bar-title": {
        master: {
          fontSize: 16,
          textTransform: "uppercase",
          marginLeft: 50,
          letterSpacing: 0.37,
        },
        mobile: {
          marginLeft: 20,
          fontSize: 14,
        },
      },
      "InventoryCard.inventory-card-image-container": {
        master: {
          // maxWidth: 200
        },
      },
      "similar-item-block": {
        master: {
          padding: "10px 40px",
        },
        mobile: {
          padding: "0px 15px",
        },
      },
      "similar-bar": {
        master: {},
      },
    };
  };
}

export default Entities.createThing(ItemModal, {
  name: "ItemModal",
});
