import banner1 from "./resources/images/banner1.jpg";
import banner2 from "./resources/images/banner2.jpg";
import banner3 from "./resources/images/banner3.jpg";

const colorPallete = {
    primary: '#375234',
    secondary: '#f4f4f4',
    teritary: '#ce602b',
    exclusive: '#faf4f4',
    subtext: '#9c9898',
    darkGrey: '#4F4F4F',
    yellow: '#fecb6e',
    black: '#212121',   
    white: '#fdfcf8',
    successGreen: '#54B749', 
    bg: '#fdfcf8',
    backLinkTextColor: '#D03C3C',
    sectionsBg: '#fff'
}

const imageThumbnailSizePallete = {
    50: {
        width: 200,
        height: 200,
    },
    100: {
        width: 300,
        height: 300,
    },
    200: {
        width: 600,
        height: 600
    },
    300: {
        width: 1000,
        height: 1000
    },
    400: {
        width: 1000,
        height: 1000
    },
    500: {
        width: 1000,
        height: 1000
    }
}

const overridingStylesheet = {
    'main-layout': {
        master : {
            maxWidth: '100%',
        },
    },
    'bottom-nav': {
        master: {
            display: 'none'
        }
    },
    'cart-layout': {
        master: {
            padding: '10px 30px 0px',
            justifyContent: 'space-evenly',
            background: colorPallete.bg,
        },
        mobile: {
            padding: '15px 10px 20px'
        }
    },
    'item-grid': {
        master: {
            height: 'calc(100vh - 124px)',
            overflowY: 'auto'
        },
        mobile: {
            height: 'auto',
            overflowY: 'visible',
            padding: 0
        }
    },
    'item-name': {
        master: {
            width: 168,
            lineHeight: 1.3,
            fontSize: 17,
        },
        mobile: {
            width: '100%',
            fontSize: 18,
            fontWeight: 600,
            padding: '0px 0px 10px 0px'
        }
    },
    'categories-list': {
        master: {
            margin: '50px',
        },
        mobile: {
            margin: '50px 10px'
        }
    },
    'cart-headers': {
        master: {
            paddingTop: 0,
            fontSize: 10
        }
    },
    'cart-mainlayout': {
        master: {
            backgroundColor: colorPallete.bg,
            padding: '0px 0px',
            margin: 0
        },
        mobile: {
            padding: 0,
            margin: 0
        }
    },
    'item-name-image': {
        master: {
            width: '150px',
            height: '150px'
        },
        mobile: {
            width: 220,
            height: 170
        }
    },
    'item-descr': {
        master: {
            width: '410px',
            height: '110px'
        },
        mobile: {
            width: 'unset',
            height: 'unset',
        }
    },
    'cart-items': {
        master: {
            alignItems: 'center'
        },
        mobile: {
            margin: '10px 0px',
            borderBottom: '1px solid #c5c5c5',
            padding: '15px 0px'
        }
    },
    'item-details': {
        master: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'space-around',
        },
        mobile: {
            alignItems: 'left',
            flexDirection: 'column',
            justifyContent: 'center',
        }
    },
    'display-board': {
        master: {
            backgroundColor: 'none'
        },
        mobile: {
            backgroundColor: 'none'
        }
    },
    'shop-door': {
        master: {
            backgroundColor: colorPallete.primary
        }
    },
    'arrow-overlay': {
        master: {
            padding: 5,
            top: 30,
            right: 90,
            cursor: 'pointer',
        },
        mobile:{
            opacity: 0.8,
            background: '#fff',
            padding: 10,
            right: 0,
            left: 'unset',
            borderRadius: '50%'
        }
    },
    'ItemModal.arrow-overlay':{
        master:{
            background:'white',
            borderRadius:18
        },
        mobile: {
            top: 10
        }
    },
    'frontwall-categories-list': {
        master: {
            /* margin-top: 20px; */
            padding: 24,
            borderRadius: 24,
            marginTop: 20,
            background: 'rgba(255, 255, 255, 0.49)',
            borderRadius: 16,
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(2px)',
            border: '1px solid rgba(255, 255, 255, 0.3)',
        },   
        mobile: {
            marginTop: 0,
        }
    },
    'FrontWall.shop-banner': {
        master: {
            display: 'flex',
            alignItems: 'center'
        }
    },
    
    'outer-container': {
        mobile: {
            margin: 0,
            padding: '0px 10px'
        }
    },
    'inner-container': {
        mobile: {
            marginTop: 0
        }
    },
    'FrontWall.primary-shelf': {
        master: {
            overflow: 'hidden',
        },
        mobile: {
            borderRadius: 1,
            height: 'auto',
            minHeight: '80vh',
            padding: 0,
            margin: 0,
        }
    },
    'secondary-shelf': {
        master: {
            padding: 70,
            width: 'unset',
            marginBottom: '0px',
            backgroundColor: 'none',
            position: 'relative',
        },
        mobile: {
            padding: '0px 10px',
            marginTop: 20
        }
    },
    'back-container': {
        master: {
            display: 'none'
        }
    },
    'info-title': {
        master: {
            fontFamily: 'unset',
            top: 10,
            position: 'absolute',
            fontSize: 14,
            opacity: 0.7,
            textTransform: 'uppercase',
            letterSpacing: 5,
            fontWeight: 'bold',
            lineHeight: '66px',
        },
        mobile: {
            position: 'relative',
            fontSize: 17,
            top: 0,
            padding: 0,
            marginBottom: 20,
            lineHeight: '24px',
            letterSpacing: 2,
            width: '100%',
            textAlign: 'center',
            padding: '20px 0px',
        }
    },
    'info-block': {
        master: {
            right: 30,
            position: 'absolute',
            margin: '0px auto',
            minHeight: 300,
            flexDirection: 'row',
            padding: 30,
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-start',
            background: 'rgba(255, 255, 255, 0.49)',
            borderRadius: 16,
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(2px)',
            border: '1px solid rgba(255, 255, 255, 0.3)',
            // boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 15px 0px',
            // boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px',
            // boxShadow: 'rgb(0 0 0 / 15%) 0px 5px 15px 0px',
            // boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
        },
        mobile: {
            position: 'relative',
            width: '100%',
            padding: '35px 0px',
            margin: 0,
            marginTop: '-40px',
            right: 0,
            width: '85%',
            alignItems:' center',
            minHeight: 'auto',
            flexDirection: 'column',
            justifyContent: 'center'
        }
    },
    'category-image-container': {
        master: {
            boxShadow: 'rgb(100 100 111 / 20%) 0px 7px 29px 0px',
            width: '100%',
            height: '300px',
            borderRadius: 24,
        },
        mobile: {
            borderRadius: 12,
            marginTop: 0,
            marginRight: 0,
            height: 280,
            minWidth: '100%'
        },
        config: {
            children: {
                entity: 'MenuList',
                props: {
                    // fixedThumbnailDimensions: {
                    //     width: 500,
                    //     height: 500,
                    // }
                }
            }
        }
    },
    'category-name': {
        master: {
            height: 40,
            fontSize: 15,
            letterSpacing: 3,
            fontWeight: 500,
            textTransform: 'uppercase',
            color: colorPallete.white,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center', 
        },
        mobile: {
            padding: '10px 0px',
            margin: '6px 0px',
            fontSize: 12,
            lineHeight: '16px'
        }
    },
    'coupon-image-block': {
        mobile: {
            paddingRight: 0,
            paddingBottom: 0
        }
    },
    'category-name-container': {
        master: {
            borderBottomRightRadius : 2,
            borderBottomLeftRadius : 2,
            position: 'absolute',
            backgroundColor: '#333',
            minWidth: '100%',
            bottom: 0,
            zIndex: 0,
            padding: 0,
            margin: 0,
        },
        mobile: {
            minWidth: '100%'
        }
    },
    'categories-container': {
        master: {
            gridTemplateColumns: 'repeat(4, 1fr)',
            gap: '30px'
        },
        mobile: {
            margin: 0,
            width: '100%',
            gridTemplateColumns: 'repeat(2, [col-start] 50%)'
        }
    },
    'primary-item-image': {
        mobile: {
            width: '100%'
        }
    },
    'section-navigation': {
        master: {
            position: 'unset',
            padding: 0,
            justifyContent: 'space-between',
            width: '100%'
        },
        mobile: {
            padding: '20px 0px 10px 0px',
            fontWeight: 'bold'
        }
    },
    'active-tabs-section-container': {
        master: {
            background: colorPallete.primary,
            boxShadow: 'rgb(0 0 0 / 24%) 0px 3px 8px',
            margin: '0px 15px',
            borderRadius: 25,
            padding: '0px 10px',
            display: 'flex',
            alignItems: 'center',
        }
    },
    'tabs-section-container': {
        master: {
            cursor: 'pointer'
        }
    },
    'section-name': {
        master: {
            backgroundColor:'unset',
            color: colorPallete.black,
            padding: '0px 5px',
            padding: 10,
            margin: 0
        }
    },
    'active-section-name': {
        master: {
            backgroundColor:'unset',
            color: colorPallete.secondary,
            padding: '5px 10px 7px 10px',
            margin: 0
        }
    },
    'product-price':{
        master: {
            fontSize: 24,
            fontWeight: 600,
            color: colorPallete.darkGrey
        },
        mobile: {
            marginTop: 0
        }
    },
    'section-inventory-list':{
        config: {
            children: {
                entity: 'InventoryListByCategory',
                props: {
                    isMobileCardHeight : 430,
                    isdesktopCadHeight : 380,
                    isdesktopCadWidth : 260,
                    isMobileDualColumn : true,
                    gridBackgroundColor: colorPallete.white
                }
            }
        }
    },
    'primary-shelf-block':{
        master: {
            minHeight: 'unset',
            height: '100%'
        },
        mobile:{
            height: '100%',
            minHeight: '100%',
            boxShadow: 'rgb(0 0 0 / 9%) 0px 3px 12px',
        },
        config: {
            children: {
                entity: 'StoryBanner',
                props: {
                    images: [banner1, banner2, banner3 ],
                    storyLine: "Everything your home needs",
                    caption: "Your home should be clean, tidy and healthy. It’s essential to have the fresh fruits and vegetables to keep your home disease free and healthy. In the same way having right cleaning supplies on hand—stock up on cleaning products to keep your home clean and organized"
                    // url: 'https://firebasestorage.googleapis.com/v0/b/nsquare-9a5b2.appspot.com/o/videos%2FC0009_1.mp4?alt=media&token=08d69271-5add-44d7-a25a-25069f5cff6b'
                }
            }
        }
    },
    'section-tabs': {
        master: {
            width: '100%',
            padding: '0px 0px 10px 0px',
            marginBottom: 50,
            overflowY: 'hidden',
            overflowX: 'scroll',
            margin: '0px auto',
            height: 'unset',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
        },
        mobile: {
            padding: 0,
            paddingBottom: 0,
            justifyContent: 'unset',
            background: colorPallete.bg,
        }
    },
    'video-info': {
        master: {
            display: 'none',
            left: '10%'
        }
    },
    'video-header': {
        master: {
            fontSize: 78,
            lineHeight: 1.1,
            letterSpacing: 4,
        }
    },
    'video-caption': {
        master: {
            color: '#f4f4f4',
            fontWeight: 100,
        }
    },
    'info-text': {
        master: {
            color: '#403d3d',
            marginLeft: 'auto',
            maxWidth: 540,
            fontSize: 34,
            fontWeight: 600,
            lineHeight: '46px',
        },
        mobile: {
            fontSize: 18,
            lineHeight: 1.8,
            margin: 'auto',
            width: '80%',
            textAlign: 'center',
        }
    },
    'info-media': {
        master: {
            minWidth: '45vw',
            maxWidth: '45vw',
            height: 650
        },
        mobile: {
            minWidth: '100%',
            maxWidth: '100%',
            height: 400,
        },
        config: {
            children: {
                entity: 'StoryVideo',
                props: {
                    url: 'https://firebasestorage.googleapis.com/v0/b/hashmi-supermarket.appspot.com/o/pexels-los-muertos-crew-8446796.mp4?alt=media&token=7a357672-8235-4a69-8f0f-fa5506d4f9cc',
                }
            }
        },
    },
    'icon-block': {
        master: {
            margin: 0
        },
        mobile: {
            margin: 0
        }
    },
    'bg-video': {
        master: {
            borderRadius: 24
        }
    },
    'info-media.bg-video.video-container': {
        master: {
            opacity: 1
        }
    },
    'primary-shelf-block.bg-video.video-container': {
        master: {
            opacity: 0.7
        }
    },
    
    'info-block-container': {
        master: {
            margin: '0px 100px 100px',
            height: 'auto',
            // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            background: 'none',
            flexDirection: 'row',
            alignItems: 'center'
        },
        mobile: {
            height: '100%',
            margin: '30px 0px',
            flexDirection: 'column'
        }
    },
    'FrontWall.primary-shelf.name': {
        master: {
            color: colorPallete.primary,
            fontWeight: 'bold'
        }
    },
    'categories' : {
        master: {
            backgroundColor: 'none',
            padding: '0px 100px 50px'
        },
        mobile: {
            padding: 20
        }
    },
    'category-block-layout': {
        master: {
            width: '100%',
            margin: 0,
            borderRadius: 4,
            position: 'relative'
        },
        mobile: {
            borderRadius: 4,
            margin: 0,
            height: 'unset',
            flexDirection: 'column'
        }
    },
    'FrontWall.grid-block-text': {
        master: {
            fontSize: 17,
            lineHeight: 1.5,
            color: '#212121',
            fontWeight: 400
        },
        mobile: {
            fontWeight: 400,
            textTransform: 'uppercase'
        }
    },
    'FrontWall.block-title': {
        master: {
            color: colorPallete.black,
            fontWeight: 'bold',
            fontSize: 34,
            fontFamily: 'Lora',
            lineHeight: 1.5,
            letterSpacing: 3,
            textTransform: 'uppercase',
        },
        mobile: {
            fontSize: 28,
            textAlign: 'center',
            letterSpacing: 1,
            margin: '30px 0px'
        }
    },
    'FrontWall.board-layout.blocks.1.DisplayBoard.primary-shelf.layouts.2.layouts.2.name': {
        master: {
            fontSize: 22,
            fontWeight: 'bold',
        }
    },
    "FrontWall.board-layout.blocks.1.DisplayBoard.grid-block-text": {
        master: {
            textTransform: 'Capitalize',
            fontSize: 14,
            letterSpacing: 0.37,
            lineHeight: 1.5,
            fontWeight: 600,
            maxWidth: 300
        },
        mobile: {
            fontSize: 16,
            maxWidth: '95%'
        }
    },
    'inventory-wheeler-main-layout': {
        master: {
            flexDirection: 'column'
        }
    },
    'counter': {
        master: {
            padding: 'unset'
        },
    },
    'counter-outline': {
        master: {
            border: 0,
            marginRight: 30,
            padding: '15px 0px'
        },
        mobile: {
            margin: '0px 10px',
            padding: '18px 0px'
        }
    },
    'Profile.profile-name': {
        master: {
            color: colorPallete.primary
        }
    },
    'Profile.title': {
        master: {
            color: colorPallete.primary
        }
    },
    'InventoriesList.items-layout': {
        master: {
            padding: '10px 0px 100px',
        }

    },
    'product-name': {
        master: {
            fontSize: 24,
            color: colorPallete.black,
            fontWeight: 'bold',
            lineHeight: 1.3,
            maxWidth: 400,
            paddingBottom : 10
        },
        mobile: {
            fontSize: 34,
            maxWidth: 235,
            lineHeight: 1.1
        }
    },
    'product-details': {
        master: {
            paddingBottom: 20,
            borderBottom: '1px solid rgb(185, 184, 184)',
        }
    },
    'name-price':{
        master: {
            fontSize: 48,
            top: 80,
            left: 100,
            lineHeight: 1
        },
        mobile: {
            position: 'relative',
            flexDirection: 'row',
            alignItems: 'flex-start',
            top: 'unset',
            left: 'unset'
        }
    },
    'cart-buttons': {
        mobile: {
            width: '100%',
            backgroundColor: 'unset',
            borderTop: 'none',
            padding: 0
        }
    },
    'item-modal-main-item-image': {
        master: {
            margin: '0px auto',
            // position: 'absolute',
            // top: '50%',
            // transform: 'translateY(-15%)',
        },
        mobile: {
            position: 'unset',
            top: 'unset',
            transform: 'unset'
        }
    },
    'item-image-container': {
        master: {
            width: '40%',
            height: '60%',
        },
        mobile: {
            paddingTop: 'unset'
        }
    },
    'stock-name': {
        master: {
            fontSize: 10,
            margin: 0,
            fontWeight: 600,
            textTransform: 'uppercase',
            letterSpacing: 1,
        },
        mobile: {
            fontSize: 10
        }
    },
    'product-description': {
        master: {
            fontSize: 13,
            //lineHeight: '15px',
            //padding: '10px 0px'
        }
    },
    'stack-indicator': {
        master: {
        }
    },
    'name': {
        master: {
            fontSize: 20,
            fontWeight: 500
        }
    },
    'ItemModal.basic-info.blocks.4': {
        master: {
            padding: '10px 0px'
        }
    },
    'basic-info': {
        master: {
            margin: '45px 0px',
        },
        mobile: {
            padding: 15,
            paddingBottom:90
        }
    },
    'detail-row': {
        mobile: {
            margin: 0,
        }
    },
    'details-heading': {
        master: {
            color: colorPallete.darkGrey,
            lineHeight: '15px',
            margin: 0,
            fontSize: 20,
            letterSpacing: 'none',
        }
    },
    'details-title': {
        master: {
            color: colorPallete.darkGrey,
            lineHeight: 1.5,
            margin: 0
        }
    },
    'details-text': {
        master: {
            fontWeight: 600,
            lineHeight: 1.5,
            fontSize: 14,
            color: colorPallete.darkGrey,
        }
    },
    'item-price': {
        master: {
            fontSize: 16,
            fontWeight: 600
        }
    },
    'price' :{
        mobile: {
            padding: '0px 0px 10px'
        }
    },
    'block-header': {
        master: {
            display: 'none',
            padding: '0px 0px 25px 0px',
            textAlign: 'center',
        },
        mobile: {
            textAlign: 'none'
        }
    },
    'charges': {
        master: {

        }
    },
    'Billing-block': {
        master: {
            backgroundColor: '#faf4f4',
            padding: "30px 50px",
            margin: 0,
            borderRadius: 4,
            height: '100%'
        },
        mobile: {
            padding: '40px 15px'
        }
    },
    'billable-charge': {
        master: {
            margin: 0,
            fontSize: 20
        }
    },
    'bottom-tab': {
        master: {
            position: 'absolute',
            backgroundColor: 'transparent',
            right: '10%',
            width: 'unset',
            bottom: 0
        },
        mobile: {
            position: 'relative',
            right: 'unset'
        }
    },
    'order-now-container': {
        master: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

        }
    },
    'order-now': {
        master: {
            width: 300,
        }
    },
    'order-now-text': {
        master: {

        }
    },
    'location-details': {
        master: {
            fontSize: 14,
            color: colorPallete.primary,
            paddingBottom: 5,
            color: colorPallete.teritary
        },
        mobile: {
            margin: '15px 0px'
        }
    },
    'delivery-charge': {
        master: {
            color: colorPallete.darkGrey,
            fontSize: 14
        }
    },
    'charge-price': {
        master: {
            color: colorPallete.darkGrey,
            fontSize: 16,
        }
    },
    'counter-block': {
        master: {
            flexDirection: 'column',
        }
    },
    'counter-outline-add': {
        master: {
            border: '1px solid #333',
            borderColor: colorPallete.black,
            boxShadow: 'rgb(0 0 0 / 8%) 0px 4px 12px',
            background: colorPallete.black,
            width: '100%',
            maxWidth: 400,
            borderRadius: 0,
            padding: '5px 0px',
            display: 'inline-block',
            height: 'auto',
            margin: '0px 0px',
        },
        'mobile': {
            margin: 0,
            padding: '7px 0px'
        }
    },
    'section-inventories': {
        master: {
            height: 'calc(100vh - 178px)',
            width: '100vw',
            padding: '0px',
            marginTop: 0,
            marginLeft: 0
        }
    },
    'rates': {
        master: {
            fontSize: 16
        }
    },
    'card-title': {
        master: {
            fontSize: 17,
            fontFamily: 'Lora',
            letterSpacing: '0.67px',
            fontWeight: 600,
            height: 50,
            margin: '3px 0px 0px'
        },
        mobile: {
            fontSize: 17,
            fontWeight: 400
        }
    },
    'counter-layout': {
        master: {
            margin: '0px 0px',
            height: 'unset',
            width: '100%',
            paddingTop: 10,
        },
        mobile: {

            marginTop: 10,
        }
    },
    'grid-block-price': {
        master: {
            fontSize: 17,
            fontWeight: 500,
        },
        mobile: {
            fontSize: 14
        }
    },
    'counter-button': {
        master: {
            color: colorPallete.white,
            fontWeight: 500,
            fontSize: 14,
            letterSpacing: 2,
            textTransform: 'uppercase'
        },
        mobile: {
            fontSize: 16
        }
    },
    'button': {
        master: {
            maxWidth: 'unset',
            margin: 0
        }
    },
    'more-container': {
        master: {
            display: 'block',
            backgroundColor: 'none',
            border: '0.1px solid #212121',
            marginLeft: 30
        },
        mobile: {
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            right: 0,
            top: 0,
            backgroundColor: 'none',
            padding: '20px 0px',
            width: 180,
            margin: '0px auto 30px',
        }
    },
    'product-description-container': {
        master: {
            maxWidth: 400,
            fontSize: 12,
            opacity: 0.7
        }
    },
    'grid-block-more': {
        master: {
            width: 'unset',
            color: colorPallete.black,
        },
        mobile: {
            height: 'unset',
            padding: 0,
            width: 200,
            color: '#fff'
        }
    },
    'front-arrow': {
        mobile: {
            border: '1px solid #fff',
            margin: '0px 15px',
            background: '#212121',
        }
    },
    'more': {
        master: {
            color: '#212121',
        },
        mobile: {
            fontSize: 17
        }
    },
    'info-main-block': {
        master: {
            margin: '0px 0px 0px'
        },
        mobile: {
            margin: 20
        }
    },
    'info-image': {
        master: {
            boxShadow:' rgb(0 0 0 / 15%) 0px 5px 15px 0px',
            minWidth: '100%',
            height: '100%',
            borderRadius: 24
        },
        mobile: {
            boxShadow: 'none',
            height: 'auto',
            marginRight: 0,
            marginTop: 10,
            marginBottom: 10,
            minWidth: '100%',
            borderRadius: 14
        }
    },
    'nav-search-outer':{
        master: {
            width: 310
        }
    },
    'info-image-block': {
        master: {
            margin: '0px 100px',
            padding: '50px 0px',
        },
        mobile: {
            margin: 10,
            padding: 0
        }
    },
    'login': {
        master: {
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        mobile: {
            justifyContent: 'flex-start'
        }
    },
    'login-message': {
        master: {
            display: 'flex',
            alignItems: 'center'
        }
    },
    'app-bottom-nav-container': {

        config: {
            children: {
                entity: 'BottomNav',
                props: {
                    // hideLabels: true
                }
            }
        },
    },
    'nav-search': {
        config: {
            children: {
                entity: "SearchBar"
            }
        }
    },
    'whatsapp-button': {
        mobile: {
            bottom: 80
        }
    },
    'app-bottom-nav': {
        master: {
            root: {
                display: 'none'
            },
            action: {
                display: 'none'
            }
        },
        mobile: {
            root: {
                display: 'flex',
                flexDirection: 'row',
            },
            action: {
                root: {
                    
                },
                selected: {
                    color: colorPallete.primary
                }
            }
        }
    },
    'login-message-text': {
        master: {
            fontSize: 14
        }
    },
    'location-title': {
        master: {
            fontSize: 12,
            textTransform: 'uppercase'
        }
    },
    'cart-header-text': {
        master: {
            fontSize: 28,
            fontWeight: 500,
            marginRight: 12
        }
    },
    'top-bar-outer' : {
        master: {
            backgroundColor: 'transparent',
            borderBottom: 'none',
            height: '100%',
        },
        mobile: {
            height: '100%'
        }
    },
    'coupon-showcase' : {
        master: {
            display: 'none',
            margin: 40
        },
        mobile: {
            display: 'none',
            margin: '15px 0px'
        }
    },
    'nav-link': {
        master: {
            textDecoration: 'none',
            color: '#4c4b4b !important',
            fontSize: 16
        }
    },
    'logo-img': {
        master: {
            borderRadius : '50%'
        }
    },
    'top-bar-container' : {
        master: {
            width: '80%',
            height: 84,
            margin: '30px auto 0px ',
            background: 'rgba(255, 255, 255, 0.49)',
            borderRadius: 16,
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(2px)',
            border: '1px solid rgba(255, 255, 255, 0.3)',
        },
        mobile: {
            position: 'relative',
            height: 78,
            margin: 0,
            width: '100%',
        }
    },
    'intro-image-outer': {
        mobile: {
            maxWidth: '100%',
            minWidth: '100%',
            height: 670,
        }
    },
    'intro-layout': {
        master: {
            margin: '14px',
            background: 'rgba(255, 255, 255, 0.49)',
            borderRadius: 16,
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(2px)',
            border: '1px solid rgba(255, 255, 255, 0.3)',
        },
        mobile: {
            margin: '20px 15px',
            flexDirection: 'column',
        }
    },
    'message-block': {
        mobile: {
            justifyContent: 'flex-start',
            padding: 20
        }
    },
    'top-bar': {
        master: {
        },
        mobile: {
            marginTop: -3,
            padding: '0px 10px'
        }
    },
    'top-bar-search' : {
        master: {
            height: '100%'
        },
        mobile: {
            height: 80
        }
    },
    'navigation-link': {
        master: {
            display: 'none'
        }
    },
    'category-header': {
        master: {
            fontSize: '34px',
            color: colorPallete.black,
            textAlign: 'none',
            letterSpacing: '0.3px'
        },
        mobile: {
            fontSize: 44,
            margin: '0px 20px 10px 20px'
        }
    },
    'price-block': {
        master: {
            display: 'unset',
            alignItems: 'center',
            height: 'unset',
            color: colorPallete.black,
            fontSize: 15,
            fontWeight: 'bold'
        }
    },
    'nav-icons' : {
        master: {
            height: 74,
            backgroundColor: 'transparent'
        },
        mobile: {
            padding: 0,
            height: 54
        }
    },
    'studio-link' : {
        master: {
            backgroundColor: 'transparent',
            margin: '40px auto 0px auto',
            padding: 0
        }
    },
    'studio-link-align': {
        master: {
            justifyContent: 'center'
        }
    },
    'shop-title': {
        master: {
            fontSize: 30,
            fontWeight: 500,
            color: '#212121',
            letterSpacing: 1,
            lineHeight: 1.5,
            fontFamily: 'Lora',
            display: 'none'
        }
    },
    'order-now-button': {
        master: {
            padding: '5px 10px',
            width: 300
        },
        mobile: {
            padding: 10,
            width: '92%'
        }
    },
    'render-content-block': {
        master: {
            margin: 0
        }
    },
    'delivery-title-block': {
        master: {
            position: 'absolute',
            maxWidth: 200,
            right: 60,
            top: 62
        },
        mobile: {
            position: 'absolute',
            maxWidth: 280,
            left: 20,
            top: 10,
        }
    },
    'delivery-title' :{
        master: {
            color: '#212121',
            fontSize: 18,
        },
        mobile: {
            color: '#212121',
            fontSize: 21,
        }
    },
    'shop-caption': {
        master: {
            display: 'none'
        }
    },
    'caption': {
        mobile: {
            fontSize: 13,
        }
    },
    'studio-link-margin': {
        master: {
            color: colorPallete.accent
        }
    },
    'powered-by': {
        master: {
            color: colorPallete.secondary
        }
    },
    'grid-block-image' : {
        master: {
            background: 'none',
            width: '100%',
            height: 180,
            padding: 0
        },
        mobile: {
            width: '100%',
            height: 580,
            padding: 0
        }
    },
    'media-showcase' : {
        master: {
            width: 400,
        },
        config: {
            children: {
                entity: "Magnifier"
            }
        },
        master: {
            width: '300px',
            height: '100%',
            display: 'block',
            margin: '0px auto'
        },
        mobile: {
            width: '100%',
            height: '50vh',
            minHeight: 300
        }
    },
    'secondary-self-block': {
        master: {
            padding: 20,
            borderRadius: 20,
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        }
    },
    'delivery-container': {
        master: {
            display: 'flex',
            alignItems: 'center'
        },
        mobile: {
            flexDirection: 'column'
        }
    },
    'delivery-image' :{
        master: {
            width: 320
        },
        mobile: {
            width :328
        }
    },
    'secondary-shelf-item-grid': {
        master: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
            gap: '50px 30px',
            overflow: 'hidden',
            // background: 'rgba(255, 255, 255, 0.49)',
            borderRadius: 16,
            // boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            // backdropFilter: 'blur(5px)',
            // border: '1px solid rgba(255, 255, 255, 0.3)',
        },
        mobile: {
            gridTemplateColumns: 'repeat(auto-fill, minmax(100vw, 1fr))',
            gap: '20px 0px',
            padding: 0
        }
    },
    'secondary-info-block': {
        master: {
            width: '100%',
            marginRight: 0
        },
        mobile: {
            width: '100%',
            marginRight: 0
        }
    },
    'ShopSections.layouts.1.Header.section-navigation': {
        master: {
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0px 0px'
        },
        mobile: {
            padding: '20px 0px 0px'
        }
    },
    'VendorInventoryList.layouts.1.Header.section-navigation': {
        master: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px 0px'
        }
    },
    'header-layout': {
        mobile: {
        }
    },
    'Cart.layouts.1.layouts.1.Header.section-navigation': {
        master: {
            padding: '50px 0px 20px',
        }
    },
    "signin-bg": {
        master: {
            width: 220,
            height: 600,
        //   backgroundImage: `url(${
        //     require("./resources/images/bag.png").default
        //   }`,
          right: 200
        },
        mobile:{
            width: 300,
            height: 300,
            right: '-17px',
            bottom: 0,
            top: 'unset'
        }
    },
    'inventory-card-image': {
        master: {
            width: '85%',
            maxWidth: '100%',
            height: '85%',
            borderRadius: 6
        },
        config: {
            children: {
                entity: 'MenuList',
                props: {
                    // fixedThumbnailDimensions: {
                    //     width: 500,
                    //     height: 500,
                    // }
                }
            }
        },
        mobile: {
            //height: '100%',
        }
    },
    'inventory-list-card-image': {
        master: {
            width: '80%',
            height: '80%',
        }
    },
    'discount-percentage': {
        master: {
            fontWeight: 'bold',
            padding: '0px 3px',
            fontSize: 14,
        },
        mobile: {
            fontWeight: 'bold',
            fontSize: 14,
            padding: 0,
            margin: '5px 0px'
        }
    },
    'secondary-text': {
        master: {
            padding: '0px 3px',
            fontSize: 13,
        }
    },
    'testimonial-carousel': {
        master: {
            padding: 50,
            margin: 0,
            width: '70%',
            margin: '0px auto',
        },
        mobile: {
            padding: 0,
            margin: 0,
            width: '100%',

        }
    },
    'testimonial-details-block': {
        master: {
            margin: 'auto',
            minHeight: 180,
            background: 'rgba(255, 255, 255, 0.49)',
            borderRadius: 16,
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.3)',
        },
        mobile: {
            width: 'unset',
            padding: 20,
            margin: 20
        }
    },
    'nav-icon-text':{
        master:{
            fontSize:'15px'
        }
    },
    'discount-badge': {
        master: {
            position: "absolute",
            alignItems: 'center',
            //justifyContent: 'center',
            //height : 34,
            background: '#eab951',
            //width: '40%',
            color: colorPallete.white,
            display: 'flex',
            padding: '0px 10px',
            top: 15,
            left: 0
        },
        mobile: {
            width: 50,
            top: 9,
            left: 3
        }
    },
    'message-line':{
        master: {
            fontSize: 52,
            textAlign: 'left',
            margin: 0,
            lineHeight: '60px',
        },
        mobile: {
            textAlign: 'center',
            fontSize: '34px',
            lineHeight: '46px',
            marginTop: 30
        }
    },
    'message-caption-outer':{
        master:{
            marginTop: 20
        },
        mobile: {
            marginTop: 0
        }
    },
    'message-caption': {
        master: {
            fontSize: 17,
            lineHeight: '24px',
            opacity: 0.7
        }
    },
    'message-container': {
        master: {
            width: 400,
            margin: 'auto'
        },
        mobile: {
            width: '100%'
        }
    },
    'footer': {
        master: {
            marginTop: -5,
            padding: '100px 70px',
            paddingTop: 0,
            width: 'unset',
            backgroundColor: colorPallete.black
        },
        mobile: {
            marginTop: 35,
        }
    },
    'show-case': {
        mobile: {
            width: '100%',
            maxHeight :180,
            boxShadow: 'unset'
        }
    },
    'item-modal-layout': {
        master: {
            width: '85%',
            marginLeft: 'auto',
            margin: 'unset',
            justifyContent: 'space-evenly',
        },
        mobile: {
            overflowY: 'auto',
            height: '100vh'
        }
    },
    'media-block': {
        master: {
            objectFit: 'cover'
        }
    },
    'item-details-container': {
        master: {
            height: '100%',
            padding: '0px 60px',
            overflowY: 'scroll',
            backgroundColor: '#fff',
            borderRadius: 6,
            marginLeft: 50
        },
        mobile: {
            padding: 0,
            marginLeft: 0,
            height: 'auto',
            overflowY: 'unset',
        }
    },
    'footer-links-title': {
        master: {
            color: colorPallete.secondary
        }
    },
    'footer-title': {
        master: {
            color: colorPallete.secondary
        }
    },
    'footer-link': {
        master: {
            fontWeight: 100,
            color: colorPallete.secondary,
            letterSpacing: 2,
            fontSize: 12,
            lineHeight: 3,
            textTransform: 'uppercase',
        }
    },
    'ShopSections.main-layout': {
        master :{
            padding: '30ps auto',
        }
    },
    'password': {
        master: {
            height: '100%'
        }
    },
    'shopsection-card-image': {
        master: {
            width: 150,
            height: 200,
        },
        mobile: {
            width: 120,
            height: 170
        }
    },
    "shopsection-item": {
        master: {
            width: 240,
            margin: 0,
            height: 320,
        },
        mobile: {
            width: 179,
            alignItems: 'center',
            justifyContent: 'space-evenly',
            flexDirection: 'column'
        }
    },
    'shopsection-item-container': {
        master: {
            //width: 220
        },
        mobile: {
            width: 200
        }
    },
    'inventory-details': {
        master: {
            width: '100%'
        }
    },
    "inventory-image-block": {
        master: {
            borderRadius: 2
        },
        mobile: {
            width: '100%',
            height: '100%'
        }
    },
    'image-block': {
        master: {
            objectFit: 'cover'
        }
    },
    'info-banner': {
        master: {
            objectFit: 'cover'
        }
    },
    'logo': {
        master: {
            width: '100%',
            height: 54,
        },
        mobile: {
            width: 48,
            height: 48,
            marginTop: 0,
            marginLeft: 0,
        }
    },
    'icons-styles':{
        master:{
            color:'white'
        }
    },
    'section-grid': {
        mobile: {
            paddingBottom: 100
        }
    }
}

// isMobile ? 180 : 380
const shopConfig = {
    apiKey: "AIzaSyBUadOWIn3hvodskPbViTVIetjdf15Y0UY",
    authDomain: "ebr-shopee.firebaseapp.com",
    databaseURL: "https://ebr-shopee.firebaseio.com",
    projectId: "ebr-shopee",
    storageBucket: "ebr-shopee.appspot.com",
    messagingSenderId: "966181549394",
    appId: "1:966181549394:web:3454c06001e74b28a8368b",
    measurementId: "G-5DLGCEJZGE"
};
const firebaseConfig = {
    apiKey: "AIzaSyBXf7bd4wut9AcX_WVRu_L0BGLmpE9w79Y",
    authDomain: "hashmi-supermarket.firebaseapp.com",
    projectId: "hashmi-supermarket",
    storageBucket: "hashmi-supermarket.appspot.com",
    messagingSenderId: "702332989944",
    appId: "1:702332989944:web:e5a2aef5cdcb553fefa60d",
    measurementId: "G-XQ28WGVYYC"
};


const shop = {
    name: 'Hashmi Brothers Mega SuperMarket',
    description: "Serving the Mahabub nagar City, since 1970.",
    about: {
        title: 'CONSCIOUS FOOD - BUY ORGANIC FOOD ONLINE',
        text: 'Conscious Food is the pioneer of organic food in India since 1990. We are certified from ECOCERT. Choose from a curated range of Cerals, Organic & Natural Flours, Grains, Raw Sugars, power packed snacks, seeds, nuts, cold-pressed organic oils also used as cooking oils(edible oils), organic pulses, digestion friendly spices, filer coffee, a2 gir grow ghee natural oils'
    },
    companyName:"64046d2dd8b78a32508e4968",
    quantity: "64046dcad8b78a32508e496a",
    logo: `${require('../images/hashmi-dark.bmp').default}`,
    compactLogo: `${require('../images/hashmi-dark.bmp').default}`,
    // bankaccount: {
    //     razorpayAccountId: 'acc_GhQkSv405svGMb',
    //     branch: "Axis bank LTD, indiranagar, Bengaluru",
    //     name: "Nature for future organic mini mart"
    // },
    infoBlockImage: require('../images/info-image.png').default,
    infoBlockHeader: 'Our Happy Customers',
    infoBlockDescr: 'One of the best Super Market in the City of hyderabad. Most probably the oldest and the best Super Market in the city.  ~ Preeti',
    googleMapLink: 'https://www.google.com/maps/place/Hashmi+Brother+s/@16.7462034,77.9942908,15.2z/data=!4m5!3m4!1s0x3bca261e14790d1d:0x45056317a911136c!8m2!3d16.747475!4d78.0012763',
    address: ['Hashmi Plaza,', '1-5-39, New Town,', 'Mahabub Nagar,', 'Telangana-509001.'],
    categoryBgImage: require('../images/category-bg.png').default,
    contact: {
        name: 'Aftab Hussain Hashmi',
        phone: '9642666000',
        email: 'hashmiaftab@gmail.com'
    },
    businessHours: 'Mon - Sun: 7:00 AM – 8:30 PM',
    webAddress: 'www.smartshop.in',
    referrals: {
        inviteSMS: {
            title: `Refer now & avail discounts on Hashmi Brothers Mega SuperMarket`,
            text: `Invite your friends to avail discount of FLAT 3% OFF on Hashmi Brothers Mega SuperMarket. `,
            url: 'https://hashmistore.in/'
        },
        profileBlock: {
            description: `Invite your friends to avail discount of FLAT 3% OFF on Hashmi Brothers Mega SuperMarket.`,
            detail: ' Your friend gets a Coupon worth 3% FLAT OFF on using your referral code while signup. You would get Coupon worth same on first order of your friend.',
        },
        featuredImage: 'https://firebasestorage.googleapis.com/v0/b/nature-for-future.appspot.com/o/refer.png?alt=media&token=00a15103-487d-405a-a5c8-a6a14e0f3f59',
    },
    paytm:{
        mid: 'ORvoPZ80727235686488',
        mkey: 'em&_#XcIJ%XJ8i3p'
    },
    socialmedialinks: {
        Instagram: 'https://www.instagram.com/',
        Facebook: 'https://www.facebook.com/',
        Twitter: 'https://www.twitter.com/',
        YouTube: 'https://www.youtube.com/'
    },
    testimonials:[{
        name:'Suresh Kumar G',
        descr:'More Hypermart is Big and Good.. Best Place to buy Groceries and all essential for Home.. Clothes, Shoes & Sandals, Cutlery, Stationery Items, Vegetables and more are available.. This is like one stop shop for all House Hold needs.. This a very big place and Very Spacious for Shopping and Parking is also very big.. very well maintained and staff is very friendly too..Good Store..'
    },{
        name:'sumbul ansari',
        descr:'Most products are discounted, place is never too crowded and checkout doesnt take long. Would suggest to take own bags as the ones they provide are charged and not very sturdy.'
    },{
        name:'hareesh Pasupuleti',
        descr:'It used to be  very convenient and one stop for all house hold needs but in recent days they are not keeping the inventory updated and many shelfs are literally empty and staff has no idea if they would restock the items as well'
    }]
}

const config = {
    development: {
        //serverURL: 'http://34.93.118.183:81',
        serverURL:'https://hashmi-supermarket-cloud.ebrdesignstudio.in',
       // serverURL: 'http://localhost:5000',
        shopId: '63bba89c8e72d39514de3da6',
        sentry:"https://68c01d955a124caba504e4d55acc3631@o963101.ingest.sentry.io/5911275",
        //logrocket : "ehpfzt/e-shops",
        razorpay: {
            key_id: "rzp_live_partner_GkZWSgviA4hJDV",
            key_secret: "OeJazVJ2u7EOI7SlXnfecE0h",
        },
        overridingStylesheet,
        colorPallete,
        shop,
        firebase: {
            shop: shopConfig,
            firebaseConfig,
        },
        imageThumbnailSizePallete
    },
    production: {
        serverURL: 'https://hashmi-supermarket-cloud.ebrdesignstudio.in',
        
        shopId: '63bba89c8e72d39514de3da6',
        sentry:"https://68c01d955a124caba504e4d55acc3631@o963101.ingest.sentry.io/5911275",
        logrocket : "ehpfzt/e-shops",
        razorpay: {
            key_id: "rzp_live_partner_GkZWSgviA4hJDV",
            key_secret: "OeJazVJ2u7EOI7SlXnfecE0h",
        },
        overridingStylesheet,
        colorPallete,
        shop,
        firebase: {
            shop: shopConfig,
            firebaseConfig,
        },
        imageThumbnailSizePallete
    }
}

export default config;