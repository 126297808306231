import _ from 'lodash';
import api from '@api';
import VendorHelper from './vendorHelper';
import config from "@config/index";

class InventoryHelper {

    constructor() {
        this.sectionalInventories = [];
        this.vendorHelper = new VendorHelper()
    }

    // getAllInventories = async () =>{
    //     const inventories = await api.Inventories.find({query:{}})
    //     return inventories
    // }

    getPrimarySectionNames = async (categoryId) =>{
        const pool = await api.Inventories.fetchPrimarySectionNames(categoryId)
        return pool
    }

    getSecondarySectionNames = async (categoryId) =>{
        const pool = await api.Inventories.fetchSecondarySectionNames(categoryId)
        return pool
    }

    getSectionInventories = async (value, id, filterData) => {
        // console.log(value, id)
        let inventories = []
        // const inSectionInventories = _.find(this.sectionalInventories, ({section : value}))
        const data = {
            section : value
        }

        // removing hideInventory = true will be taken by this api itself
        inventories = await api.Inventories.fetchSectionInventories(value, id, filterData)
        data.inventories = {...inventories}
        this.sectionalInventories.push(data)
        return inventories
    }

    isEqual = (objectIdOne, objectIdTwo) =>{
        if(objectIdOne.toString() === objectIdTwo.toString())
        return true
    }

    getDisplayBoardItems = async () => {
        return await api.Inventories.getDisplayBoardItems()
    }

    // getValues = async (propertyId) =>{
    //     let inventories = await api.Inventories.find({query:{}})
    //     const filteredLabel = _.reduce(inventories, (filteredpropertyLabel, inventory)=>{
    //         const property = _.find(inventory.properties, property=>{
    //             return (property.propertyId.toString() === propertyId.toString())
    //         })
    //         if(property){
    //             filteredpropertyLabel.push(_.trim(_.toUpper(property.value)));
    //         }
    //         return filteredpropertyLabel
    //     }, [])
    //     let pool = _.uniq(_.compact(_.map(filteredpropertyLabel)))
    //     return pool
    // }

    getLabeledInventories = async () =>{
        const inventories = await api.Inventories.find({query:{}})
        _.forEach(inventories, inventory =>{
            const updatedLabel ={
                name : _.upperFirst(_.toLower(inventory.name))
            }
            api.Inventories.update(inventory._id, updatedLabel)
        })

    }

    getByLabelGroups = (inventories) => {
        _.pull(inventories, {"hideInventory" :true})
        const groupedInventories = _.groupBy(inventories,'label')
        console.log(groupedInventories)
        return groupedInventories
    }

    getByLabel = async (label) =>{
        const inventories = await api.Inventories.find({query:{label:label}})
        return inventories
    }

    getInventoriesCount = async (id) =>{
        let count = await api.Inventories.inventoriesLinkedWithCategory(id)
        return count.data
    }

    // getCategoryList = async () =>{
    //     const categories = await api.Categories.find({query:{}})
    //     let list = []
    //     _.forEach(categories, category =>{
    //         let data = {
    //             label : category.name,
    //             value: category._id
    //         }
    //         list.push(data)
    //     })
    //     return list
    // }
    getPropertiesList = async () =>{
        const properties = await api.Properties.find({query:{}})
        let list = []
        _.forEach(properties, property =>{
            let data ={
                label: property.label,
                value: property._id
            }
            list.push(data)
        })
        return list
    }

    getInventoryList = (group, groupedInventories) =>{
        const clonedGroupedInventories = _.cloneDeep(groupedInventories);
        const currentGroupLabel = _.get(group, '0.label');
        delete clonedGroupedInventories[currentGroupLabel];

        return clonedGroupedInventories
    }

    getInventoryById = async (id)=>{
        const inventory = await api.Inventories.getById(id, {
            expandFields: {
                tags: 'tag'
            }})
        return inventory
    }

    getExpandedInventory = async (id) =>{
        let inventory = await this.getInventoryById(id)
        const properties = await api.Properties.find({query:{}})
        _.map(inventory.properties, property =>{
            let prop =  _.find(properties, {_id:property.propertyId})
            property.label = prop.label
            return inventory
        })
        return inventory
    }

    onUpdateMany = async (ids, values) =>{
        await api.Inventories.updateMany(ids, values)
    }

    // createProperty = async (propertyName) => {
    //     let query = {
    //         label : propertyName
    //     }
    //     let propertyDetails =  await this.find({ query })
    //     if(propertyDetails.length===0){
    //         await this.create({"label" : propertyName, "isIncluded": true})
    //     }
    // }

    roundDecimal = (num) => Math.round((num + Number.EPSILON) * 100) / 100;
    calculateSaleRate = (mrp, percentage) =>{
        let saleRate = mrp - ((mrp/100) * percentage)
        return this.roundDecimal(saleRate);
    }
    expandOrderedItems = async (orderedItems) =>{
        let list = await api.Inventories.expandOrderedItems(orderedItems)
        let items = []
        _.map(orderedItems, item =>{
                let inventory = _.find(list, {_id:item.id})
                if(inventory.isDeleted){
                    item.isDeleted = true
                    items.push(item)
                }else{
                let companyName = _.find(inventory.properties, {propertyId : config.getConfig('shop').companyName})
                let quantity = _.find(inventory.properties, {propertyId : config.getConfig('shop').quantity})
                let data = {
                    ...item,
                    companyName : companyName ? companyName.value : ' ',
                    vendorProductId : inventory.vendorProductId,
                    productQuantity : quantity ? quantity.value : ' '
                }
                items.push(data)
            }
        })
        return items

    }
}

export default InventoryHelper
