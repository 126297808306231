import React from 'react';
import Entities from '@toolkit/entities';
import TextBanner from './message';
import _ from 'lodash';

class MultiImages extends React.PureComponent {
    constructor(props) {
        super(props)
    }
    renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
        return (
            <Layout name="multi-image-layout">
                {_.map(this.props.images, img => <Block name="story-image-block"><Bits.Image name="story-image" src={img}/></Block>)}
            </Layout>
            
        )
    }

    getStylesheet = (colorPallete) => {
        return {
            'multi-image-layout' : {
                master: {
                    overflowY: 'auto',
                    height: '100vh',
                    width: '100%'
                }
            },
            'story-image': {
                master: {
                    width: '100%',
                    height: '100%',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }
            }
        }
    }
}

export default Entities.createPlace(MultiImages, {
    name: "MultiImages",
  });
