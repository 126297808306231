import _ from 'lodash';
import React, {Component} from 'react';
import { withFormsy } from 'formsy-react';
import customFormHoc from './customFormHoc';
import {Text} from './textField'; 
import { CircularProgress} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Button from './button'
require('./styles.scss');

class ChipsInput extends Component {
    constructor(props){
        super(props);
        this.state = {
            value :'',
        }
        this.setValue(this.props.defaultValue);
    }

    addChip = values => {
        this.props.value.push(values);
        this.setValue(this.props.value)
    };

    removeChip = index => {
        const chips = _.cloneDeep(this.props.value)
        _.pullAt(chips , [index]);
        this.setValue(chips)
    };

    onChange = (value) =>{
        this.setState({ value : value })
    }

    setValue = value => {
        // if (this.props.type === 'number') {
        //     value = parseFloat(value)
        // }
        this.props.setValue(value)
    }

    onKeyDown = (event) => {
        if (event.keyCode === 13) {
            this.props.onEnter(this.state.value, event);
            this.props.onInteractionComplete(this.state.value, event);
        }
        this.props.onKeyDown();
    }

    onAddZipCode = () =>{
        this.addChip(this.state.value)
        this.setState({
            value : ''
        })
    }

    render(){
        return(
            <div>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <Text
                        onChange = {this.onChange}
                        fullWidth
                        label="Add Zipcode"
                        value ={this.state.value}
                    />
                    <Button
                        style={{ fontSize: 18, opacity: 0.8 , marginTop:15}}
                        className="big-btn"
                        fullWidth
                        variant="contained" 
                        color="primary"
                        onClick={this.onAddZipCode}
                        disabled ={this.state.value.length !== 6 && true}
                    >Add</Button>
                </div>
                <div className="margin-t-b-10">
                { _.map(this.props.value, (selectedOption, index) =>
                        <Chip
                            key={index}
                            onDelete={() => this.removeChip(index)}
                            className="margin-5"
                            // {...this.props.selectedOptionProps}
                            onChange={this.changeValue}
                            label={
                                <div className="flex-center">
                                    <span>{selectedOption}</span>
                                </div>
                            }/>)
                    }
                </div>
            </div>
        ) 
    }
}

ChipsInput.defaultProps = {
    onKeyDown: _.noop,
    onEnter: _.noop,
    onInteractionComplete: _.noop,
    onChange: _.noop,
};

const nonFormsyHOC = customFormHoc(ChipsInput);

export { nonFormsyHOC as ChipsInput };
export default withFormsy(ChipsInput);