import React from "react";
import _ from "lodash";
import Entities from "@toolkit/entities";
import Helper from "@core/helpers";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.shopHelper = new Helper.ShopHelper();
    this.helper = new Helper.UserSigninHelper();
    this.userHelper = new Helper.UserHelper();
    this.state = {
      showDailogFlag: false,
    };
  }

  getLabelValues = () => ({
    title: _.get(this.props.page, "title"),
    secondaryText: _.get(this.props.page, "secondaryText"),
  });

  renderSurface(
    values,
    { MainLayout, Layout, Block, Bits, View, Switch },
    config
  ) {
    return (
      <MainLayout name="main-layout">
        {(values.title || values.secondaryText) && (
          <Layout name="section-navigation">
            <Layout>
              <Layout name="header-layout">
                <Block>
                  <Bits.Text name="category-header">{values.title}</Bits.Text>
                  <Bits.Text name="caption">{values.secondaryText}</Bits.Text>
                </Block>
              </Layout>
              <Layout name="navigation-link">
                {this.props.previousPage &&
                  _.map(this.props.previousPage, (page) => {
                    return (
                      <Block name="previous-page">
                        <Bits.Text onClick={() => page.onClick()}>
                          {page.title}
                        </Bits.Text>
                        <Block>
                          <ArrowForwardIosIcon
                            fontSize="small"
                            style={{
                              fontSize: 14,
                              margin: "0px 5px",
                              paddingTop: 3,
                            }}
                          />
                        </Block>
                      </Block>
                    );
                  })}
                <Block name="active-page">
                  <Bits.Text>{this.props.activePage}</Bits.Text>
                </Block>
              </Layout>
            </Layout>
            <Block style={{ display: "flex" }}>
              {this.props.buttonIcons && (
                <Block style={{ marginRight: 5, marginTop: -16 }}>
                  {_.map(this.props.buttonIcons, (button) => {
                    return (
                      <Tooltip title={button.tooltipTitle}>
                        <IconButton
                          aria-label="delete"
                          onClick={button.onClickButton}
                          color="primary"
                        >
                          {button.icon}
                        </IconButton>
                      </Tooltip>
                    );
                  })}
                </Block>
              )}
              {this.props.actionBar && this.props.actionBar}
              {this.props.rightButton && (
                <Button
                  onClick={this.props.rightButton.onClick}
                  variant="contained"
                  color="primary"
                  startIcon={this.props.rightButton.startIcon}
                  style={{ height: 40, fontSize: 12 }}
                  disabled={this.props.rightButton.disabled}
                >
                  {this.props.rightButton.buttonLabel}
                </Button>
              )}
            </Block>
          </Layout>
        )}
        {this.props.children}
      </MainLayout>
    );
  }

  getStylesheet = (colorPallete, config) => {
    return {
      caption: {
        master: {
          fontSize: 16,
          color: "grey",
          margin: 0,
        },
        mobile: {
          fontSize: 14,
        },
      },
      "main-layout": {
        master: {
          maxWidth: 1250,
          margin: "0 auto",
          minHeight: "calc(100vh - 100px)",
          // backgroundColor: colorPallete.bg
        },
      },
      "section-navigation": {
        master: {
          padding: "30px 30px 20px",
          position: this.props.leftFixedTitle ? "fixed" : "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          transition: "children-slideRight",
          boxSizing: "border-box",
          // background: "#f1fff0",
          width: this.props.leftFixedTitle ? 300 : "100%",
          // marginTop: _.isNull(persistentStorage.get("selectedDeliveryZone")) ? 20 : 0
        },
        mobile: {
          position: "unset",
          width: "100%",
        },
      },
      "category-header": {
        master: {
          fontSize: 28,
          color: colorPallete.primary,
          fontWeight: 600,
          letterSpacing: 1,
          margin: 0,
        },
        mobile: {
          fontSize: 24,
          letterSpacing: 0.5,
          margin: 0,
          fontWeight: 600,
          position: _.get(this.props.page, 'stickyTitle') ? 'sticky' : 'unset'
        },
      },
      "navigation-link": {
        master: {
          display: "flex",
          alignItems: "center",
        },
        mobile: {
          display: "none",
        },
      },
      "previous-page": {
        master: {
          display: "flex",
          alignItems: "center",
          color: colorPallete.subtext,
          cursor: "pointer",
          left: 40,
          top: 40,
        },
        mobile: {
          left: 15,
          top: 34,
        },
      },
      "active-page": {
        master: {
          color: colorPallete.teritary,
        },
      },
    };
  };
}

export default Entities.createPlace(PageHeader, {
  name: "Header",
});
