import moment from 'moment';
import _ from 'lodash';
import { RRule, RRuleSet, rrulestr } from 'rrule'

const formatLabel = (date) => moment(date).format("MMM D, YYYY");

const getYear = (date) => moment(date).year();

const getMonth = (date) => moment(date).month();

const getDay = (date) => moment(date).date();

const getShortMonth = (date) => moment(date).format("MMM");

const getTodayDay = (date) => moment(date).day();

const formatMultiDates = ({ from, to }, seperator = '-') => {

    const getTemplate = (fromDate, toDate) => `${fromDate} ${seperator} ${toDate}`;
    // if years are same display as 27 May - 21 Jun 2018
    if (getYear(from) == getYear(to)) {
        // if months are same display as 27 - 21 Jun 2018
        if (getMonth(from) == getMonth(to)) {
            if (getDay(from) == getDay(to)) {
                return moment(from).format("D MMM, YYYY");
            }
            return getTemplate(moment(from).format("D"), moment(to).format("D MMM, YYYY"));
        }
        return getTemplate(moment(from).format("D MMM"), moment(to).format("D MMM, YYYY"));
    }
    return getTemplate(moment(from).format("D MMM, YYYY"), moment(to).format("D MMM, YYYY"));
}

const groupByDate = (list, dateKey) => {
    const groups = list.reduce((maps, item) => {
        const group = moment(item[dateKey]).calendar(new Date(), {
            sameDay: '[Today]',
            lastDay: '[Yesterday]',
            lastWeek: '[Last Week]',
            sameElse: 'DD/MM/YYYY'
        });
        if (!moment(group).isValid()) {
            maps[group] = maps[group]|| [];
            maps[group].push(item);
        } else {
            const label = moment(group, 'DD/MM/YYYY').format('MMMM YYYY');
            maps[label] =  maps[label] || [];
            maps[label].push(item)
        } return maps}, {});
    const a = _.toPairs(groups)
    return a;
}

const recur = (event) => {
    const startDate = event.end.dateTime;
    const allDate = RRule.parseString(event.recurrence[0])
    allDate.dtstart = new Date(startDate);
    const rule = new RRule(allDate)
    const length = rule.all().length
    return rule.all()[length - 1];
  }
    
export default {
    formatLabel,
    formatMultiDates,
    getShortMonth,
    getTodayDay,
    groupByDate,
    recur
};