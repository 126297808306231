import React from "react";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from './routes';
import tokenManager from '@utils/lib/tokenManager';
import persistentStorage from '@utils/lib/localStorage';
import cacheManager from '@utils/lib/cache';
import { Helmet } from "react-helmet";
import interiorDesigner from "@toolkit/lib/interiorDesigner";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import sentry from '@utils/lib/sentry';
import LogRocket from '@utils/lib/logRocket';
import { addCustomFormInput } from "@web-bricks/Shared/form/components/formMaker";
import MultiMedia from "@core/modules/shared/views/Places/multiMedia";

addCustomFormInput({
  type: 'Multimedia',
  Input: MultiMedia.formInput
})

const theme = createMuiTheme({
  palette: {
    primary: {
      main: interiorDesigner.colorPallete.primary
    },
    secondary: {
      main: interiorDesigner.colorPallete.teritary
    }
  },
});

class App extends React.Component {

  constructor(props) {
    super(props);
    sentry.wakeup();
    LogRocket.wakeup();
    tokenManager.setStorage(persistentStorage);
    tokenManager.wakeup();
  }

  componentDidMount = async () => {

    await Promise.all([
      // enableLinking(this.props.history.push),
      cacheManager.wakeup(),
    ]);
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Helmet>
            <script src="https://checkout.razorpay.com/v1/checkout.js"></script>
          </Helmet>
          <MainRoutes />
        </BrowserRouter>
      </ThemeProvider>
    );
  }
};

export default (App);