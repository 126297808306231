import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import _ from 'lodash'


export default function BNav(props) {
  return (
    <BottomNavigation
      classes={makeStyles(props.style)()}
      value={props.activeSection}
      onChange={(event, newValue) => {
        const tab = _.find(props.list, ({ name: newValue }));
        props.onChange(tab);
      }}
      showLabels={!props.hideLabels}
      name='bottom-nav'
    >
      {
        _.map(props.list, (item, index) => {
          return (
            <BottomNavigationAction
              key={index}
              classes={props.style && makeStyles(props.style.action)()}
              value={item.name}
              label={props.hideLabels ? null : item.label}
              icon={item.icon}
            />
          )
        })
      }
    </BottomNavigation>
  );
}
