import React from "react";
import endpoints from "@api";
import Entities from "@toolkit/entities";
import SliderComp from "@web-bricks/Shared/Image/slidercomp";
import _ from "lodash";
import config from "@config";

class CouponShowcase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    const shop = config.getConfig("shop");
    this.offers = _.compact([_.get(shop, "referrals")]);
  }

  async componentDidMount() {
    const coupons = await endpoints.Coupon.find({});
    const allOffers = _.concat(coupons, this.offers);
    this.setState({
      coupons: allOffers,
      couponImages: _.filter(allOffers, "featuredImage"),
    });
  }

  getLabelValues = (_) => {
    return {};
  };

  renderSurface(values, { MainLayout, Layout, Block, Bits }) {
    return _.size(this.state.couponImages) > 0 ? (
      <MainLayout name="outer-container">
        {/* <Block name="offer-block-header">
                    <Bits.Text name='offers-block-title' type="h2">
                        Offers & Discounts
                    </Bits.Text>
                </Block> */}
        <Layout name="inner-container">
          {/* <SliderComp> */}
          {_.map(this.state.couponImages, (coupon) => {
            return (
              <Block name="coupon-image-block">
                <Bits.Image name="show-case" src={coupon.featuredImage} />
              </Block>
            );
          })}
          {/* </SliderComp> */}
        </Layout>
      </MainLayout>
    ) : null;
  }

  getStylesheet = (colorPallete) => {
    return {
      "offers-block-title": {
        master: {
          minWidth: 300,
        },
      },
      "offer-block-header": {
        master: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
      },
      "block-title": {
        master: {
          color: colorPallete.primary,
          fontWeight: 600,
          fontSize: 30,
          letterSpacing: 1,
          fontFamily: "initial",
          margin: 0,
          opacity: 0.95,
          width: 320,
        },
      },
      "outer-container": {
        master: {
          width: "100%",
          height: "100%",
          borderRadius: 6,
          display: "flex",
        },
        mobile: {
          padding: "0px 10px 10px",
          boxSizing: "border-box",
          height: "100%",
          margin: "25px 0px",
          flexDirection: "column",
        },
        transition: "slideDown",
      },
      "inner-container": {
        master: {
          width: "100%",
          height: "100%",
        },
        mobile: {
          marginTop: 15,
        },
      },
      "coupon-image-block": {
        master: {
          width: "100%",
          height: "100%",
          paddingRight: 30,
        },
        mobile: {
          paddingRight: 20,
          minWidth: "calc(100vw - 20px)",
          paddingBottom: 15,
        },
      },
      "show-case": {
        master: {
          width: "100%",
          height: "240px",
          borderRadius: 5,
          boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
          objectFit: "cover",
        },
        mobile: {
          height: "100%",
          maxHeight: 140,
        },
      },
      "coupon-image": {
        master: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
        mobile: {
          objectFit: "cover",
        },
      },
    };
  };
}

export default Entities.createThing(CouponShowcase, {
  name: "CouponShowcase",
});
