import React from 'react';
import _ from 'lodash';
import Entities from '@toolkit/entities';
import Helper from '@core/helpers'
import InventoryTags from '@core/modules/Inventory/views/Things/inventoryTags';
import InventoryMedia from '@core/modules/Inventory/views/Pieces/inventoryMedia'
import WithCart from "@core/modules/Cart/views/lib/withCart";


class TaggedInventory extends React.Component {

    constructor(props) {
        super(props);
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits
    }) {
        const { inventory } = this.props;
        return (
            <MainLayout>
                <Layout name='secondary-info-block' onClick={this.expandView}>
                    <Block name='grid-block-image'>
                        {
                            !_.isEmpty(inventory.tags) &&
                            <Block name="tag-container" style={{ position: 'absolute', zIndex: 1, right: 0, top: 10 }}>
                                <InventoryTags tags={inventory.tags} />
                            </Block>
                        }
                        <InventoryMedia noLazy media={inventory.media} />
                    </Block>
                    <Block name="grid-blocks">
                        <Bits.Text name='grid-block-text'>{_.capitalize(inventory.label)}</Bits.Text>
                        <Layout name="price-counter-block">
                            <Bits.Currency
                                name="grid-block-price"
                                value={`${_.capitalize(inventory.rates.saleRate)}`}
                            />
                            {this.renderQuickAdd(false)}
                        </Layout>
                    </Block>
                </Layout>
                {this.renderExpandView()}
            </MainLayout>
        )
    }

    getStylesheet = (colorPallete) => ({
        'price-counter-block': {
            master: {
                display: 'flex',
                justifyContent: 'space-between'
            }
        },
        'inventories-block': {
            master: {
                justifyContent: 'flex-start',
                whiteSpace: 'nowrap',
                margin: '20px 0px',
                display: 'flex',
                alignItems: 'center',
                overflow: 'auto',
                paddingBottom: 10,
            },
            mobile: {
                overflow: 'auto',
                padding: '0px 20px 20px',
                marginTop: 0,
                marginBottom: 40,
                marginLeft: 5
            }
        },
        'taged-inventory-block': {
            master: {
                margin: '20px 0px 30px 0px'
            },
            mobile: {
                marginLeft: 5
            }
        },
        'primary-block-title': {
            master: {
                color: colorPallete.primary,
                fontWeight: 600,
                fontSize: 30,
                letterSpacing: 1,
                fontFamily: 'initial',
                margin: '50px 0px 0px 0px',
                opacity: 0.95
            },
            mobile: {
                margin: '10px 20px',
                fontSize: 24,
            },
            transition: 'slideDown'
        },
        'secondary-info-block': {
            master: {
                marginRight: 40,
                paddingBottom: 20,
                display: 'inline-block'
                // display: 'flex',
                // flexDirection: 'column',
                // alignItems: 'center',
                // width: '100%',
                // boxSizing: 'border-box',
                // margin: '10px 0px'
            },
            mobile: {
                marginRight: 20,
                width: 150
            }
        },
        'grid-block-image': {
            master: {
                position: 'relative',
                width: 150,
                height: 150,
                background: '#f9f1e9',
                padding: '50px 30px',
                borderRadius: 4,
                cursor: 'pointer'
            },
            mobile: {
                width: 100,
                height: 100,
                padding: '40px 25px',
            }
        },
        'grid-block-text': {
            master: {
                textTransform: 'capitalize',
                textAlign: 'left',
                fontSize: 16,
                fontWeight: 600,
                color: colorPallete.black,
                maxWidth: 210,
                margin: '7px 0px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
            mobile: {
                fontSize: 14
            }
        },
        'grid-block-price': {
            master: {
                margin: 0,
                fontSize: 13,
                color: colorPallete.Black,
            }
        },
    })
}


TaggedInventory = Entities.createThing(WithCart(TaggedInventory), {
    name: 'TaggedInventory'
})
class TaggedInventories extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.tagsHelper = new Helper.TagsHelper()
    }

    async getData() {
        const inventories = await this.tagsHelper.getAllTagsWithInventories(this.props.query)
        this.setState({
            inventories: _.slice(inventories, 0, 6)
        })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({

        })
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }) {
        return (
            <MainLayout name="taged-inventory-main-block">
                <Layout>
                    {
                        !_.isEmpty(this.state.inventories) &&
                        <Layout name='taged-inventory-block'>
                            <Block name="block-header">
                                <Bits.Text name='block-title'>{this.props.title}</Bits.Text>
                            </Block>
                            <Block name='inventories-block'>
                                {
                                    _.map(this.state.inventories, (inventory, index) => {
                                        return (
                                            <TaggedInventory
                                                key={index}
                                                inventory={inventory}
                                                {...Helper.CartHelper.makeProps([inventory])}
                                            />
                                        )
                                    })
                                }
                            </Block>
                        </Layout>
                    }
                </Layout>
            </MainLayout>
        )
    }
    getStylesheet = (colorPallete) => {
        return ({
            'inventories-block': {
                master: {
                    justifyContent: 'flex-start',
                    whiteSpace: 'nowrap',
                    margin: '20px 0px',
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'auto',
                    paddingBottom: 10,
                },
                mobile: {
                    overflow: 'auto',
                    padding: '0px 20px 20px',
                    marginTop: 0,
                    marginBottom: 40,
                    marginLeft: 5
                }
            },
            'taged-inventory-block': {
                master: {
                    margin: '20px 0px 30px 0px'
                },
                mobile: {
                    marginLeft: 5
                }
            },
            'primary-block-title': {
                master: {
                    color: colorPallete.primary,
                    fontWeight: 600,
                    fontSize: 30,
                    letterSpacing: 1,
                    fontFamily: 'initial',
                    margin: '50px 0px 0px 0px',
                    opacity: 0.95
                },
                mobile: {
                    margin: '10px 20px',
                    fontSize: 24,
                },
                transition: 'slideDown'
            },
            'secondary-info-block': {
                master: {
                    marginRight: 40,
                    paddingBottom: 20,
                    display: 'inline-block'
                    // display: 'flex',
                    // flexDirection: 'column',
                    // alignItems: 'center',
                    // width: '100%',
                    // boxSizing: 'border-box',
                    // margin: '10px 0px'
                },
                mobile: {
                    marginRight: 20,
                    width: 150
                }
            },
            'grid-block-image': {
                master: {
                    position: 'relative',
                    width: 150,
                    height: 150,
                    background: '#f9f1e9',
                    padding: '50px 30px',
                    borderRadius: 4,
                    cursor: 'pointer'
                },
                mobile: {
                    width: 100,
                    height: 100,
                    padding: '40px 25px',
                }
            },
            'grid-block-text': {
                master: {
                    textTransform: 'capitalize',
                    textAlign: 'left',
                    fontSize: 16,
                    fontWeight: 600,
                    color: colorPallete.black,
                    maxWidth: 210,
                    margin: '7px 0px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                },
                mobile: {
                    fontSize: 14
                }
            },
            'grid-block-price': {
                master: {
                    margin: 0,
                    fontSize: 13,
                    color: colorPallete.Black,
                }
            },
        })
    }
}

export default Entities.createPlace(TaggedInventories, {
    name: 'TaggedInventories',
    hasSkeleton: true
});