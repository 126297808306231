import api from '@api';
import _ from 'lodash';
import tokenManager from '@utils/lib/tokenManager';
import GenerateNumber from '@utils/lib/generateNumber';
import { currency } from '@utils/lib/utils';
import config from '@config/index'

class OrdersHelper {

    constructor() {
        this.razorpayCreds = config.getConfig('razorpay')
    }

    placeOrder = async (cart, transactionId) => {

        const generateNumber = new GenerateNumber()
        var number = generateNumber.generate()
        const orderItems = []
        const timeline = []

        _.map(cart.cartItems , item =>{
            var name = item.cartItem.name
            var price = item.cartItem.rates.saleRate
            var quantity = item.count
            var id = item.cartItem._id
            var totalAmount = item.totalPrice
            var image = item.cartItem.media && _.get(item.cartItem, "media.0.url")
            var ordered_item = {
                name,
                price,
                quantity,
                id,
                totalAmount,
                image,
                discount: _.get(_.find(cart.discountableItems, di => di.inventoryId === item.cartItem._id), 'discount')
            }
            orderItems.push(ordered_item)
        })

        timeline.push({
            status:'ORDERED',
            time : Date().toLocaleString()
        })
        const orderedList = {
            orderedItems : orderItems,
            bill:{
                totalAmount: currency.convertToDecimal(cart.totalAmount),
                deliveryCharge: currency.convertToDecimal(cart.deliveryCharge),
                billedAmount: currency.convertToDecimal(cart.billableAmount),
                discount: currency.convertToDecimal(cart.discount),
                couponedDiscount: currency.convertToDecimal(cart.couponedDiscount),
                couponId: cart.couponId,
                sumAmount : cart.sumAmount
            },
            orderedBy : {
                name : tokenManager.name,
                id : tokenManager.user,
                phone : _.toString(tokenManager.phone)
            },
            transactionId : transactionId,
            deliveringAdderess : cart.locationTodeliver,
            orderedAt : Date().toLocaleString(),
            status: 'ORDERED',
            orderSummary : `ordered succesfully`,
            orderNumber: parseInt(number),
            orderType: cart.orderType,
            timeline:timeline,
            paymentMode : cart.paymentMode
        }
        let order = await api.Orders.create(orderedList)
        return order
    }

    cancelOrder = async (order) =>{
        if(order.paymentMode === 'RP')
            await api.Transactions.initializeRefund(order.transactionId, this.razorpayCreds.key_id, this.razorpayCreds.key_secret)
        await api.Orders.update(order._id, {status : "CANCELLED", isCancelled : true, cancelledAt: new Date()})
    }

    getAllOrders = async () =>{
        let query ={}
        const orders = await api.Orders.find(query);
        return orders
    }

    getById = async (_id) =>{
        const order = await api.Orders.getById(_id);
        return order
    }

    getBilledAmount = (order) => {
        return order.bill.billedAmount || order.bill.totalAmount;
    }

    hasDiscount = (order) => {
        return !!order.bill.discount;
    }

    getDiscount = order => order.bill.discount;

    sortBydate = async () =>{
        const userOrders = await this.getUserOrders()
        const sortedList = _.sortBy(userOrders, 'orderedAt')
        const getLastTwoOrders = _.reverse(_.slice(sortedList, sortedList.length-2, sortedList.length))
        return getLastTwoOrders
    }

    getUserOrders = async () =>{
        const AllOrders = await this.getAllOrders()
        let userOrders =[]
        _.map(AllOrders, order =>{
            if(order.orderedBy.id === tokenManager.user){
                userOrders.push(order)
            }
        })
        return userOrders
    }

    getByDates = (dates) => {
        return api.Orders.getByDates(dates)
    }
}

export default OrdersHelper
