import React from "react";
import _ from 'lodash';
import Entities from '@toolkit/entities';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class PrivacyPolicy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    async getData() {
        this.setState({

        })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({
        })
    }


    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }, config) {

        const shopConfig = config['shop'];
        const shopName= shopConfig.name;
        return (
            <MainLayout name="mainlayout">
                <View landing>
                    <Layout>
                        <Block name='top-bar'>
                            <ArrowBackIosIcon onClick={() => this.props.history.goBack()}/>
                            <Bits.Text name='top-bar-title'>Privacy Policy</Bits.Text>
                        </Block> 
                    </Layout>
                    <Layout>
                        <Block>
                            <Bits.Text name='content-header'>Our Privacy Policy</Bits.Text>
                            <Bits.Text name='content'>This Privacy Policy describes which personal information we process, and how we collect, store, protect, use, and disclose your information with regards to your access and use of the Smartshop Platform or our products designed for partners. You can also read for which purposes and on which legal grounds we use your personal data and how you can exercise  your privacy rights.</Bits.Text>
                            <Bits.Text>In this Privacy Policy, your “Personal Data” means information or pieces of information that could allow you to be identified. All other terms used in this Privacy Policy (e.g. “Smartshop Platform”) are defined in the same was as in our Terms and Conditions.</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='content-header'>Applicability</Bits.Text>
                            <Bits.Text name='block-content'>This Privacy Policy applies to all your use of the smartshop, digital presence, branding services, and all the services that we offer you via this “App” and this “Website”.</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='content-header'>Grounds for Processing Your Personal Data</Bits.Text>
                            <Bits.Text name='block-content'>We process your personal data first and foremost so that we can carry out our contractual commitments towards you.</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='content-header'>Information Usage</Bits.Text>
                            <Bits.Text name='block-content'>We use, store, and process information, including personal information for the following reasons:<br/>
                            1. Track your enquiries made on this Website<br/>
                            2. Provide customer service and support<br/>
                            3. Send you messages, alerts, notifications, and messages, including promotional messages, marketing, advertising, and other information that may be relevant.<br/>
                            4. Detect and prevent harmful and unwanted activity such as spam, abuse, and fraud<br/>
                            5. Verify or authenticate information or identifications provided by Users.<br/>
                            6. Conduct background checks.<br/>
                            7. Enforce our Terms and Conditions and other policies.<br/>
                            8. Conduct promotional events such as referral programs, rewards, surveys, contents, and more.<br/>
                            You may opt-out of receiving communications from us by contacting us. We use, store, and process your information to comply with all applicable laws.</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='content-header'>Sharing Your Information</Bits.Text>
                            <Bits.Text name='block-content'>1. We may share your information, including personal information only after taking appropriate consent from you.<br/>
                            2. We will not share your billing and checkout information with other Users.</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='content-header'>Third Party Service Providers</Bits.Text>
                            <Bits.Text name='block-content'>{shopName} may use third party service providers to help us provide features related to the our services. These providers may help us verify your identity, conduct background checks, provide customer service or advertising and more. These providers will have limited access to your personal information to perform these tasks, and are contractually bound to protect and to use it only for the purposes for which it was disclosed.</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='content-header'>Business Activities.</Bits.Text>
                            <Bits.Text name='block-content'>If {shopName} is involved in any merger, acquisition, reorganization, sale of assets, or bankruptcy, we may sell, transfer or share some or all of our assets, including your information, for the completion of the transaction. In this case, we will notify you before your personal information is transferred and becomes subject to a different privacy policy.</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='content-header'>Do Not Track signals</Bits.Text>
                            <Bits.Text name='block-content'>We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='content-header'>Third-party behavioral tracking</Bits.Text>
                            <Bits.Text name='block-content'>We allow third-party behavioral tracking.</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='content-header'>Cookies</Bits.Text>
                            <Bits.Text name='block-content'>We may use cookies to enhance your experience when using our Website to improve our Smartshop Platform and deliver a more personalized experience. This can include remembering your login details, speeding up your searches, and recognizing you when you return to our Website.<br/>

                            A cookie is a small text file, containing a string of characters that can be placed on your device when you visit a website. This text file uniquely identifies your browser or device. When you visit our Website again, the cookie allows our Website to recognize your browser or device. You can change your cookie settings in your browser, if you don’t want cookies to be sent to your device. Please note that some features or services of our Website may not function properly without cookies. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you log on to our Website.<br/>
                            
                            We use the following types of cookies on our Website:<br/>
                            
                            1. Technical cookies: these are cookies that are essential for the operation of our Website, they enable you to move around our Website and use our features.<br/>
                            2. Analytical/statistical cookies: we use these cookies to track visitor statistics. We use these statistics to continuously improve our Website and mailings, and thus offer you relevant content. These cookies also allow us to recognize and count the number of visitors and to see how visitors navigate when they are using our Website. This helps us to improve user navigation and ensure users to find what they need more easily.<br/>
                            3. Tracking cookies: these cookies monitor clicking behaviour and surfing habits. By means of these cookies we can inter alia see whether and when you view Your Account, and whether you click through to our Website.</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='content-header'>Data Retention</Bits.Text>
                            <Bits.Text name='block-content'>How long do we retain your Personal Data?<br/>

                            We retain your Personal Data no longer than strictly necessary or as long as allowed by law -- whichever is shorter. At any time, you are welcome to contact us with a request to delete you from our records.</Bits.Text>
                            <Bits.Text>Rights<br/>

                            You have the right to:<br/>
                            
                            1. Request access to your Personal Data<br/>
                            2. Request us to correct, update, or erase your Personal Data in our records. If you want your account to be permanently deleted, please contact us.<br/>
                            3. Request us to restrict any processing or object to any processing of your Personal Data.<br/>
                            If you have any questions, comments, or concerns about how we your Personal Data, please contact us through the {shopName} website.</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='content-header'>Withdrawing Consent</Bits.Text>
                            <Bits.Text name='block-content'>Wherever you have provided {shopName} with consent to process your personal information, you may withdraw your consent at any time by contacting us.  

                            </Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='content-header'>Payments</Bits.Text>
                            <Bits.Text name='block-content'>{shopName} will not share financial information we collect about you with third parties (both financial and non-financial), except as required or permitted by law.</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='content-header'>Security</Bits.Text>
                            <Bits.Text name='block-content'>In order to protect your information, we may use firewalls, encryption, and other security measures. If you believe your Account information has been stolen, misappropriated, or compromised, you may notify us by contacting us.</Bits.Text>
                        </Block>
                        <Block>
                            <Bits.Text name='block-content-last'>Changes to Our Privacy Policy: <br/>
                            {shopName} reserves the right to modify this Privacy Policy at any time. In this case, we will post the updated Privacy Policy on our Website, and indicate when our Privacy Policy was last changed.<br/>
                            If you have any questions, complaints, or comments about our Privacy Policy, you may contact us.</Bits.Text>
                        </Block>
                    </Layout>
                </View>
            </MainLayout>
        )
    }

    getStylesheet = (colorPallete) => {
        return ({
            'block-content-last':{
                master:{
                    margin:'10px 0px',
                    fontSize: 15,
                    textAlign:'justify',
                    fontWeight:'bold',
                    padding:'30px'
                }
            },
            'block-content':{
                master:{
                    margin:0,
                    fontSize: 15,
                    textAlign:'justify'

                }
            },
            'content-header':{
                master:{
                    color:colorPallete.primary,
                    fontSize:20,
                    margin:'20px 0px 10px',
                }
            },
            'top-bar':{
                master:{
                    display:'flex',
                    marginBottom:30
                }
            },
            'mainlayout':{
                master:{
                    maxWidth:900,
                    margin:'auto',
                    margin:'20px auto',
                    padding: '0px 20px',
                    // margin:'30px 30px'
                }
            },
            'top-bar-title':{
                master:{
                    margin:'0px 10px',
                    fontSize:22,
                }
            },
        })
    }
}

export default Entities.createPlace(PrivacyPolicy, {
    name: 'PrivacyPolicy'
});

