import React, { Component } from "react";
// import ReactImageMagnify from "react-image-magnify";
import Magnifier from "react-magnifier";
import helpers from '@core/helpers';
import './styles.css';
import _ from 'lodash';
import Entities from "@toolkit/entities";

class ImageMagnifier extends React.Component {
  constructor(props) {
    super(props);
  }

  getLabelValues = (formatter, _, transformers) => {
    return {
      url : helpers.MediaHelper.getOriginalFileURL(this.props.url)
    };
  };
    
  renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
    return (
      <MainLayout>
        <Layout name="fluid">
          <Block name="fluid__image-container">
            <Magnifier
                src= {values.url}
                  // width= {1200}
                  // height= {1800}
            />
          </Block>
        </Layout>
      </MainLayout>
    );
  }

  getStylesheet = (colorPallete) => {
    return {
      "fluid": {
        master:{
          maxWidth: '1200px',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'row',
          fontFamily: 'Arial',
          lineHeight: 1.3,
          fontSize: 16,
          width: '100%',
          height: '100%'
        },
        mobile:{
          flexDirection: 'column'
        }
      },
      'fluid__image-container':{
        width: '100%',
        height: '100%',
        flex: 'unset',
        margin: '20px 0 20px 20px'
      }
    }
  }
}
export default Entities.createThing(ImageMagnifier, {
  name: "ImageMagnifier",
});