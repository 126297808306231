import React from 'react';
import _ from 'lodash';
// import interiorDesigner from '@toolkit/lib/interiorDesigner';
import Entities from '@toolkit/entities';
// import Form from "@web-bricks/Shared/form/comp/form";
// import FormMaker from '@web-bricks/Shared/form/components/formMaker';onents
import Container from "@core/modules/shared/views/Things/container.js";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@web-bricks/Shared/Input/button';
import Helper from '@core/helpers';
import moment from 'moment';
import DataTable from "@web-bricks/Shared/table/dataGrid";
// import LoopIcon from '@material-ui/icons/Loop';
class CustomerEngagement extends React.Component {
    constructor(props) { 
        super(props)
        this.state={
            //users: users
        }
        this.draftsHelper = new Helper.DraftsHelper()
    }

    async getData() { 
        const drafts = await this.getAllDrafts()
        this.setState({
            drafts: drafts,
        })
    };

    getAllDrafts = async () =>{
        const drafts = await this.draftsHelper.getAllDrafts()
        return drafts
    }

    getDraftList = () => {
        const columns = _.concat(
            [
                {
                    field: 'templateName',
                    headerName: 'Template',
                    type: "string",
                    width:200
                },
                {
                    field: 'type',
                    headerName: 'Type',
                    type: "string",
                    width:150
                },
                {
                    field: 'scheduledAt',
                    headerName: 'Scheduled At',
                    type: "date",
                    width:200
                },
                {
                    field: 'recipients',
                    headerName: 'Recipients',
                    type: "string",
                    width:200
                },
                {
                    field: 'action',
                    headerName: 'Action',
                    type: "button",
                    width:200,
                    renderCell: (cellValue) => {
                        return (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={(event) => {
                                this.onResend(cellValue);
                            }}
                            //disabled = {this.getDisabledStatus()}
                        >
                            re-send
                        </Button>
                        );
                    }
                      
                }
            ],
        );
        return columns
    };

 
   async onSubmit (values, value)  {
        this.setState({
            value: value   
        })   
    }

    onResend = async (cellValue) =>{
        await this.draftsHelper.resendDraft(cellValue.row.id)
        const drafts = await this.getAllDrafts()
        this.setState({
            drafts: drafts,
        })
    }

    getDisabledStatus = (draft) =>{
        var now = moment()
        let status = moment(draft.scheduledAt).isBefore(now);
        return status
    }

    getRows = (drafts) => {
        const rows = _.map((drafts), (draft) => ({
            id: draft._id,
            type: draft.template.type,
            templateName : draft.template.subject,
            scheduledAt : draft.scheduledAt ? moment(draft.scheduledAt).format("hh:mm a, D MMM YYYY") : 'recurrence',
            recipients : !_.isNil(draft.recipient.include) ? `Includes ${draft.recipient.include.ids.length}` : `Excludes ${draft.recipient.exclude.ids.length}`,
            
        }))
        return rows
    }

    toggleModel = () => {
        this.setState({
            open: !this.state.open
        })
    }
    
    toggleScheduleModel = () => {
        this.setState({
            scheduleOpen: !this.state.scheduleOpen
        })
    }


    
    getLabelValues = (formatter, _, transformers) => {
        return ({

        })
    }


    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        View,
        Bits,
        Switch
    }) {
        return (
            <MainLayout name="main-layout">
                <Container
                    page={{ 
                        title: 'Customer Engagement',
                        secondaryText: 'Send Notifications To End Users'
                     }}
                    previousPage={[{title:'Dashboard', onClick: () => this.props.history.push('/vendor/dashboard')}]}
                    activePage='Drafts'
                    rightButton={ this.state.drafts.length > 0 && { buttonLabel: 'Create Draft', onClick: () => this.goToView('/vendor/draft/create'), startIcon:<AddCircleIcon /> }}
                >
                <View>
                    {
                        this.state.drafts.length > 0 ?
                        <Layout>
                        <Block>
                            <DataTable
                                //onSelectionModelChange={this.onSelectedInventory}
                                //onRowClick={this.onRowClick}
                                columns={this.getDraftList()}
                                rows={this.getRows(this.state.drafts)}
                                checkboxSelection= {false}
                                handleSelectionModelChange= {this.onSelectionModelChange}
                            />
                        </Block>
                    </Layout> :
                    <Layout>
                        <Block>
                            <Bits.Text name='info-text'>Create a new draft to get started</Bits.Text>
                            <Block name='center-block'>
                                <Button onClick={() => this.goToView('/vendor/draft/create')} variant ='contained' color='primary'>create draft</Button>
                            </Block>
                        </Block>
                    </Layout>

                    }
                    
                </View> 
                </Container> 
            </MainLayout>
        )
    }

    getStylesheet = (colorPallete) => {
        return({
            'info-text':{
                master:{
                    color:colorPallete.subtext,
                    //fontWeight: 400,
                    fontSize:18,
                    display: 'flex',
                    justifyContent:'center'
                }
            },
            'center-block':{
                master:{
                    display: 'flex',
                    justifyContent:'center'
                }
            },
            'add-template':{
                master:{
                    display:'flex',
                    justifyContent:'flex-end'
                }
            },
            'template-button': {
                master:{
                    padding:'30px 0px'
                }
            },
            'main-layout': {
                master: {
                    maxWidth: 'unset',
                    margin: 'unset',
                    minHeight: 'unset'
                }
            },
        })
    }
    
}


export default Entities.createPlace(CustomerEngagement, {
    name: 'CustomerEngagement'
})