import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const classes = useStyles();
  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.handleClose} TransitionComponent={Transition} {...props}>
        <AppBar className={classes.appBar} {...props}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.title}
            </Typography>
            <div style={{marginRight:10, display:'flex'}}>
              { props.RightSecondaryButtons  && props.RightSecondaryButtons}
              {
                props.rightPrimaryButtons &&
                _.map(props.rightPrimaryButtons, button =>{
                  return(
                    <Button variant="contained" color="primary" onClick={button.onClick}>
                      {button.label}
                    </Button>
                  )
                })
              }
            </div>
          </Toolbar>
        </AppBar>
        <div>
          {
              props.getRenderItems()
          }
        </div>
      </Dialog>
    </div>
  );
}