import React from 'react'
import _ from 'lodash';
import Entities from '@toolkit/entities';
import moment from 'moment';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import config from '@config';
import helpers from '@core/helpers';

class OrderListView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            order: this.props.order
        }

        this.helper = new helpers.OrderHelper()
    }

    async getData() {
        this.setState({

        })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({
            order: this.state.order
        })
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch,
        interiorDesigner
    }) {
        return (
            <MainLayout name='main-layout'>
                <View>
                    <Layout>
                        <Block name="margin-t-b-5">
                            <Bits.Text name='detail-header'>Order Id</Bits.Text>
                            <Bits.Text name='detail-text'>{values.order.orderNumber}</Bits.Text>
                        </Block>
                        <Block name='ordered-items'>
                            <Bits.Text type='p' name='detail-header'>Items</Bits.Text>
                            {
                                _.map(values.order.orderedItems, item => {
                                    return (
                                        <Block name="order-item">
                                            <Bits.Text name='detail-text' >
                                                {`${item.name}  ✕  ${item.quantity}`}
                                            </Bits.Text>
                                        </Block>
                                    )
                                })
                            }
                        </Block>
                        <Block name="margin-t-b-5">
                            <Bits.Text name='detail-header'>Total amount</Bits.Text>
                            <Bits.Currency name='detail-text'>{this.helper.getBilledAmount(values.order)}</Bits.Currency>
                        </Block>
                        <Block name="margin-t-b-5">
                            <Bits.Text name='detail-header'>Ordered at</Bits.Text>
                            <Bits.Text name='detail-text'>{moment(values.order.orderedAt).format("D MMM YYYY")} | {moment(values.order.orderedAt).format("h:mm a")}</Bits.Text>
                        </Block>
                        <Block name='more-details'>
                            <Bits.Text name='details'>Details</Bits.Text>
                            <ArrowForwardIosIcon style={{ fontSize: 12, paddingTop: 5, paddingLeft: 4, color: interiorDesigner.colorPallete.backLinkTextColor, paddingLeft: 5 }} />
                        </Block>
                    </Layout>
                </View>
            </MainLayout>
        )
    }

    getStylesheet = (colorPallete) => {
        return ({
            'margin-t-b-5': {
                master: {
                    margin: '13px 0px'
                }
            },
            'detail-header': {
                master: {
                    color: 'grey',
                    fontSize: 10,
                    margin: '3px 0px',
                    textTransform: 'uppercase',
                }
            },
            'order-item': {
                master: {
                    display: 'flex',
                    // justifyContent: 'space-between',
                    alignItems: 'center'
                }
            },
            'detail-text': {
                master: {
                    margin: '0px 0px',
                    fontSize: 16,
                    fontWeight: 400,
                }
            },
            'detail-count': {
                master: {
                    margin: '0px 0px',
                    fontSize: 16
                }
            },
            'more-details': {
                master: {
                    display: 'flex',
                    cursor: "pointer",
                }
            },
            'details': {
                master: {
                    color: colorPallete.backLinkTextColor,
                    fontSize: 16,
                    margin: 0,
                    fontWeight: 600
                }
            }
        })
    }
}

export default Entities.createPlace(OrderListView, {
    name: 'OrderListView'
});