import _ from 'lodash';
import api from '@api';

class CategoryHelper {

    constructor() {
        
    }

    getCategoryList = async (query = {}) =>{
        const categories = await api.Categories.find({ query })
        let list = []
        _.forEach(categories, category =>{
            let data = {
                label : _.capitalize(category.name).split('_').join(' '),
                value: category._id
            }
            list.push(data)
        })
        return list
    }

    getVisibleCategoryList = async () => {
        return await this.getCategoryList({ isIncluded: true })
    }

    getById = async (id) =>{
        const category = await api.Categories.getById(id)
        return category;
    }

    getDetails = async (categoryId) =>{
        const category = await api.Categories.getDetails(categoryId)
        return category;
    }

    getCategories = async () =>{
        let categories = await api.Categories.find({})
        categories = _.filter(categories, { "isIncluded": true })
        categories = _.sortBy(categories, 'sortRank')
        return categories;
   }

   getCategoriesList = async () =>{
    let categories = await api.Categories.find({})
    // categories = _.filter(categories, { "isIncluded": true })
    categories = _.sortBy(categories, 'sortRank')
    return categories;
}

    editCategory = async (values, id) =>{
        let data ={
            name : _.toUpper(_.trim(values.name)).split(' ').join('_'),
            isIncluded : values.isIncluded,
            image : values.image,
            description : !_.isNil(values.description) ? values.description : ''
        }
        await api.Categories.update(id, data)
    }

    addCategory = async  (values) =>{
        let data ={
            name : _.toUpper(_.trim(values.name)).split(' ').join('_'),
            isIncluded : values.isIncluded,
            image : values.image,
            description : !_.isNil(values.description) ? values.description : ''
        }
        await api.Categories.create(data)
    }

    updateIsDeleted = async (id) =>{
        let data ={
            isDeleted : true
        }
        await api.Categories.update(id, data)
    } 
        
}

export default CategoryHelper
