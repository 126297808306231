import React from "react";
import _ from 'lodash';
import Entities from '@toolkit/entities';
import interiorDesigner from '@toolkit/lib/interiorDesigner';
import Form from '@web-bricks/Shared/form/components/form';
import endpoints from "@api";
import config from '@config/index'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    async getData() {
        this.setState({

        })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({
            
        })
    }

    onSubmit = async (values) =>{
        console.log(values)
        let message = {
            to: config.getConfig('shop').contact.email, // Change to your recipient
            from: 'info@ebrdesignstudio.in', // Change to your verified sender
            subject: 'Customer query from website',
            text: `${values.name}, phone - ${values.phone}, email id - ${values.email} sent the message ${values.message}`
            //html: '<strong>and easy to do anywhere, even with Node.js</strong>'
        }
        await endpoints.Services.sendMail(message)
        values.onSuccess();
    }


    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }, config) {
        const shopConfig = config['shop'];
        return (
            <MainLayout name="mainlayout">
                <View landing>
                    <Layout name='contact-us-layout'>
                    <Block name='contact-us-container'>
                        
                            <Block name='contact-us-form'>
                                <Bits.Text name ='form-heading'>Reach us for any query</Bits.Text>
                                <Form submitButton={{ label: 'send', style: { backgroundColor: interiorDesigner.colorPallete.primary, color: interiorDesigner.colorPallete.bg }  }} noValidate onSubmit={this.onSubmit} 
                                    formMaker={{
                                        list: [{
                                            type: 'textField',
                                            props: {
                                                name: 'name',
                                                label: 'Name',
                                                required: true,
                                            }
                                        },
                                        {
                                            type: 'textField',
                                            props: {
                                                name: 'email',
                                                label: 'Email',
                                                required: true,
                                            }
                                        },
                                        {
                                            type: 'textField',
                                            props: {
                                                name: 'phone',
                                                label: 'Phone Number',
                                                required: true,
                                            }
                                        },{
                                            type: 'textArea',
                                            title: "Your message",
                                            props: {
                                                name: 'message',
                                                label: 'Your Message',
                                                required: true,
                                                placeHolder:'type your message...',
                                                rows:"5"
                                            }
                                        }]
                                    }}
                                />
                            </Block>
                        <Block>                    
                            <Block name='contact-us-details'>
                                <Block>
                                {
                                    !_.isEmpty(shopConfig.address) &&
                                    <Block name='delivery'>
                                        <Bits.Text name='common-style-heading'>{ shopConfig.physicalStore != false ? "Shop Address" :"Registered Address"}</Bits.Text>
                                        <Block name='shop-address-details'>
                                        <Block>
                                            {
                                                _.map(shopConfig.address,address => {
                                                    return (
                                                        <Bits.Text name="common-style-para">{address}</Bits.Text>
                                                        )
                                                    })
                                            }
                                        </Block>
                                        {
                                            shopConfig.googleMapLink &&
                                            <a style={{
                                                backgroundColor: 'white',
                                                color: interiorDesigner.colorPallete.primary,
                                                border: `2px ${interiorDesigner.colorPallete.primary}`,
                                                //padding: '10px 20px',
                                                textAlign: 'center',
                                                textDecoration: 'none',
                                                height: 'fit-content',
                                                display: 'inline-block',
                                            }}target="_blank" href={shopConfig.googleMapLink}>
                                                <Bits.Text name='open-maps'>Open maps</Bits.Text>
                                            </a>
                                        }
                                        </Block>
                                    </Block>
                                    }
                                    
                                    <Block name='contact-details'>
                                        <Bits.Text name='common-style-heading'>Contact Details</Bits.Text>
                                        <Bits.Text name='basic-shop-info'>{shopConfig.contact.name}</Bits.Text>
                                        <Bits.Text name='basic-shop-info'>{shopConfig.contact.phone}</Bits.Text>
                                        <Bits.Text name='basic-shop-info'>{shopConfig.contact.email}</Bits.Text>
                                    </Block>
                                    {
                                        !_.isEmpty(shopConfig.businessHours) && 
                                        <Block name='contact-details'>
                                        <Bits.Text name='common-style-heading'>Business Hours</Bits.Text>
                                        <Bits.Text name='shop-address'>
                                            {shopConfig.businessHours}<br />
                                        </Bits.Text>
                                    </Block>
                                    }
                                     {
                                        !_.isEmpty(shopConfig.socialmedialinks) &&
                                            <Block name="follow-us">
                                                <Bits.Text name="common-style-heading">Follow us</Bits.Text>
                                                <Block name="social-media-flex">
                                                {Object.keys(shopConfig.socialmedialinks).map((key) => {
                                                    console.log(key)
                                                    return (
                                                    <Block name="social-media-align">
                                                        <Bits.IconButton href={shopConfig.socialmedialinks[key]} >
                                                            <Bits.Icon>{key}</Bits.Icon>
                                                        </Bits.IconButton>
                                                    </Block>
                                                    );
                                                })}
                                                </Block>
                                            </Block>
                                    }
                                </Block>
                            </Block>
                            </Block>
                        </Block>
                    </Layout>
                </View>
            </MainLayout>
        )
    }

    getStylesheet = (colorPallete, config) => {
        return ({
            "contact-us-container":{
                master:{
                    display:'flex',
                    justifyContent:'space-evenly'
                },
                mobile:{
                    flexDirection :'column',
                    //margin:20
                }
            },
            "social-media-flex": {
                master: {
                  display: "flex",
                  alignItems: "center",
                  height: 48,
                },
              },
            'contact-us-details':{
                master:{
                    display:'flex',
                    justifyContent:'center'
                }
            },
            'contact-us-form':{
                master:{
                    margin:'0px 0px',
                    background:'antiquewhite',
                    padding:30,
                    borderRadius:10
                }
            },
            'form-heading':{
                master:{
                    fontSize:20,
                    //textAlign:'center',
                    margin:'0px 0px 0px 13px',
                    fontWeight:'bold',
                    color:colorPallete.primary
                }
            },
            'contact-us-layout':{
                master:{
                   // margin:'auto',
                    display:'grid'
                }
            },
            'common-style-heading':{
                master: {
                    marginBottom:4,
                    color:colorPallete.primary,
                    fontSize:20,
                    fontWeight:600,
                    //margin:'20px 0px 10px',
                    //textTransform: 'uppercase'
                    //textAlign:'center'
                }
            },
            'basic-shop-info':{
                master:{
                    fontSize:15,
                    margin:0,
                    //textAlign:'center'
                }
            },
            'common-style-para':{
                master:{
                    margin:0,
                    fontSize:15,
                    //textAlign:'center'
                },
                'name':{
                    mobile:{
                        width:300
                    }
                }
            },
            'contact-details':{
                master:{
                    paddingTop:10,
                }
            },
            'open-maps':{
                master:{
                    margin:5,
                    fontSize:15
                }
            },
            'shop-address':{
                master:{
                    fontSize:12,
                    margin:0,
                }
            },
            'shop-address-details':{
                master:{
                    display:'flex',
                    justifyContent:'space-between',
                }
            },
            'delivery-details-header':{
                master:{
                    color:colorPallete.primary,
                    fontSize:20,
                    margin:0,
                    marginBottom: 5
                }
            },
            'top-bar':{
                master:{
                    display:'flex',
                    marginBottom:30
                }
            },
            'mainlayout':{
                master:{
                    margin:'30px 30px'
                }
            },
            'top-bar-title':{
                master:{
                    margin:'0px 10px',
                    fontSize:22,
                }
            },
            'logo': {
                master: {
                    backgroundImage: `url(${config.shop.logo})`,
                    width: '100%',
                    // height:100,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    height: 290,
                    marginTop:-30
                }
            },
        })
    }
}

export default Entities.createPlace(ContactUs, {
    name: 'ContactUs'
});

