import React from "react";
import Entities from "@toolkit/entities";
import InventoryMedia from "../Pieces/inventoryMedia";
import withCart from "@core/modules/Cart/views/lib/withCart";

import _ from "lodash";

class SimpleInventory extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  getLabelValues() {
    return {
      group: this.props.group,
    };
  }
  renderSurface(values, { Block, MainLayout, Layout, Bits }) {
    return (
      <MainLayout>
        <p>{_.head(values.group).name}</p>
        <InventoryMedia media={_.get(_.head(values.group), "media")} />
      </MainLayout>
    );
  }

  getStylesheet = (colorPallete) => {
    return {
      "counter-block": {
        master: {
          bottom: 0,
        },
      },
      "inventory-quantity": {
        master: {
          width: 100,
        },
      },
      "secondary-text": {
        master: {
          margin: 0,
          color: "white",
          fontSize: 9,
          fontWeight: 600,
          //paddingTop: 1
        },
        mobile: {
          fontSize: 8,
        },
      },
      "discount-percentage": {
        master: {
          color: "white",
          fontWeight: "bold",
          fontSize: 14,
          margin: "auto",
          //marginTop: 2
        },
        mobile: {
          fontSize: 13,
        },
      },
      "discount-badge": {
        master: {
          background: colorPallete.teritary,
          position: "absolute",
          right: 0,
          borderRadius: "50%",
          width: 30,
          height: 30,
          padding: 5,
          textAlign: "center",
          zIndex: 1,
        },
        mobile: {
          width: 28,
          height: 28,
          padding: 6,
        },
      },
      "inventory-card-image-container": {
        master: {
          position: "relative",
        },
      },
      "out-of-stock": {
        master: {
          fontSize: 12,
          margin: "0px 0px",
          color: colorPallete.subtext,
        },
      },
      //   'price-quantity-block': {
      //     master: {
      //       display: 'flex',
      //       flexDirection: this.state.quantityVariants.length === 0 ? 'row' : 'column',
      //       justifyContent: 'space-between',
      //       width: '180px'
      //     }
      //   },
      "price-block": {
        master: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
        },
      },
      "quantity-block": {
        master: {
          fontSize: 14,
          color: colorPallete.subtext,
          margin: 0,
          padding: "5px 0px",
        },
      },
      item: {
        master: {
          width: 200,
          height: "auto",
          padding: "5px",
          position: "relative",
        },
        mobile: {
          width: "calc(100vw/2 - 40px)",
          margin: 0,
          padding: "5px 0px",
        },
      },
      "item-details": {
        master: {
          //marginTop: 10
        },
      },
      quantity: {
        master: {
          margin: 0,
          color: colorPallete.subtext,
          fontSize: 10,
        },
      },
      "card-title": {
        master: {
          fontSize: 17,
          color: colorPallete.black,
          //   marginBottom: tokenManager.isStaff() ? 0 : 10,
          fontWeight: 600,
          margin: "5px 0px",
          lineHeight: 1.5,
          marginBottom: 6,
          overflow: "hidden",
          textOverflow: "ellipsis",
          height: 51,
        },
        mobile: {
          fontSize: 19,
          lineHeight: 1.5,
          height: "auto",
          fontWeight: 600,
        },
      },
      rates: {
        master: {
          display: "flex",
          alignItems: "center",
        },
      },
      "card-image": {
        master: {
          margin: 5,
          borderRadius: 3,
        },
      },
      "card-content": {
        master: {
          fontSize: 16,
          fontWeight: 600,
          marginRight: 10,
          color: colorPallete.darkGrey,
        },
      },
      "card-content-strike": {
        master: {
          fontSize: 12,
          color: colorPallete.darkGrey,
          textDecoration: "line-through",
        },
      },
      "flex-row-space-btw": {
        master: {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          //width:'100%'
        },
      },
      "flex-column": {
        master: {
          display: "flex",
          flexDirection: "column",
        },
      },
    };
  };
}

export default Entities.createThing(SimpleInventory, {
  name: "InventoryCard",
});
