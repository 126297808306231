import React from "react";
import _ from 'lodash';
import Entities from '@toolkit/entities';
import config from '@config/index';

class AboutUs extends React.Component {

    constructor(props) {
        super(props);
        this.name = config.getConfig('shop').name;
        this.description = config.getConfig('shop').description;
        this.about = config.getConfig('shop').about;
        this.logo = config.getConfig('shop').logo;
        this.googleMapLink = config.getConfig('shop').googleMapLink;
        this.address = config.getConfig('shop').address;
        this.businessHours = config.getConfig('shop').businessHours;
        this.webAddress = config.getConfig('shop').webAddress;
        this.contact = config.getConfig('shop').contact;
        this.socialmedialinks = config.getConfig('shop').socialmedialinks;
    }

    async getData() {
        this.setState({

        })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({

        })
    }


    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }) {
        return (
            <MainLayout name="mainlayout">
                <View landing>
                    <Layout>

                        <Block name="logo-name-container">
                            <Block name="title">
                                <Bits.Text name="name-text">{this.name}</Bits.Text>
                                <Bits.Text name="description">{this.description}</Bits.Text>
                            </Block>
                            <Block name='logo'>
                                <Bits.Image src={this.logo} />
                            </Block>
                        </Block>
                        {
                            _.get(this.about, 'text') &&
                            <Block name="about-us">
                                <Block name="about-title">
                                    <Bits.Text name="common-style-heading">Our Story</Bits.Text>
                                    <Bits.Text name="common-style-para name">{this.about.text}</Bits.Text>
                                </Block>
                            </Block>
                        }
                        {
                            !_.isEmpty(this.businessHours) &&
                            <Block name="business-hours">
                            <Block name="timings">
                                <Bits.Text name="common-style-heading">Timings</Bits.Text>
                                <Bits.Text name="common-style-para">{this.businessHours}</Bits.Text>
                            </Block>
                        </Block>
                        }
                        {
                            !_.isEmpty(this.addres) && 
                            <Block name="address">
                            <Block name="address-flex">
                                <Block name="address-text">
                                    <Bits.Text name="common-style-heading">Shop Address</Bits.Text>
                                </Block>
                                <Block name="directions">
                                    <a style={{ textDecoration: 'none', color: 'black', marginLeft: '20px' }} href={this.googleMapLink} target={"_blank"}>Get directions</a>
                                </Block>
                            </Block>
                            <Block>
                                {
                                    _.map(this.address, address => {
                                        return (
                                            <Bits.Text name="common-style-para">{address}</Bits.Text>
                                        )
                                    })
                                }
                            </Block>
                        </Block>
                        }
                        {
                            !_.isEmpty(this.socialmedialinks) &&
                                <Block name="follow-us">
                                    <Bits.Text name="common-style-heading">Follow us</Bits.Text>
                                    <Block name="social-media-flex">
                                    {Object.keys(this.socialmedialinks).map((key) => {
                                        console.log(key)
                                        return (
                                        <Block name="social-media-align">
                                            <Bits.IconButton href={this.socialmedialinks[key]} >
                                            <Bits.Icon>{key}</Bits.Icon>
                                        </Bits.IconButton>
                                        </Block>
                                        );
                                    })}
                                    </Block>
                                </Block>
                        }
                        <Block name="contact">
                            <Bits.Text name="common-style-heading">Contact</Bits.Text>
                            <Block name="footer-flex">
                                <Block name="person-icon">
                                    <Bits.Icon name='aboutus-icon'>Person</Bits.Icon>
                                </Block>
                                <Block name="person-text">
                                    <Bits.Text name="footer-text-margin">{this.contact.name}</Bits.Text>
                                </Block>
                            </Block>
                            <Block name="footer-flex">
                                <Block name="phone-icon">
                                    <Bits.Icon name='aboutus-icon'>Phone</Bits.Icon>
                                </Block>
                                <Block name="phone-text">
                                    <Bits.Text name="footer-text-margin">{this.contact.phone}</Bits.Text>
                                </Block>
                            </Block>
                            <Block name="footer-flex">
                                <Block name="mail-icon">
                                    <Bits.Icon name='aboutus-icon'>Mail</Bits.Icon>
                                </Block>
                                <Block name="mailid">
                                    <Bits.Text name="footer-text-margin">{this.contact.email}</Bits.Text>
                                </Block>
                            </Block>
                        </Block>
                    </Layout>
                    {/* <Layout>

                        <Block name="google-map">
                            
                        </Block>
                        <Block name='about-content'>
                            <Bits.Text name='address'>
                                
                            </Bits.Text> */}
                    {/* <Bits.Text name='bankaccount'>
                                {this.bankaccount.razorpayAccountId}
                                {this.bankaccount.branch}
                                {this.bankaccount.name}
                            </Bits.Text> */}
                    {/* <Bits.Text name='businessHours'>
                                
                            </Bits.Text>
                            <Bits.Text name='webaddress'>
                                {this.webAddress} 
                            </Bits.Text> */}
                    {/* </Block>
                    </Layout> */}
                </View>
            </MainLayout>
        )
    }

    getStylesheet = (colorPallete, config) => {
        return ({
            "social-media-align": {
                master: {
                  opacity: 0.9,
                },
                mobile: {
                  textAlign: "center",
                },
              },
            "social-media-flex": {
                master: {
                  display: "flex",
                  alignItems: "center",
                  height: 48,
                },
              },
            'aboutus-icon': {
                master: {
                    color: colorPallete.primary
                }
            },
            'about-content': {
                master: {
                    paddingBottom: 40
                }
            },
            'common-style-para name': {
                master: {
                    width: 570
                },
                mobile: {
                    width: '100%'
                }
            },
            'logo-name-container': {
                master: {
                    display: 'flex',
                    justifyContent: 'space-between'
                },
                mobile: {
                    // flexDirection:'column-reverse'
                }
            },
            'name-text': {
                master: {
                    color: colorPallete.primary,
                    fontSize: 50,
                    margin: '20px 0px 5px'
                },
                mobile: {
                    fontSize: 25
                }
            },
            'common-style-heading': {
                master: {
                    color: colorPallete.primary,
                    fontSize: 16,
                    fontWeight: 600,
                    margin: '20px 0px 10px',
                    textTransform: 'uppercase'
                }
            },
            'common-style-para': {
                master: {
                    margin: 0,
                    fontSize: 15,
                    textAlign: 'justify'
                },
                'name': {
                    mobile: {
                        width: 300
                    }
                }
            },
            'story-width': {
                master: {
                    width: 570
                }
            },
            // 'about-text':{
            //     master:{
            //         fontSize: 15,
            //         textAlign:'justify',
            //     },
            //     mobile:{
            //         width:350
            //     }
            // },
            'top-bar': {
                master: {
                    display: 'flex',
                    marginBottom: 30
                }
            },
            'mainlayout': {
                master: {
                    maxWidth: 900,
                    margin: '20px auto',
                    // margin:'30px 30px'
                },
                mobile: {
                    maxWidth: '90%'
                }
            },
            // a:{
            //     master:{
            //         textDecoration:'none',
            //     }
            // },
            'top-bar-title': {
                master: {
                    margin: '0px 10px',
                    fontSize: 22,
                }
            },
            'logo': {
                master: {
                    // backgroundImage: `url${require('@core/assests/images/logo-with-tag.png').default}`,
                    width: '50%',
                    // height:100,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    height: 175,
                    marginTop: -30
                }
            },
            'footer-flex': {
                master: {
                    display: 'flex',
                    alignItems: 'center'
                }
            },
            'footer-text-margin': {
                master: {
                    marginLeft: 10,
                    marginTop: 0,
                    marginBottom: 0
                }
            },
            'flex': {
                master: {
                    display: 'flex',
                    alignItems: 'flex-end',
                    justifyContent: 'space-between'
                }
            },
            'address-flex': {
                master: {
                    display: 'flex',
                    alignItems: 'baseline'
                }
            }
        })
    }
}

export default Entities.createPlace(AboutUs, {
    name: 'AboutUs'
});

