import React from "react";
import _ from "lodash";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Entities from "@toolkit/entities";
// import Counter from "@core/modules/shared/views/Things/counter";
import tokenManager from "@utils/lib/tokenManager";
// import CloseIcon from "@material-ui/icons/Close";
import { Divider } from "@material-ui/core";
import InventoryTags from "@core/modules/Inventory/views/Things/inventoryTags";
import InventoryMedia from "@core/modules/Inventory/views/Pieces/inventoryMedia";
import { RatingStars } from "@web-bricks/Shared/Input/rating";
import Counter from "./counter";
import LazyLoad from "react-lazyload";

class NewItemModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  getLabelValues = (_, transformers) => {
    return {
      //   cart: Helper.CartHelper.getCart(),
      // cartCount: Helper.CartHelper.getCount(),
      imageSample:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRGh5WFH8TOIfRKxUrIgJZoDCs1yvQ4hIcppw&usqp=CAU",
      // inventory : this.state.inventory
      description: _.tail(
        _.split(_.get(this.props.inventory, "description"), "•")
      ),
    };
  };

  renderSurface(values, { MainLayout, Layout, Bits, Block }) {
    console.log(this.props.group);
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={this.props.open}
        onClose={this.props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Layout name="item-modal-container">
          <Block name="arrow-overlay" onClick={this.props.handleClose}>
            <Bits.Icon name="close-icon">Close</Bits.Icon>
          </Block>
          <Layout name="item-modal-layout">
            <Layout name="item-image-container" key={this.props.inventory._id}>
              {!_.isEmpty(this.props.inventory.tags) && (
                <Block
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    right: 0,
                    bottom: 10,
                  }}
                >
                  <InventoryTags
                    tags={this.props.inventory.tags}
                    size="large"
                  />
                </Block>
              )}
              <Block name="item-modal-main-item-image">
                <InventoryMedia
                  media={this.props.inventory.media}
                  type="slider"
                />
              </Block>
            </Layout>
            <Layout name="item-details-container">
              <Block name="basic-info">
                <Block name="name-price">
                  <Bits.Text name="product-name">
                    {_.capitalize(this.props.inventory.label)}
                  </Bits.Text>
                  {this.props.inventory.rates && (
                    <Bits.Currency
                      name="product-price"
                      value={this.props.inventory.rates.saleRate}
                    />
                  )}
                </Block>
                <Block name="product-description-container">
                  {/* <Bits.BulletList
                    name="product-description"
                    list={_.map(values.description, (i) => ({
                      label: i,
                    }))}
                  /> */}
                  <Bits.Text
                    dangerouslySetInnerHTML={{
                      __html: this.props.inventory.description,
                    }}
                  />
                </Block>
                <Block>
                  {this.props.ratings && this.props.ratings.average > 0 && (
                    <Layout>
                      <Block name="rating-block">
                        <RatingStars
                          defaultValue={this.props.ratings.average}
                          readOnly
                          size="small"
                        />
                        <Bits.Text name="avg-rating">{`(${this.props.ratings.totalReviews})`}</Bits.Text>
                      </Block>
                    </Layout>
                  )}
                </Block>
                <Layout name="stack-indicator">
                  <Block>
                    {this.props.inventory.inStock === "NO" ? (
                      <Bits.Text name="stock-name">Stock unavailable</Bits.Text>
                    ) : (
                      <Bits.Text name="stock-name">Stock available</Bits.Text>
                    )}
                  </Block>
                  <Layout>
                    {/*(this.props.inventory.inStock !== "NO" &&
                                    !tokenManager.isStaff() &&
                                    _.map(this.getQuantityVariants, (variant, index) => {
                                    return (
                                        <Block key={index}>
                                            <Block name="price-table">
                                                <Bits.Text name="quantity">
                                                {variant.label}
                                                </Bits.Text>
                                                <Counter
                                                inventory={variant.inventory}
                                                count={this.cartValue(variant.inventory)}
                                                onChange={this.onCountChange}
                                                variants={[]}
                                                onQuantitySelect={this.onQuantitySelect}
                                                />
                                            </Block>
                                            <Divider />
                                        </Block>
                                    );
                                    })*/}
                  </Layout>
                </Layout>
                <Layout name="product-details">
                  <Block>
                    <Bits.Text name="details-heading">
                      Product Details
                    </Bits.Text>
                  </Block>
                  <Block name="property-block">
                    {_.map(
                      this.props.inventory.properties,
                      (property, index) => {
                        return (
                          <Block name="detail-row" key={index}>
                            <Bits.Text name="details-title">
                              {_.startCase(property.label)}
                            </Bits.Text>
                            <Bits.Text name="details-text">
                              {_.capitalize(property.value)}
                            </Bits.Text>
                          </Block>
                        );
                      }
                    )}
                  </Block>
                </Layout>
                <Layout name="cart-buttons">
                  <Counter
                    suffixText={this.props.group.label}
                    inventoryBook={this.props.inventoryBook}
                    variants={this.props.variants}
                  />
                </Layout>
              </Block>
            </Layout>
          </Layout>
        </Layout>
      </Modal>
    );
  }

  getStylesheet = (colorPallete) => {
    return {
      "cart-buttons": {
        master: {
          marginTop: 20,
        },
        mobile: {
          //position: "fixed",
          bottom: 0,
          left: 0,
          zIndex: 2,
          width: "100%",
          paddingTop: 10,
          paddingBottom: 10,
          paddingLeft: 10,
          paddingRight: 10,
          backgroundColor: "white",
          borderTop: "1px solid #eee",
          boxSizing: "border-box",
          marginTop: 0,
        },
      },
      "avg-rating": {
        master: {
          margin: 0,
          paddingLeft: 5,
          fontSize: 14,
        },
      },
      "rating-block": {
        master: {
          display: "flex",
          marginBottom: 10,
        },
      },
      "item-modal-main-item-image": {
        master: {
          maxWidth: "100%",
          width: "100%",
          height: "100%",
          margin: "auto",
        },
        mobile: {
          maxWidth: "100%",
        },
      },
      ItemModal: {
        master: {
          height: "100%",
          maxWidth: "100%",
        },
      },
      "cart-fab": {
        master: {
          backgroundColor: colorPallete.primary,
          borderRadius: 24,
          height: 50,
          width: 50,
          right: 40,
          bottom: 40,
          position: "fixed",
        },
      },
      "cart-fab.CartBasketIcon.cart-basket-icon-link": {
        master: {
          color: colorPallete.secondary,
        },
      },
      "item-modal-layout": {
        master: {
          display: "flex",
          justifyContent: "space-evenly",
          position: "relative",
          alignItems: "center",
          width: "100%",
          margin: "0 auto",
          height: "100vh",
          backgroundColor: "white",
          overflow: "auto",
          boxSizing: "border-box",
        },
        mobile: {
          flexDirection: "column",
          justifyContent: "unset",
          margin: 0,
          height: "75vh",
          position: "fixed",
          bottom: 0,
        },
        // transition: 'children-slideDown'
      },
      "item-details-container": {
        mobile: {
          width: "100%",
        },
      },
      "inventory-list-card": {
        master: {
          width: "200px",
          marginRight: 50,
          cursor: "pointer",
          display: "inline-flex",
        },
        mobile: {
          // width: 300
        },
      },
      "modal-container": {
        master: {
          maxWidth: "80%",
        },
      },
      description: {
        master: {
          margin: "15px 0px",
        },
        mobile: {
          width: "100%",
        },
      },
      "product-details": {
        master: {
          margin: "0px 0px",
        },
        mobile: {
          //marginBottom: 75,
          marginRight: 0,
          marginLeft: 0,
        },
      },
      "arrow-overlay": {
        master: {
          position: "fixed",
          right: 30,
          top: 30,
          zIndex: 2,
        },
        mobile: {
          padding: 5,
          right: "unset",
          position: "absolute",
          top: "calc(25vh - 75px)",
          left: "50%",
          transform: "translate(-50%)",
        },
      },
      "item-image-container": {
        master: {
          width: "50%",
          height: "auto",
          overflow: "hidden",
          position: "relative",
        },
        mobile: {
          width: "100%",
          paddingTop: 0,
          height: "auto",
          overflow: "unset",
        },
      },
      "item-modal-mainblock": {
        master: {
          marginTop: 80,
          backgroundColor: "#f6eee2",
        },
      },
      "property-block": {
        master: {
          opacity: 0.9,
        },
      },
      "stock-name": {
        master: {
          fontSize: 20,
          color: colorPallete.teritary,
          margin: 0,
          fontWeight: 400,
        },
        mobile: {
          fontSize: 18,
        },
      },
      "product-description": {
        master: {
          fontSize: 16,
          margin: 0,
          color: colorPallete.black,
          padding: "2px 0px",
        },
        mobile: {
          fontSize: 14,
        },
      },
      "product-name": {
        master: {
          fontSize: 24,
          fontWeight: 300,
          margin: 0,
          maxWidth: 390,
        },
        mobile: {
          fontSize: 22,
        },
      },
      "product-price": {
        master: {
          fontSize: 28,
          marginTop: 10,
          fontWeight: 400,
          color: colorPallete.black,
        },
        mobile: {
          fontSize: 20,
          fontWeight: 500,
        },
      },
      image: {
        master: {
          width: "100%",
          height: "100%",
          position: "relative",
        },
      },
      "name-price": {
        master: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
        },
      },
      "stack-indicator": {
        master: {
          borderBottom: "1px solid #b9b8b8",
          paddingBottom: 20,
        },
        mobile: {
          marginTop: 20,
        },
      },
      "details-heading": {
        master: {
          fontSize: 18,
          letterSpacing: 0.37,
          padding: "20px 15px 15px 0px",
          fontWeight: 600,
        },
        mobile: {
          fontSize: 17,
        },
      },
      "price-table": {
        master: {
          margin: "10px 0px",
        },
      },
      quantity: {
        master: {
          margin: "20px 0px",
          fontWeight: 600,
        },
      },
      "detail-row": {
        master: {
          display: "flex",
          alignItems: "center",
          margin: "8px 0px",
          fontSize: 12,
        },
        mobile: {
          margin: "5px 0px",
        },
      },
      inventory_list: {
        master: {
          overflow: "auto",
          padding: "20px 40px 40px",
          maxWidth: "100vw",
          whiteSpace: "nowrap",
        },
        mobile: {
          paddingLeft: 5,
        },
        // transition: 'children-slideRight'
      },
      fab: {
        master: {
          backgroundColor: "#ffefef",
          position: "fixed",
          bottom: 30,
          right: 15,
        },
      },
      "basic-info": {
        master: {
          minWidth: 500,
          margin: "20px 20px 20px",
        },
        mobile: {
          minWidth: "90%",
          margin: "0 auto",
          padding: 20,
        },
      },
      "details-title": {
        master: {
          margin: "10px 0px",
          fontSize: 14,
          fontWeight: 200,
          minWidth: 150,
          color: colorPallete.primary,
        },
        mobile: {
          margin: "5px 0px",
        },
      },
      "details-text": {
        master: {
          color: colorPallete.subtext,
          margin: 0,
          fontWeight: 400,
        },
      },
      "similar-bar-title": {
        master: {
          fontSize: 16,
          textTransform: "uppercase",
          marginLeft: 50,
          letterSpacing: 0.37,
        },
        mobile: {
          marginLeft: 20,
          fontSize: 14,
        },
      },
      "InventoryCard.inventory-card-image-container": {
        master: {
          // maxWidth: 200
        },
      },
      "similar-item-block": {
        master: {
          padding: "10px 40px",
        },
        mobile: {
          padding: "0px 15px",
        },
      },
      "similar-bar": {
        master: {},
      },
    };
  };
}

NewItemModal = Entities.createPlace(NewItemModal, {
  name: "NewItemModal",
});

const HOC = function (Component) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        ...this.state,
        _open: false,
      };
      this.renderExpandView = this.renderExpandView.bind(this);
      this.expandView = this.expandView.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.renderQuickAdd = this.renderQuickAdd.bind(this);
    }

    getInventoryBook = () =>
      _.zipObject(
        _.map(this.props.group.inventories, "_id"),
        this.props.group.inventories
      );

    getInventory = () => _.head(_.get(this.props.group, "inventories"));

    getInventoryId = () => _.get(this.props.group, "inventories.0._id");

    getVariants = () => {
      return _.reduce(
        _.get(this.props.group, "inventories"),
        (variants, inventory) => {
          const quantityProperty = _.find(inventory.properties, {
            label: "quantity",
          });
          if (quantityProperty)
            variants.push({
              label: inventory.rates
                ? `${quantityProperty.value} ₹${inventory.rates.saleRate}`
                : `${quantityProperty.value}`,
              value: inventory._id,
              // inventory: inventory,
            });

          return variants;
        },
        []
      );
    };

    expandView() {
      this.setState({
        _open: true,
      });
    }

    handleClose() {
      this.setState({
        _open: false,
      });
    }

    renderQuickAdd(lazy = true) {
      const counter = (
        <Counter
          inventoryBook={this.getInventoryBook()}
          variants={this.getVariants()}
          quickView
          outlined
          onClick={this.expandView}
        />
      );
      return lazy ? (
        <LazyLoad height={25} scrollContainer=".ReactVirtualized__Grid">
          {counter}
        </LazyLoad>
      ) : (
        counter
      );
    }

    renderExpandView() {
      return !this.state._open ? null : (
        <NewItemModal
          key={this.getInventoryId()}
          open={this.state._open}
          handleClose={this.handleClose}
          group={this.props.group}
          inventoryBook={this.getInventoryBook()}
          variants={this.getVariants()}
          inventory={this.getInventory()}
        />
      );
    }
  };
};

export default HOC;
