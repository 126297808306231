import Video from '@web-bricks/Shared/backgroundVideo/video';
import Entities from '@toolkit/entities';
import React from 'react';

class BackgroundVideo extends React.PureComponent {
    constructor(props) {
        super(props)
    }

    renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
        return (
            <Block name="bg-video">
                {
                    !this.props.onlyVideo && <Block name="video-info">
                    <Bits.Text name="video-header" className="outline-animate">DRESSES<br/>& BAGS</Bits.Text>
                    <Bits.Text name="video-caption">I see it.   I like it.   I got it!</Bits.Text> 
                    <Bits.Link to="/categories">
                        <Bits.Button size="large" name="video-action" endIcon={<Bits.Icon>ArrowRightAlt</Bits.Icon>} className="hover-border-shine">Get the latest</Bits.Button>
                    </Bits.Link>
                </Block>
                }
                <Block name="video-container">
                    <Video link={this.props.url}/>
                </Block>
            </Block>
            
        )
    }

    getStylesheet = (colorPallete) => {
        return {
            'bg-video' : {
                master: {
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#212121',
                },

            },
            'video-info': {
                master: {
                    position: 'absolute',
                    left: '50%',
                    top: '60%',
                    transform: 'translateX(-50%)',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    textAlign: 'center'
                },
                mobile: {
                    left: '5%',
                    top: '70%'
                }
            },
            'video-header': {
                master: {
                    textTransform: 'uppercase',
                    fontSize: 64,
                    // color: 'white',
                    fontWeight: 600,
                    letterSpacing: 7,
                    textAlign: 'center',
                    margin: 0
                },
                mobile: {
                    fontSize: 40,
                    letterSpacing: 0.87,
                    width: '100%',
                    opacity: 1
                }
            },
            'video-caption': {
                master: {
                    textTransform: 'uppercase',
                    fontSize: 18,
                    color: 'white',
                    fontWeight: 400,
                    margin: '20px 10px 20px 10px',
                    top: 10,
                    position: 'relative'
                },
                mobile: {
                    fontSize: 12,
                    top: 10,
                    textAlign: 'center',
                    margin: '10px 10px 10px 10px',
                }
            },
            'video-action': {
                master: {
                    margin: '20px 0px',
                    top: 10,
                    fontStyle: 'italic',
                    width: '100%'
                }
            },
            'video-container': {
                master: {
                    width: '100%',
                    height: '100%',
                    opacity: 0.6
                },
                mobile: {
                    height: '100%'
                }
            }
        }
    }
}

export default Entities.createPlace(BackgroundVideo, {
    name: "BackgroundVideo",
  });
