import React from 'react';
import Entities from '@toolkit/entities';

class Caption extends React.PureComponent {
    constructor(props) {
        super(props)
    }

    renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
        return (
            <Block name="message-caption-outer">
                <Bits.Text name="message-caption">{this.props.caption}</Bits.Text>
            </Block>
            
        )
    }

    getStylesheet = (colorPallete) => {
        return {
            'story-line': {
                master: {
                    fontSize: 24,
                    textAlign: 'center'
                }
            }
        }
    }
}

export default Entities.createPlace(Caption, {
    name: "Caption",
  });
