// import React from 'react';
// import _ from 'lodash';
// import classNames from 'classnames';
// import Timeline from '@material-ui/lab/Timeline';
// import TimelineItem from '@material-ui/lab/TimelineItem';
// import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
// import TimelineConnector from '@material-ui/lab/TimelineConnector';
// import TimelineContent from '@material-ui/lab/TimelineContent';
// import TimelineDot from '@material-ui/lab/TimelineDot';
// import { CheckCircle, DockSharp, FiberManualRecordRounded } from '@material-ui/icons';


// const statusToColorMap = {
//     REQUESTED: 'warning',
//     SCHEDULED: 'green',
//     CANCELLED: 'red'
// };

// class Stepper extends React.Component {

//     constructor(props) {
//         super(props)
//     }

//     shapeUp = (list) => {
//         return _.reverse(_.sortBy(_.compact(list), 'date'));
//     }

//     render() {
//         return (
//             <div className="stepper">
//                 <p className="no-margin bold grey-text _heading">{this.props.title}</p>
//                 {
//                     !_.isEmpty(this.props.steps) ?
//                         <Timeline align="left">
//                             {
//                                 _.map(this.shapeUp(this.props.steps), (step, index) => {
//                                     return (
//                                         <TimelineItem key={index}>
//                                             <TimelineSeparator>
//                                                 <TimelineDot color="primary">
//                                                     {
//                                                         step.completed ? <CheckCircle/> : <FiberManualRecordRounded />
//                                                     }
//                                                 </TimelineDot>
//                                                 <TimelineConnector />
//                                             </TimelineSeparator>
//                                             <TimelineContent className="_step">
//                                                 <p className="no-margin medium-title bold">{step.label}</p>
//                                                 {
//                                                     step.tags &&
//                                                         <div className="flex _tags">
//                                                             {_.map(step.tags, (tag, index) => {
//                                                                 return (
//                                                                     <span className={classNames('_tag', 'bg-' + (tag.color || statusToColorMap[tag.status] || 'green'))}>
//                                                                         {_.capitalize(_.trim(tag.label))}
//                                                                     </span>
//                                                                 )
//                                                             })}
//                                                         </div>
//                                                 }
//                                                 <div>
//                                                     {step.renderContent()}
//                                                 </div>
//                                             </TimelineContent>
//                                         </TimelineItem>
//                                     )
//                                 })
//                             }
//                         </Timeline> :
//                     <p className="caption text-center margin-t-b-20">{this.props.emptyMessage}</p>
//                 }
//             </div>

//         )
//     }
// }

// export default Stepper;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));


function getStepContent(step) {
  switch (step) {
    case 0:
      return `Date`;
    case 1:
      return 'Date';
    case 2:
      return `Date`;
    default:
      return 'Unknown step';
  }
}

export default function VerticalLinearStepper(props) {
  const classes = useStyles();
  const steps = props.steps;

  return (
    <div className={classes.root}>
      <Stepper activeStep={props.activeStepIndex} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel>{label}</StepLabel>
            {/* <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              {
                  props.buttons && <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </div>
                </div>
              }
            </StepContent> */}
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
