import React from 'react';
import interiorDesigner from '@toolkit/lib/interiorDesigner';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import Entities from '@toolkit/entities';
import DialogBox from '@core/modules/shared/views/Things/dialogBox';
import _ from 'lodash';
class Counter extends React.PureComponent {

  constructor(props) {
    super(props)
    this.onAddItem = this.onAddItem.bind(this)
  }
  getCount = () => this.props.count || 0

  onRemoveItem = (e) => {
    e.stopPropagation();
    this.onUpdate({
      count: this.getCount() > 0 ? this.getCount() - 1 : this.getCount()
    });
  }

  onAddItem = (e) => {
    e.stopPropagation();
    this.onUpdate({
      count: this.getCount() + 1
    });
  }

  onUpdate = ({ count }) => {
    this.props.onChange(count, this.props.inventory)
  }

  getLabelValues = (formatter, _, transformers) => {
    return ({

    })
  }

  renderAddButton() {
    const { Block, Layout, Bits } = this.deps;
    return <Layout name="counter-outline-add" onClick={this.onAddItem}>
        <Block name="counter-outline-add-inner" >
          <Bits.Text name='counter-button'>
            Add{this.props.suffixText && ` ${this.props.suffixText}`}
          </Bits.Text>
        </Block>
      </Layout>
  }

  renderSurface(values, {
    MainLayout,
    Layout,
    Block,
    Bits,
    View,
  }) {
    return (
      <MainLayout>
            <Layout name="counter-layout">
              {
                this.getCount() > 0 &&
                  <Layout name="counter-outline">
                    {
                      (this.props.quickView && (_.size(this.props.variants) > 1)) ?
                        this.renderAddButton() : 
                        <Layout name="counter-flex" >
                          <RemoveIcon onClick={this.onRemoveItem} color="action"
                            style={{
                              marginRight: 3,
                              fontSize: 15,
                              backgroundColor: interiorDesigner.colorPallete.primary,
                              borderRadius: 4,
                              color: interiorDesigner.colorPallete.secondary,
                              padding: 2
                            }}
                          />
                          <Bits.Text name='count-font'>{this.getCount()}</Bits.Text>
                          <AddIcon onClick={this.onAddItem} fontSize="small" color="action"
                            style={{
                              marginLeft: 3,
                              fontSize: 15,
                              backgroundColor: interiorDesigner.colorPallete.primary,
                              borderRadius: 4,
                              color: interiorDesigner.colorPallete.secondary,
                              padding: 2
                            }}
                          />
                        </Layout>
                      }
                    </Layout>
              }
              {
                (this.getCount() === 0 || this.props.alwaysShowAdd) &&
                  this.renderAddButton()
              }
            </Layout>
      </MainLayout>
    );
  }

  getStylesheet = (colorPallete) => {
    return ({
      'counter-layout': {
        master: {
          display: 'flex',
          alignItems: 'center',
          height: this.props.small ? 25 : 'unset'
        }
      },
      'button': {
        master: {
          marginTop: -8
        }
      },
      'counter-outline': {
        master: {
          border: '1px solid',
          borderColor: colorPallete.primary,
          padding: '8px 0px',
          borderRadius: 3,
          width: 'fit-content',
          cursor: 'pointer',
        },
        mobile: this.props.suffixText ? {
          marginRight: 10,
        } : {}
      },
      'counter-outline-add': {
        master: {
          width: this.props.quickView ? 'unset' : '100%',
          border: '1px solid',
          borderColor: colorPallete.primary,
          borderRadius: 3,
          padding: '1px 9px',
          background: this.props.outlined ? 'transparent' : colorPallete.primary,
          cursor: 'pointer',
          height: this.props.small ? 20 : 35,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
        mobile: this.props.alwaysShowAdd ? {
          flex: 1
        } : {

        }
      },
      'counter-button': {
        master: {
          color: this.props.outlined ? colorPallete.primary : 'white',
          margin: 5,
          textAlign: 'center',
          fontSize: this.props.small ? 14 : 17,
          fontWeight: 600,
        }
      },
      'counter-flex': {
        master: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginLeft: 3,
          marginRight: 3,
          marginTop: '-1px'
        }
      },
      'count-font': {
        master: {
          margin: '0 6px',
          lineHeight: '12px',
          fontSize: '20px'
        }
      },
    })
  }
}

export default Entities.createPlace(Counter, {
  name: 'Counter'
});