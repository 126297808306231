import Entities from '@toolkit/entities';
import _ from 'lodash';
import {SignIn} from '@core/modules/User/views/Places/login/signIn.js'
import Helper from '@core/helpers';


class VendorSignIn extends SignIn {

  constructor(props) {
    super(props);
      this.state = {
        
      }
      this.signInHelper = new Helper.UserSigninHelper()

    }

  // isValidNumber = (phoneNum) =>{
  //   var pattern = /^(0|([1-9]\d*))$/;
  //   if(pattern.test(phoneNum) && phoneNum.length === 10){
  //     return true
  //   }else{
  //     this.setState({
  //       inValidPhoneNum : true,
  //       errorMessage:'Invalid phone number!'
  //     })
  //   }
  // }

  onSubmit = (values) => {
    this.setState({
      phoneNumberIncorrect: false,
      incorrectReason: null
    })
    const phone = _.toString(values.phone);
    let validity = this.isValidNumber(phone)
    if(validity){
      this.signInHelper.onLookUpVendor(phone, () => {
        this.goToView('/account/password')
    }, () => {
      this.setState({
        inValidPhoneNum : true,
        errorMessage:'Enter registered phone number!'
      })
      values.onSuccess()
    })
    this.signInHelper.storePhoneNumber(phone)
  }else{
    values.onSuccess()
  }
  }

}

export default Entities.createPlace(VendorSignIn, {
  name: "VendorSignIn",
});
