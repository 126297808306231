import React, {Component} from 'react';
import AutoComplete from '@material-ui/lab/Autocomplete';
import CustomFormHOC from './customFormHoc';
import { places, getPlaces } from '../../lib/places';
import _ from 'lodash';
import classNames from 'classnames';
import { withFormsy } from 'formsy-react';
import { TextField } from '@material-ui/core';

class Prediction extends Component {
    constructor(props) {
      super(props);
      this.state = {
        dataSource : [],
        inputValue : this.props.value ,
        isFocused: false,
        index: 0,
      }
      this.handleChange = this.handleChange.bind(this);
      this.onNewRequest = this.onNewRequest.bind(this)
    }

    componentDidUpdate(prevProps) {
      if (!_.isEqual(this.props.value, prevProps.value)) {
        this.setState({
          inputValue: this.props.value,
          index: this.state.index++
        })
      }
    }

    handleChange(e, inputValue) {
      if (inputValue) {
        places(inputValue, (data) => {
          if(!_.isEmpty(data)){
            const dataSource = data.map((place) => {
              return ({
                label: place.description,
                value: place.place_id,
              })
            });
            this.setState({
              dataSource
            })
          }
        });
      } else {
        this.setState({
          dataSource: [],
        });
      }
      this.setState({
        inputValue,
      })

      if(!this.props.setWholeAddressAsValue) {
        this.props.setValue(inputValue);
      }
    }


    onNewRequest(e, chosenRequest){
      if (chosenRequest) {
        const placeId = chosenRequest.value;
        getPlaces(placeId, (data) => {
          this.props.onSelect(data);
          if(this.props.setWholeAddressAsValue){
            this.props.setValue(data);
          }
        });
      }
    }

    onFocus = () => {
      this.setState({
        isFocused: true,
      })
    }

    onBlur = () => {
      this.setState({
        isFocused: false,
      })
    }
  
    render() {
      return (
        <div className={classNames('predictions__container', this.props.className)}>
            <i class="fas fa-search"></i>
            <AutoComplete
              className={classNames({ 'box-view': this.props.boxView, 'focused': this.state.isFocused, 'has-value': _.size(this.state.inputValue) > 0 }, 'AddressLane1', this.props.className )}
              onChange={this.onNewRequest}
              onInputChange={this.handleChange}
              filterOptions={(options, params) => {
              return options;
          }}
          value={{ label: this.state.inputValue }}
          options={this.state.dataSource}
          getOptionLabel={(option) => {
            return option.label;
          }}
          renderInput={(params) => (
            <TextField key={this.state.index} defaultValue={this.props.defaultValue} {...params}  label={this.props.label} />
          )}
        />
              
        </div>
      )
    }
  }

Prediction.defaultProps = {
  placeholder: 'Address Lane 1',
  onSelect: _.noop,
  restrictLabel: true,
};


export default {
  input: CustomFormHOC(Prediction),
  formInput: withFormsy(Prediction),
};

// <AutoComplete
//                 hintText={this.props.placeholder}
//                 searchText={this.state.inputValue}
//                 onUpdateInput = {t} 
//                 filter = {AutoComplete.noFilter} 
//                 onFocus={this.onFocus}
//                 onBlur={this.onBlur}
//                 dataSource={this.state.dataSource}
//                 onNewRequest={this.onNewRequest}
//                 required={this.props.required}
//                 fullWidth
//                 renderInput={(params) => (
//                   <TextField {...params} label={this.props.label}/>
//                 )}
//               />