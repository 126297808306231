import { Avatar, CircularProgress } from "@material-ui/core";
import { Image } from "@material-ui/icons";
import React, { Component } from "react";
import customFormHoc from "@web-bricks/Shared/Input/customFormHoc";
import _ from 'lodash'; 
import FullScreenDialog from '@web-bricks/Shared/view/components/dailog';
import FirebaseUploaderButton from '@web-bricks/Shared/Input/uploadToFirebaseButton';
import Badge from '@material-ui/core/Badge';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import Entities from '@toolkit/entities';
import firebase from '@firebaseConnect';

class MultiMedia extends Component {

  constructor(props) {
    super(props);

    this.state = {
      mediaList : !_.isEmpty(props.defaultValue) && props.defaultValue || [],
      isUploading: false,
      showDailogFlag : false,
      previewImage : _.isEmpty(props.defaultValue) ? null : _.head(props.defaultValue).url
    };

    this.props.setValue(this.props.defaultValue);
  }

  componentDidCatch(r) {
    console.log(r)
  }

  getURL = () => {
    const value = this.props.value || null;

    if (value) {
      return this.props.mediaHelper.getOriginalFileURL(value);
    } else return null;
  }

  onDailogOpen = () => {
    this.setState({
        showDailogFlag: true,
    })
  }

  onDailogClose = () => {
      this.setState({
          showDailogFlag: false
      })
  }

  onChange = (url, fileName) => {
    let mediaList = this.state.mediaList 
    let data ={
      url: fileName,
      type:'IMAGE',
    }
    mediaList.push(data)
    this.setState({
      mediaList : mediaList
    })
    this.setState({
      refreshKey : !this.state.refreshKey,
      previewImage:fileName
    })
  }

  onShowPreview = (url) =>{
    this.setState({
      previewImage: url
    })
  }

  renderRightButtons = () =>{
    return(
      <FirebaseUploaderButton.input
        {...this.props} 
        name="url"
        getFirebase={() => firebase.shop}
        mediaHelper={this.props.mediaHelper}
        onChange={(url, fileName) => this.onChange(url, fileName)} 
      />
    )
  }

  getLabelValues = (formatter, _, transformers) => {
    return ({
    })
  }

  onRemoveImage = (i) =>{
    console.log(i)
    let mediaList = []
    _.map(this.state.mediaList, (item, index) =>{
      if(!_.isEqual(i, index)){
        mediaList.push(item)
      }
    })
    this.setState({
      mediaList: mediaList,
      previewImage :_.isEmpty(mediaList) ? null : _.head(mediaList).url
    })
  }

  renderImages =(Block, Bits) =>{
    return(
      <Block>
        {
          !_.isEmpty(this.state.mediaList) ?
          <Block>
            <Block style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:45}}>
              <Bits.Image src={this.state.previewImage} key={this.state.previewImage} alt='' style={{width:350, height:350, borderRadius:10}}/>
            </Block>
            <Block style={{display:'flex', margin:15, maxWidth: '100%', whiteSpace: 'nowrap',overflow: 'scroll'}}>
              {
                _.map(this.state.mediaList, (item,index) =>{
                  return(
                    <Block>
                    { 
                      _.isEmpty(item.url) ? <Avatar className="f-f-upload-btn" style={{height:100, width:100, marginRight:15}} color="secondary" variant="rounded" ><Image color="primary" style={{fontSize:150}}/></Avatar> :
                        <Block style={{ marginRight:25, position:'relative'}}>
                            <Block style={{background: 'black', opacity:0.5, borderRadius:25, position:'absolute', right:0,zIndex:1, margin:6 }}>
                              <IconButton component="span">
                                <ClearIcon onClick={()=>this.onRemoveImage(index)} style={{color:'white', fontSize:15}}/>
                              </IconButton>
                            </Block>
                            <Bits.Image src={item.url} alt='' style={{width:160, height:160, borderRadius:10 ,borderColor:this.state.previewImage === item.url ? '#18485F': null, borderStyle:'solid',borderWidth:'thick'}} onClick={() => this.onShowPreview(item.url)}/>
                        </Block>
                    } 
                    </Block>
                  )
                })
              }
            </Block> 
          </Block>: 
          <Block style={{width:500, height:500, alignItems:'center', justifyContent:'center', margin:'auto'}}>
            <Bits.Text style={{color:'white' , fontSize:20}}>Press 'ADD IMAGE' on the top to insert images</Bits.Text>
          </Block>
        }
      </Block>
    )
  }

  onSaveMedia = () =>{
    this.props.setValue(this.state.mediaList)
    this.props.onChange(this.state.mediaList)
    this.setState({
      showDailogFlag: false,
    })
  }

  getLabelValues= () => ({})
 
  renderSurface(values, {
    MainLayout,
    Layout,
    Block,
    View,
    Bits
  }) {
    return (
      <MainLayout>
        <View>
          <Layout>
            <Block className="f-f-upload-avatar">
              {
                _.isEmpty(this.state.mediaList) ?
                  <Avatar className="f-f-upload-btn" color="secondary" variant="rounded" >
                    <Image  className="f-f-upload-icon"  color="primary" onClick={this.onDailogOpen}/>
                  </Avatar> : 
                  <Block>
                    <Badge badgeContent={this.state.mediaList.length} color="primary">
                      <Bits.Image src={_.head(this.state.mediaList).url} alt='' onClick={this.onDailogOpen} style={{width:40, height:40, marginRight:5, borderRadius:10}}/>
                    </Badge>
                  </Block>
              }
            </Block>
            </Layout>
            {
              this.state.showDailogFlag &&
                <FullScreenDialog
                    handleClickOpen={this.onDailogOpen}
                    handleClose={this.onDailogClose}
                    open={this.state.showDailogFlag}
                    dialogTitle='Media'
                    getRenderItems={() => this.renderImages(Block, Bits)}  
                    rightPrimaryButtons={[
                      {
                        label:'save',
                        onClick:this.onSaveMedia
                      }
                    ]}
                    RightSecondaryButtons={this.renderRightButtons()}
                    PaperProps= {{
                      style: {
                        backgroundColor: 'black',
                        opacity:0.9,
                        boxShadow: 'none',
                      },
                    }}
                    style= {{ background: 'transparent'}}
                />
            }
          </View>
        </MainLayout>
    );
  }

  getStylesheet = (colorPallete) => {
    return ({

    })
  }

}

MultiMedia.defaultProps = {
  onChange: _.noop,
}
 

const MultimediaInputEntity = Entities.createPlace(MultiMedia, {
  name: 'MultimediaInputEntity'
})

export default {
    formInput: MultimediaInputEntity,
    input: customFormHoc(MultimediaInputEntity)
}
