import React from "react"
import _ from 'lodash';
import Helper from '@core/helpers';
import Entities from '@toolkit/entities';
import VerticalLinearStepper from '@web-bricks/Shared/Input/stepper';
import Avatar from '@material-ui/core/Avatar';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import orderProcesses from "@core/enums/orderProcesses";
import interiorDesigner from "@toolkit/lib/interiorDesigner";
import config from "@config";
import Table from '@web-bricks/Shared/table/table';
import InventoryTags from '@core/modules/Inventory/views/Things/inventoryTags';
import Container from '@core/modules/shared/views/Things/container.js';
import Button from "@web-bricks/Shared/Input/button";
import PhoneIcon from '@material-ui/icons/Phone';
import StarIcon from '@material-ui/icons/Star';
class OrderSummary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            orderId: this.props.match.params._id ,
            order:{}
        };
        this.helper = new Helper.OrderHelper()
        this.transactionHelper = new Helper.TransactionHelper()
        this.shopHelper = new Helper.ShopHelper()
        this.reviewHelper = new Helper.ReviewHelper()
    }

    async getData() {
        const order = await this.helper.getById(this.state.orderId)
        // const transactionDetails = await this.transactionHelper.getTransactionById(order.transactionId)
        const shopDetails = await this.shopHelper.fetchMyShopDetails()
        const reviews = await this.reviewHelper.getReviews(this.state.orderId)
        this.setState({
            order : order,
            shopDetails:shopDetails,
            reviewStatus: reviews.status
        })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({
            NoOfItemsOrdered : this.state.order.orderedItems.length,
            orderId: this.state.order.orderNumber,
            hasDicount: this.helper.hasDiscount(this.state.order),
            discount: this.helper.getDiscount(this.state.order),
            billedAmount: this.helper.getBilledAmount(this.state.order),
            orderStatus: this.state.order.status,
            reviewStatus : this.state.reviewStatus
        })
    }

    getProcess = () => {
        if(this.state.order.orderType === 'takeaway'){
            return orderProcesses.takeawayProcess
        }else if(this.state.order.status=== 'CANCELLED'){
            return orderProcesses.cancelledProcess
        }else{
            return orderProcesses.internal_delivery_process
        }
    }

    getActiveStepIndex = () => {
        const process = this.getProcess();
        return _.findIndex(process, { status: this.state.order.status });
    }

    // changeStatus = async () =>{
    //     //await this.transactionHelper.aptForRefund(this.state.order)
    //     await api.Orders.update(this.state.order._id, {
    //         status : "CANCELLED",
    //         isCancelled: true,
    //         cancelledAt: new Date()
    //     })
    //     const order = await this.helper.getById(this.state.orderId)
    //     this.setState({
    //         order : order,
    //     })
    
    // }

    gotoReview= () =>{
        this.props.history.push(`/orderSummary/writeReview/${this.state.order._id}`)
    }

    getSumedAmount = () =>{
        // console.log(this.state.order, 
        return _.sumBy(this.state.order.orderedItems, "totalAmount")
    }

    getOrderedItemsData = (Bits, Block) =>{
        const headers = [{
            label: 'Name',
        }, {
            label: 'Quantity',
            align: 'right'
        },{
            label: 'Rate',
            align: 'right'
        },];

        const rows = _.map(this.state.order.orderedItems,item => ([{
            column: 'Name',
            value:  item.name,
            // render: () =>{
            //     return(
            //         <Block name='table-inventpry-name'>
            //             <Bits.Text>{item.name}</Bits.Text>
            //             <InventoryTags tags={item.id} direction='row'/>
            //         </Block>
            //     )
            // }
        }, {
            column: 'Quantity',
            value:  item.quantity
        },{
            column: 'Rate',
            value: `₹ ${item.totalAmount}`
        },
            
    ]));
    const subRows =[
        {
            title: 'Amount',
            value:  `₹ ${this.state.order.bill.sumAmount ? this.state.order.bill.sumAmount : this.getSumedAmount() }`
        }, {
            title: 'Delivery Charges',
            value: `₹ ${this.state.order.bill.deliveryCharge}`
        },{
            title: 'Coupon Discount',
            value:  `₹ ${this.state.order.bill.couponedDiscount}`
        },{
            title: 'Grand Total',
            value: `₹ ${this.state.order.bill.totalAmount}`
        }
    ]
        return { rows, headers, subRows};
    
    }


    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }) {
        const preventDefault = (event) => event.preventDefault();
        const shop = config.getConfig('shop')
        return(
            <MainLayout name='order-details'>
                <Container
                    page={{ title: 'Order Summary' }}
                    previousPage={[{title:'All orders', onClick: () => this.props.history.push('/allOrders/ordered')}]}
                    activePage={'Order status'}
                >
                <View >
                    <Layout name="order-summary-container">
                        <Layout name='review-layout'>
                            {
                                (values.orderStatus === 'DELIVERED' || values.orderStatus === 'TOOK_AWAY') && values.reviewStatus !== 'COMPLETED' &&
                                <Block name='review-text-block'> 
                                    <Block name='review-icon-block'>
                                        <StarIcon style={{color:"yellow", marginRight:5}}/>  
                                        <Bits.Text name='review-text'>Help us to improve quality & shop's experience</Bits.Text>
                                    </Block> 
                                    <Button  
                                       // style={{height:30}}
                                        onClick={this.gotoReview}
                                        variant='contained'
                                    >Review order</Button>
                                </Block>
                            }
                        </Layout>
                        <Layout>
                            <Block>
                                <Block>
                                    <Bits.Text name='status-title'>STATUS</Bits.Text>
                                </Block>
                                <VerticalLinearStepper
                                    steps={_.map(this.getProcess(), 'label')}
                                    activeStepIndex={this.getActiveStepIndex()}
                                />
                            </Block>
                        </Layout>
                        <Layout>
                            <Block name='details'>
                                <Block name='details-header'>
                                    <Bits.Text name='details-title'>DETAILS</Bits.Text>
                                    {/* <Bits.Currency name='price'>{values.billedAmount}</Bits.Currency> */}
                                </Block>
                                <Block  name='ordered-items'>
                                    <Bits.Text type='p' name='ordered-items-details-heading'>Items</Bits.Text>
                                    {
                                        // _.map(this.state.order.orderedItems, item =>{
                                        //     return(
                                        //         <Block >
                                        //             <Bits.Text name='ordered-items-details' >
                                        //                 {item.name} - ₹ {item.price} ✕ {item.quantity} -  ₹ {item.totalAmount} 
                                        //             </Bits.Text>
                                        //         </Block>
                                        //     )
                                        // })
                                    }
                                    <Block name="items-table">
                                    <Table style={{width:"200px"}}
                                            {...this.getOrderedItemsData( Bits, Block )}
                                        />
                                    </Block>
    
                                </Block>
                                {
                                    values.hasDicount &&
                                        <Layout  name='ordered-items'>
                                            <Block>
                                                <Bits.Text type='p' name='ordered-items-details-heading'>Discounts</Bits.Text>
                                            </Block>
                                        <Layout name="coupon-layout">
                                            <Block>
                                                    <Bits.Text name="coupon-text">Coupon Applied</Bits.Text>
                                            </Block>
                                            <Block>
                                                    <Bits.Currency negative name="coupon-value">{values.discount}</Bits.Currency>
                                            </Block>
                                            </Layout>
                                        </Layout>
                                }
                                <Block name='delivery'>
                                    <Bits.Text name='delivery-details-header'>Delivery address</Bits.Text>
                                    {
                                        this.state.order.orderType === 'takeaway' ? <Bits.Text name='takeaway'>Opted for Take Away</Bits.Text> :
                                        <Block >
                                        {
                                        this.state.order.orderType !== 'takeaway'  ? 
                                        <Bits.Text name='delivery-details'>-</Bits.Text>
                                        :
                                        <Block>
                                        <Block>
                                        <Bits.Text name='delivery-details'>{`Location - ${this.state.order.deliveringAdderess.label}` }</Bits.Text>
                                    </Block>
                                    <Block>
                                        <Bits.Text name='delivery-details'>{`${this.state.order.deliveringAdderess.addressLineOne} , ${this.state.order.deliveringAdderess.addressLineTwo}, ${this.state.order.deliveringAdderess.city}, ${this.state.order.deliveringAdderess.state}, ${this.state.order.deliveringAdderess.zipCode}`}</Bits.Text>
                                    </Block>
                                    </Block>
                                        }
                                        </Block>
                                    }
                                </Block>
                                {
                                    this.state.order.orderType === 'takeaway' &&
                                        <Block name='delivery'>
                                            <Bits.Text name='delivery-details-header'>Shop Address</Bits.Text>
                                            <Block name='shop-address-details'>
                                                <Bits.Text name='shop-address'>{shop.address.join(',')}</Bits.Text>
                                                
                                                <Block>
                                                <a style={{
                                                    backgroundColor: 'white',
                                                    color: interiorDesigner.colorPallete.primary,
                                                    border: `2px ${interiorDesigner.colorPallete.primary}`,
                                                    //padding: '10px 20px',
                                                    textAlign: 'center',
                                                    textDecoration: 'none',
                                                    height: 'fit-content',
                                                    display: 'inline-block',
                                                }}
                                                
                                                target="_blank" href={shop.googleMapLink}>
                                                    <Bits.Text name='open-maps'>Open maps</Bits.Text>
                                                </a>
                                                <Block name='call-block'>
                                    <a href={"tel:+91" + shop.contact.phone} style={{textDecoration:'none'}}>
                                        <Button  style={{  height:'25px',border:interiorDesigner.colorPallete.teritary, fontSize:'15px', color:interiorDesigner.colorPallete.teritary}} 
                                            startIcon={<PhoneIcon />}
                                        >Call</Button>
                                    </a>
                                    </Block>
                                    </Block>
                                            </Block>
                                            
                                            
                                           
                                        </Block>
                                }
                                <Block name='delivery'>
                                    <Bits.Text name='delivery-details-header'>Transaction details</Bits.Text>
                                    <Bits.Text type='p' name='transaction-details'>{`Order id - ${values.orderId}`}</Bits.Text>
                                    <Bits.Text name='transaction-details'>{this.state.order.paymentMode === 'COD' ?`Payment Mode - Cash on delivery` : `Transaction id - ${this.state.order.transactionId}`}</Bits.Text>
                                </Block>
                               
                                {
                                // ( this.state.order.status !== "CANCELLED" && this.state.order.status !== "DELIVERED") &&
                                //         <Block name='cancel-block'>
                                //         <Bits.Text name='cancel-text'>Cancel your order?</Bits.Text>
                                //         <Link
                                //             component="button"
                                //             variant="body2"
                                //             onClick={this.changeStatus}
                                //             style={{color:interiorDesigner.colorPallete.primary, fontSize:15}}
                                //         >
                                //             Cancel
                                //         </Link>
                                //     </Block>
                                }

                            </Block>
                        </Layout>
                    </Layout>
                    
                </View>
                </Container>
            </MainLayout>
        )
    }

    getStylesheet = (colorPallete) => {
        return ({
            'review-layout':{
                master:{
                    display:'flex',
                    justifyContent:'center',
                    paddingBottom: 20
                }
            },
            'review-text':{
                master:{
                    margin:3,
                    color:'white',
                    fontSize:18
                }
            },
            'review-icon-block':{
                master:{
                    display:'flex',
                }
            },
            'review-text-block':{
                master:{
                    backgroundColor:colorPallete.primary,
                    width:'95%',
                    //height:40,
                    borderRadius:10,
                    display:'flex',
                    justifyContent:'space-between',
                    padding:15,
                    //gap: '0px 40px'
                }
            },
            'items-table': {
                master:{
                    maxWidth: 600,
                }
            },
            'call-block':{
                master:{
                    marginTop:20
                }
            },
            'table-inventpry-name':{
                master:{
                    position:'relative'
                }
            },
            'back-button': {
                transition: 'children-slideRight'
            },
            'order-summary-container': {
                transition: 'children-slideRight'
            },
            'coupon-layout': {
                master: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }
            },
            'coupon-text': {
                master: {
                    marginTop: 10,
                    marginBottom: 10,
                    fontSize: 16,
                    color: colorPallete.successGreen
                }
            },
            'coupon-value': {
                master: {
                    marginTop: 10,
                    marginBottom: 10,
                    fontSize: 16,
                    color: colorPallete.successGreen
                }
            },
            'cancel-text':{
                master:{
                    fontSize:12,
                    margin:'2px 4px 0px 0px',
                    color: colorPallete.subtext
                }
            },
            'open-maps':{
                master:{
                    margin:5,
                    fontSize:15,
                }
            },
            'shop-address':{
                master:{
                    fontSize:12,
                    margin:0,
                    maxWidth:150
                }
            },
            'order-details':{
                master:{
                    // backgroundColor: colorPallete.secondory
                }
            },
            'transaction-details':{
                master:{
                    fontSize:12,
                    margin:0,
                }
            },
            'delivery':{
                master:{
                    margin:'20px 30px 10px'
                }
            },
            'order-id':{
                master:{

                }
            },
            'ordered-items':{
                master:{
                    margin:'0px 30px 10px'
                }
            },
            'ordered-items-details':{
                master:{
                    fontSize:12,
                    margin:0
                }
            },
            'delivery-details-header':{
                master:{
                    color:'grey',
                    fontSize:12,
                    margin:0
                }
            },
            'delivery-details':{
                master:{
                    fontSize:12,
                    margin:0
                }
            },
            'shop-details-header':{
                master:{
                    color:'grey',
                    fontSize:12,
                    margin:0
                }
            },
            'ordered-items-details-heading':{
                master:{
                    color:'grey',
                    fontSize:12,
                    margin:0
                }   
            },
            'price':{
                master:{
                    marginTop:0
                }
            },
            'status-title':{
                master:{
                    fontSize:12,
                    color:"#D03C3C",
                    margin: '0px 0px 15px 30px',
                    fontWeight:'bold',
                }
            },
            'order-id-label':{
                master:{
                    fontSize:12,
                    margin:0
                }
            },
            'order-id-number':{
                master:{
                    fontSize:12,
                    margin:0,
                    fontWeight:'bold',
                    textAlign:'center'
                }
            },
            'status-layout':{
                master:{
                    display:'flex',
                    justifyContent:'space-between',
                    alignItems: 'flex-end',
                    margin:'0px 30px'
                }
            },
            'cancel-block':{
                master:{
                    display:'flex',
                    marginLeft:30,
                    marginBottom:20
                }
            },
            'shop-address-details':{
                master:{
                    display:'flex',
                    justifyContent:'space-between',
                }
            },
            'top-header' :{
                master:{
                    display:'flex',
                    justifyContent:'space-between',
                    margin:'30px'
                }
            },
            'header':{
                master:{
                    margin:0,
                    fontWeight: 500,
                    color:colorPallete.primary
                }
            },
            'button':{
                master:{
                    color:"#D03C3C",
                    margin:0
                }
            },
            'details-title':{
                master:{
                    margin:0,
                    fontSize:12,
                    color:"#D03C3C",
                    fontWeight:'bold'
                }
            },
            'details-header':{
                master:{
                    display:'flex',
                    justifyContent:'space-between',
                    margin:'30px 30px 0px'
                }
            },
            'takeaway':{
                master:{
                    margin:0,
                    fontSize:12
                }
            }
        })
    }

}

export default Entities.createPlace(OrderSummary, {
    name: 'OrderSummary'
});