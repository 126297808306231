import React from "react";
import Counter from "@core/modules/shared/views/Things/counter";
import Entities from "@toolkit/entities";
import tokenManager from "@utils/lib/tokenManager";
import InventoryMedia from "@core/modules/Inventory/views/Pieces/inventoryMedia";

class CartItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async getData() {
    this.setState({});
  }

  getLabelValues = (_, transformers) => {
    return {
      imageSample:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRGh5WFH8TOIfRKxUrIgJZoDCs1yvQ4hIcppw&usqp=CAU",
    };
  };
  renderSurface(values, { MainLayout, Layout, Block, Bits, View, Switch }) {
    return (
      <MainLayout name="main-layout">
        <View>
          <Layout>
            <Block name="cart-items">
              <Block name="item-name-image">
                <InventoryMedia media={this.props.item.cartItem.media} />
              </Block>
              <Block name="item-descr">
                <Block name="item-details">
                  <Bits.Text name="item-name">
                    {this.props.item.cartItem.name}
                  </Bits.Text>
                  <Block name="price">
                    <Bits.Currency
                      name="item-price"
                      value={this.props.item.totalPrice}
                    ></Bits.Currency>
                  </Block>
                  <Block name="counter">
                    {!tokenManager.isStaff() && (
                      <Counter
                        onChange={(count) =>
                          this.props.onChange(count, this.props.item.cartItem)
                        }
                        name={this.props.item.cartItem}
                        count={this.props.item.count}
                        inventory={this.props.item.cartItem}
                        variants={[]}
                      />
                    )}
                  </Block>
                </Block>
              </Block>
            </Block>
          </Layout>
        </View>
      </MainLayout>
    );
  }
  getStylesheet = (colorPallete) => {
    return {
      "cart-items": {
        master: {
          display: "flex",
          marginBottom: 10,
        },
      },
      "item-name-image": {
        master: {
          //display: 'flex',
          width: 200,
          height: 150,
        },
        mobile: {
          width: 180,
          height: 120,
        },
      },
      "item-name": {
        master: {
          color: colorPallete.black,
          fontWeight: 400,
          fontSize: 20,
          padding: "5px 0px",
          margin: 0,
        },
        mobile: {
          fontSize: 17,
        },
      },
      "item-descr": {
        master: {
          marginLeft: 40,
        },
        mobile: {
          marginLeft: 20,
        },
      },
      "item-descr": {
        mobile: {
          minWidth: 200,
          paddingLeft: 20,
        },
      },
      "main-layout": {
        master: {
          marginBottom: 20,
        },
      },
      price: {
        master: {
          display: "flex",
          alignItems: "center",
          padding: "5px 0px",
        },
      },
      counter: {
        master: {
          padding: "20px 0px",
        },
        mobile: {
          padding: 0,
        },
      },
      "item-currency": {
        master: {
          margin: 0,
        },
      },
      "item-price": {
        master: {
          margin: "0px 2px",
        },
      },
    };
  };
}

export default Entities.createThing(CartItem, {
  name: "CartItem",
});

// <div style={interiorDesigner.getStyles('flex-row')}>
//           <div style={interiorDesigner.getStyles('cart-card-image')}>
//             <img
//                 src={this.props.item.cartItem.image ? this.props.item.cartItem.image : values.imageSample}
//                 alt=''
//                 style={interiorDesigner.getStyles('cover')}
//             />
//           </div>
//           <div  styles={interiorDesigner.getStyles('details')} style={interiorDesigner.getStyles('margin-b-M40')}>
//               <CardContent  styles={interiorDesigner.getStyles('content')}>
//                   <Typography variant="subtitle1" style={interiorDesigner.getStyles('cart-card-title')}>
//                       {this.props.item.cartItem.name}
//                   </Typography>
//                   <div style={interiorDesigner.getStyles('flex-row-space-btw')}>
//                       <Counter onChange={(count) => this.props.onChange(count, this.props.item.cartItem)}  name={this.props.item.cartItem} count={this.props.item.count}/>
//                       <Typography variant="h5" style={interiorDesigner.getStyles('bold')}>
//                           ₹ {this.props.item.totalPrice}
//                       </Typography>
//                   </div>
//                   <p style={interiorDesigner.getStyles('small-text')}>Remove from cart</p>
//               </CardContent>
//           </div>
//           </div>
