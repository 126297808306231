import React from "react";
import _ from 'lodash';
import Entities from '@toolkit/entities';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class ShippingPolicy extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    async getData() {
        this.setState({

        })
    }

    getLabelValues = (formatter, _, transformers) => {
        return ({
        })
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch
    }, config) {

        const shopConfig = config['shop'];

        return (
            <MainLayout name="mainlayout">
                <View landing>
                    <Layout>
                        <Block name='top-bar'>
                            <ArrowBackIosIcon onClick={() => this.props.history.goBack()}/>
                            <Bits.Text name='top-bar-title'>Shipping Policy</Bits.Text>
                        </Block> 
                    </Layout>
                    <Layout>
                        <Block>
                            <Bits.Text>
                            {shopConfig.name} ("we" and "us") is the operator of ({shopConfig.webAddress}) ("Website"). By placing an order through this Website you will be agreeing to the terms below. These are provided to ensure both parties are aware of and agree upon this arrangement to mutually protect and set expectations on our service.</Bits.Text>
                        </Block>
                        <Block>
                        <Bits.Text name="content-header">1. General</Bits.Text>
                        <Bits.Text name='block-content'>
                        Subject to stock availability. We try to maintain accurate stock counts on our website but from time-to-time there may be a stock discrepancy and we will not be able to fulfill all your items at time of purchase. In this instance, we will fulfill the available products to you, and contact you about whether you would prefer to await restocking of the backordered item or if you would prefer for us to process a refund.</Bits.Text>
                        <Bits.Text name="content-header">2. Shipping Costs</Bits.Text>
                        <Bits.Text name='block-content'>Shipping costs are calculated during checkout based on weight, dimensions and destination of the items in the order. Payment for shipping will be collected with the purchase.
                        This price will be the final price for shipping cost to the customer.</Bits.Text>
                        <Bits.Text name="content-header">3. Dispatch Time</Bits.Text>
                        <Bits.Text name='block-content'>Orders are usually dispatched within 2 business days of payment of order
                        Our warehouse operates on Monday - Friday during standard business hours, except on national holidays at which time the warehouse will be closed. In these instances, we take steps to ensure shipment delays will be kept to a minimum.</Bits.Text>
                        <Bits.Text name="content-header">4. Change Of Delivery Address</Bits.Text>
                        <Bits.Text name='block-content'>For change of delivery address requests, we are able to change the address at any time before the order has been dispatched.</Bits.Text>
                        <Bits.Text name="content-header">5. Items Out Of Stock</Bits.Text>
                        <Bits.Text name='block-content'>If an item is out of stock, we will wait for the item to be available before dispatching your order. Existing items in the order will be reserved while we await this item.</Bits.Text>
                        <Bits.Text name="content-header">6. Delivery Time</Bits.Text>
                        <Bits.Text name='block-content'>After receiving your order we take minimum of one day to maximum of two days to deliver your products.</Bits.Text>
                        <Bits.Text name="content-header">7. Delivery Time Exceeded</Bits.Text>
                        <Bits.Text name='block-content'>If delivery time has exceeded the forecasted time, please contact us so that we can conduct an investigation.</Bits.Text>
                        <Bits.Text name="content-header">8. Tracking Notifications</Bits.Text>
                        <Bits.Text name='block-content'>Upon dispatch, customers will receive a notification from which they will be able to follow the progress of their shipment based on the latest updates made available by the shipping provider.
                        </Bits.Text>
                        <Bits.Text name="content-header">9. Parcels Damaged In Transit</Bits.Text>
                        <Bits.Text name='block-content'>If you find a parcel is damaged in-transit, if possible, please reject the parcel from the courier and get in touch with our customer service. If the parcel has been delivered without you being present, please contact customer service with next steps.</Bits.Text>
                        <Bits.Text name="content-header">10. Duties & Taxes</Bits.Text>
                        <Bits.Text name="block-sub-header">10.1  Sales Tax</Bits.Text>
                        <Bits.Text name='block-content'>Sales tax has already been applied to the price of the goods as displayed on the website</Bits.Text>
                        {/* <Bits.Text name="block-sub-header">10.2  Import Duties & Taxes</Bits.Text>
                        <Bits.Text name='block-content'>Import duties and taxes for international shipments will be pre-paid, without any additional fees to be paid by customer upon arrival in destination country</Bits.Text> */}
                        <Bits.Text name="content-header">11. Insurance</Bits.Text>
                        <Bits.Text name='block-content'>Parcels are insured for loss and damage up to the value as stated by the courier.</Bits.Text>
                        <Bits.Text name="block-sub-header">11.1 Process for parcel damaged in-transit</Bits.Text>
                        <Bits.Text name='block-content'>We will process a refund or replacement as soon as the courier has completed their investigation into the claim.</Bits.Text>
                        <Bits.Text name="block-sub-header">11.2 Process for parcel lost in-transit</Bits.Text>
                        <Bits.Text name='block-content'>We will process a refund or replacement as soon as the courier has conducted an investigation and deemed the parcel lost.</Bits.Text>
                        <Bits.Text name="content-header">12. Customer service</Bits.Text>
                        <Bits.Text name='block-content'>For all customer service enquiries, please email us at {shopConfig.contact.email}</Bits.Text>
                        </Block>

                    </Layout>
                </View>
            </MainLayout>
        )
    }

    getStylesheet = (colorPallete) => {
        return ({
            'block-content-last':{
                master:{
                    margin:'10px 0px',
                    fontSize: 15,
                    textAlign:'justify',
                    fontWeight:'bold',
                }
            },
            'block-content':{
                master:{
                    margin:0,
                    fontSize: 15,
                    textAlign:'justify',
                    padding:'20px'
                }
            },
            'content-header':{
                master:{
                    color:colorPallete.primary,
                    fontSize:20,
                    margin:'20px 0px 5px',
                }
            },
            'block-sub-header':{
                master:{
                    color:colorPallete.primary,
                    fontSize:18,
                    margin:'20px 0px 5px',
                }
            },
            'top-bar':{
                master:{
                    display:'flex',
                    marginBottom:30
                }
            },
            'mainlayout':{
                master:{
                    maxWidth:900,
                    margin:'20px auto',
                    padding: '0px 20px',
                    // margin:'30px 30px'
                }
            },
            'top-bar-title':{
                master:{
                    margin:'0px 10px',
                    fontSize:22,
                }
            },
        })
    }
}

export default Entities.createPlace(ShippingPolicy, {
    name: 'ShippingPolicy'
});
