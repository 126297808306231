import React, { Component } from 'react';
import _ from 'lodash';
import Entities from '@toolkit/entities';
import endpoints from '@api';
import Form from "@web-bricks/Shared/form/components/form";
import FormMaker from '@web-bricks/Shared/form/components/formMaker';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Container from "@core/modules/shared/views/Things/container.js";
import EditIcon from '@material-ui/icons/Edit';
import Helper from '@core/helpers';
import Button from '@web-bricks/Shared/Input/button';


class TemplateList extends Component {
    constructor(props) {
      super(props)
      this.state = {
       
      }
      this.templateHelper = new Helper.TemplateHelper()
    }

    async getData() { 
        const templates = await endpoints.Templates.find({query:{}})
        this.setState({
            templates: templates,
        })
    };

    handleClick = (event, cellValue) =>{
        this.props.history.push(`/vendor/template/${cellValue.row.id}`)
    }

    getHeaders = () => {
        const columns = _.concat(
            [
                {
                    field: 'subject',
                    headerName: 'Subject',
                    type: "string",
                    width: 200
                },
                {
                    field: 'body',
                    headerName: 'Body',
                    type: "string",
                    width: 400
                },
                {
                    field: 'type',
                    headerName: 'Type',
                    type: "string",
                    width: 150
                },
                {
                    field: "Actions",
                    width: 140,
                    renderCell: (cellValues) => {
                      return (
                        <EditIcon  
                            onClick={(event) => {
                                this.handleClick(event, cellValues);
                            }}
                        />

                      );
                    }   
                }
            ],
        );
        return columns
    };

    getRows = (templates) => {
        const rows = _.map((templates), (template) => ({
            id: template._id,
            subject : template.subject,
            body : template.body,
            type: template.type,
            
        }))
        return rows
    }

    onSelectionModelChange = (selectedUsers) => {
        this.setState({
            selectedUsers: selectedUsers
        })
    }
    
  renderSurface(values, {
    MainLayout,
    Layout,
    Block,
    View,
    Bits
  }) {
    return (
        <MainLayout>
             <Container 
                    page={{ 
                        title: 'All Templates',
                        secondaryText: 'Create and save templates to send notifications'
                    }}
                    previousPage={[{title:'Drafts', onClick: () => this.props.history.push('/vendor/customerengagement')}]}
                    activePage='Templates'
                    rightButton={ this.state.templates.length > 0 && { buttonLabel: 'Add Templates', onClick: () => this.goToView('/vendor/template'), startIcon:<AddCircleIcon /> }}
                >    
            <View>
                {
                     this.state.templates.length > 0 ?
                     <Layout>
                    <Block>
                        <Form>
                            <FormMaker
                                fields={[
                                    {
                                        type: "DataTable",
                                        props: {
                                            name: 'userList',
                                            columns: this.getHeaders(),
                                            handleSelectionModelChange: this.onSelectionModelChange,
                                            rows: this.getRows(this.state.templates),
                                            //checkboxSelection: true
                                        }
                                    }
                                ]}
                            />
                        </Form>
                    </Block>
                </Layout> :
                 <Layout>
                 <Block>
                     <Bits.Text name='info-text'>Create a new template to get started</Bits.Text>
                     <Block name='center-block'>
                         <Button onClick={() => this.goToView('/vendor/template')} variant ='contained' color='primary'>create template</Button>
                     </Block>
                 </Block>
             </Layout>

                }
            </View>
            </Container>
        </MainLayout>
    );
  }
  getStylesheet = (colorPallete) => {
    return({
        'info-text':{
            master:{
                color:colorPallete.subtext,
                //fontWeight: 400,
                fontSize:18,
                display: 'flex',
                justifyContent:'center'
            }
        },
        'center-block':{
            master:{
                display: 'flex',
                justifyContent:'center'
            }
        }
    })
}
}

export default Entities.createPlace(TemplateList, {
    name: 'TemplateList'
});
