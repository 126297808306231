import React from 'react';
import Form from '@web-bricks/Shared/form/components/form';
import FormMaker from '@web-bricks/Shared/form/components/formMaker'
import Helper from '@core/helpers';
import toolkit from '@toolkit';
import tokenManager from  '@utils/lib/tokenManager';
import transitionRegistry from '@toolkit/lib/transitionRegistry';
import persistentStorage from '@utils/lib/localStorage';
import Container from '@core/modules/shared/views/Things/container.js';
import Link from '@material-ui/core/Link';
import _ from 'lodash'
class UserDetails extends React.Component {

  constructor(props) {
    super(props);
    const phoneNumber = persistentStorage.get("newUserPhoneNumber");
    this.state = {
      phoneNumber : phoneNumber,
      showReferalBlock : false,
      role : persistentStorage.get("userDetails").role,
      id: props.match.params.id
    }
    this.signinHelper = new Helper.UserSigninHelper()
    this.userHelper = new Helper.UserHelper()
    tokenManager.user && this.props.history.push('/')
  }

  getLabelValues = ( _, transformers) => {
    return ({
      containerTitle : 'Welcome, please provide your information to signup',
      previousPage :'Sign up',
      activePage:'Step 1'
    })
}

onShowRefferal = () =>{
  this.setState({
    showReferalBlock : true
  })
}

getFields = (Block, Bits) =>{
  const fields = [
    {
        type: "textField",
        //title: "Full name",
        props: {
            name: "fullName" ,
            label: "Full Name",
            required : true
        }
    },
    {
        type: "textField",
        //title: "Phone",
        props: {
            name: "phone" ,
            label: "Phone",
            required : true,
            defaultValue : persistentStorage.get('phoneNumber'),
            InputProps:{
              startAdornment: "+91",
              maxLength : 10,
            },
        }
    },
    {
      type: "textField",
      props: {
          name: "email" ,
          label: "Email",
          //required : true
      }
    },
    this.state.showReferalBlock ? {
      type: "textField",
      //title: 'Refferrer Code'
      props: {
        name: "referrerCode",
        label: "Referral Code",
        required: false,
      },
    } :
    {
      type: 'Block',
      props: {
        render: () => {
          return (
            <Link onClick={this.onShowRefferal}>Have a referral code ?</Link>
          )
        }
      }
    }
  ]
  return fields
}

onSubmit = async (values) =>{
  if(this.state.role === 'CUSTOMER'){
    await this.props.signinHelper.updateUser(values)
    this.onNextRoute()
  }else if(_.isNil(this.state.role)){
    let data ={
      ...values, 
      registrationStatus:'COMPLETED',
      registrationCompletedAt: Date().toLocaleString(),
      role :'CUSTOMER'
    }
    await this.props.signinHelper.updateUser(data)
    this.onNextRoute()
    //this.userHelper.setUser(user)
  }else{
    //this.userHelper.updateRegistrationCompleted(persistentStorage.get('userDetails')._id)
    await this.props.signinHelper.updateUser({...values, registrationStatus:'COMPLETED', registrationCompletedAt: Date().toLocaleString()})
    this.props.signinHelper.removeUserDetails()
    this.props.history.push('/vendor/dashboard')
  }
}

  onNextRoute = () =>{
    if(!_.isEmpty(this.props.location.search)){
      this.props.history.push(`/createUser/address${this.props.location.search}`)
    }else{
      this.props.history.push(`/createUser/address?nextRoute=/`)
    }
  }

  renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch,
        interiorDesigner
    }) {
    return (
      <MainLayout>
        <Container
          page={{ title:values.containerTitle}}
          previousPage={[{title:values.previousPage}]}
          activePage={values.activePage}
        >
          <View>
            <Block name='details-block'>
              <Form submitButton={{ label: 'continue', variant: "contained" ,color: "primary", fullWidth: true, className: "add-submit-button" }}  noValidate onSubmit={this.onSubmit}>
                <FormMaker fields={this.getFields(Block, Bits)} Transition={transitionRegistry.slideDown} />
              </Form>
            </Block>
          </View>
        </Container>
      </MainLayout>
    );
  }

  getStylesheet = (colorPallet) => {
    return ({
      'details-block':{
        master:{
          margin:'auto',
          maxWidth:550
        }
      }
    })
  }
  
}

export default  toolkit.Entities.createPlace(UserDetails, {
  name: 'UserDetails'
});
