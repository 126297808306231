import React from 'react';
import createBit from '../lib/createBit';

const format = (x) => {
    x = (x || 0).toString()
    var afterPoint = '';
    if(x.indexOf('.') > 0)
       afterPoint = x.substring(x.indexOf('.'),x.length);
    x = Math.floor(x);
    x=x.toString();
    var lastThree = x.substring(x.length-3);
    var otherNumbers = x.substring(0,x.length-3);
    if(otherNumbers != '')
        lastThree = ',' + lastThree;
    var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
    return res;
}


export default createBit(props => <span style={props.style}>{props.negative ? '- ' : ''}&#8377;{format(props.value || props.children)}</span>, {
    name: 'currency'
});