import ImageSlider from "@web-bricks/Shared/Image/imageSlider";
import ProductSlider from "@core/modules/shared/views/Things/productSlider";
import InventoryListByCategory from "@core/modules/Inventory/views/Things/inventoryListByCategory"
import SearchBar from './Search/searchbar';
import { Search } from "@material-ui/icons";
import Message from "./primaryshelf/message";
import MessageWithImage from "./primaryshelf/messageWithImage";
import ImageMagnifier from "./Image/imageMagnifier";
import InventoryImage from "./Image/inventoryImage";
import InfoImage from "./Info/infoImage";
import BackgroundVideo from "./primaryshelf/bgVideo";

const widgetRegistry = {
    Magnifier: ImageMagnifier,
    InventoryImage: InventoryImage,
    SearchBar: SearchBar,
    SearchIcon: Search,
    ImageSlider: ImageSlider,
    ProductSliderForPrimaryShelf: ProductSlider,
    InventoryListByCategory : InventoryListByCategory,
    MessageBanner: Message,
    StoryBanner: MessageWithImage,
    StoryVideo: BackgroundVideo,
    InfoImage: InfoImage
}


export default widgetRegistry;