import React from "react";
import _ from "lodash";
import api from "@api";
import Entities from "@toolkit/entities";
import Helper from "@core/helpers";
import interiorDesigner from "@toolkit/lib/interiorDesigner";
import persistentStorage from "@utils/lib/localStorage";
import tokenManager from "@utils/lib/tokenManager";
import DialogBox from "@core/modules/shared/views/Things/dialogBox";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { RadioGroupForm } from "@web-bricks/Shared/Input/radio";
import FabCart from "@core/modules/Cart/views/lib/cart";
import delivery from "@core/assests/images/delivery.png";
import Config from "@config";
import Drawer from "@web-bricks/Shared/view/components/drawer";
import ItemModal from "@core/modules/Inventory/views/Things/itemModal";

class SimpleNav extends React.PureComponent {
  constructor(props) {
    super(props);
    this.shopHelper = new Helper.ShopHelper();
    this.helper = new Helper.UserSigninHelper();
    this.userHelper = new Helper.UserHelper();
    this.categoryHelper = new Helper.CategoryHelper();
    this.state = {
      showDailogFlag: false,
      cartOrderType: _.get(Helper.CartHelper.getCart(), "orderType"),
      selectedDeliveryZone: persistentStorage.get("selectedDeliveryZone"),
      showItemModal: false,
    };
    this.renderSelectAddress = this.renderSelectAddress.bind(this);
    this.fetchData();
  }

  async fetchData() {
    let categoriesList = await this.categoryHelper.getCategories();
    this.setState({
      categoriesList,
    });
    if (tokenManager.user && tokenManager.type !== "staff") {
      this.user = await this.helper.getUserById(tokenManager.user);
      this.setState({
        locationList: [],
      });
    }
  }

  onDailogOpen = () => {
    this.setState({
      showDailogFlag: true,
    });
  };

  onDailogClose = () => {
    this.setState({
      showDailogFlag: false,
    });
  };

  setTakeAway = () => {
    Helper.CartHelper.updateOrderType("takeaway");
    this.setState({
      showDailogFlag: false,
      cartOrderType: _.get(Helper.CartHelper.getCart(), "orderType"),
    });
  };

  onAddAddress = () => {
    this.props.history.push("/addAddress");
  };

  // onChange = (value) => {
  //   console.log(this.props);
  //   const inventories = this.props.onSearchInventory(value);

  //   console.log(inventories);

  //  };

  onChange = async (value) => {
    let searchedInventories = await api.Inventories.search(value);
    //this.updateSearchInventories( searchedInventories, value)
    return searchedInventories;
  };

  updateSearchInventories = (searchedInventories, value) => {
    // ----> searchedInventories = _.groupBy(searchedInventories, "label")
    this.setState({
      searchedInventories: searchedInventories,
      value: value,
    });
  };

  onSelectedInventory = (e, inventory) => {
    console.log(inventory);
    this.props.history.push(`/product/${inventory.id}`);
    // this.setState({
    //   selectedInventory : inventory,
    //   showItemModal : true
    // })
  };

  onAddressSubmit = async (values) => {
    let location = {
      value: values,
    };
    await Helper.CartHelper.selectAddress(location, this.user);
    this.setState({
      showDailogFlag: false,
      selectedDeliveryZone: persistentStorage.get("selectedDeliveryZone"),
    });
  };

  getNavlinks = ({ config }) => {
    const navlinks = this.props.navlinks || [{
      label: 'Home',
      to: '/'
    }, {
      label: `About Shop`,
      to: '/aboutUs'
    }, {
      label: 'Contact',
      to: '/contactUs'
    }]

    return navlinks;
  }

  renderSelectAddress(Block, Bits) {
    const self = this;
    return function () {
      return (
        <Block>
          <Block
            style={{ display: "flex", justifyContent: "center" }}
            className="delivery-image-align"
          >
            <img style={{ width: "120px" }} src={delivery} />
          </Block>
          <List>
            <ListItem button onClick={self.setTakeAway}>
              <ListItemText primary="Take away" />
            </ListItem>
            <Divider />
            <ListItem button onClick={self.onAddAddress}>
              <ListItemText primary="Add Address" />
            </ListItem>
            <Divider />
            <ListItem>
              <RadioGroupForm
                name="deliveryAddress"
                list={self.state.locationList}
                onChange={self.onAddressSubmit}
                required
              />
            </ListItem>
          </List>
        </Block>
      );
    };
  }

  getLabelValues = (formatter, _, transformers) => {
    return {};
  };
  goToHome = () => {
    this.goToView("/");
  };

  getLabelValues = (formatter, _, transformers) => {
    return {};
  };

  renderSurface(
    values,
    { interiorDesigner, MainLayout, Layout, Block, Bits, View, Switch },
    config
  ) {
    return (
      <MainLayout>
        {_.isNull(this.state.selectedDeliveryZone) &&
          !tokenManager.isStaff() &&
          tokenManager.user &&
          this.state.cartOrderType !== "takeaway" && (
            <Block name="banner" onClick={this.onDailogOpen}>
              <Bits.Text name="banner-text">
                Delivery services are not available for the selected address.
                Try opting for takeaway or other address to continue shopping.
              </Bits.Text>
            </Block>
          )}
        <Layout name="top-bar-container">
          <Layout name="top-bar-outer">
            <Layout name="top-bar-search">
              <Block name="back-container">
                {!interiorDesigner.isMobile() && !this.props.hideBackButton && (
                  <Bits.Icon
                    name="back-icon"
                    onClick={() => this.props.history.goBack()}
                  >
                    ArrowBack
                  </Bits.Icon>
                )}
              </Block>
              <Layout name="top-bar" onClick={this.goToHome}>
                <Block name="logo">
                  <Bits.Image
                    name="logo-img"
                    src={config.shop.compactLogo}
                    noLazy
                  />
                </Block>
                <Layout name="header">
                  {config.shop.logoText ? (
                    <Block name="logo-text-block">
                      <Bits.Image
                        name="logo-text"
                        noLazy
                        src={config.shop.logoText}
                      ></Bits.Image>
                    </Block>
                  ) : (
                    <Block>
                      <Bits.Text name="shop-title">
                        {config.shop.name}
                      </Bits.Text>
                      {config.shop.description && (
                        <Bits.Text name="shop-caption">
                          {config.shop.description}
                        </Bits.Text>
                      )}
                    </Block>
                  )}
                </Layout>
                <Layout name="nav-links">
                  {
                    _.map(this.getNavlinks({ config }), (link, index) => {
                      return <Block name="nav-link" key={index} >
                        <Bits.Link to={link.to}>{link.label}</Bits.Link>
                      </Block>
                    })
                  }
                </Layout>
              </Layout>
              <Block name="nav-search">
                {this.placeWidget({
                  defaultWidget: "SearchBar",
                  props: {
                    placeholder: 'Search for menu, items & more..',
                    onClick: () => this.props.history.push("/search"),
                    onSearchInventory: this.onChange,
                    onSelectedOption: this.onSelectedInventory,
                  },
                })}
              </Block>
              <Layout name="nav-icons">
                {!tokenManager.isStaff() && (
                  <Block name="icon-block">
                    <Bits.IconButton
                    label="aa"
                    >
                      <FabCart
                        name="icon"
                        pushToCart={() => this.props.history.push("/cart")}
                      />
                    </Bits.IconButton>
                  </Block>
                )}
                {!tokenManager.isStaff() ? (
                  <Bits.IconButton
                    name="icon"
                    onClick={() => {
                      this.props.history.push("/profile");
                    }}
                  >
                    <Bits.Icon name="cart-basket-icon-link">Person</Bits.Icon>
                    <Bits.Text name="nav-icon-text">Profile</Bits.Text>
                  </Bits.IconButton>
                ) : (
                  <Bits.IconButton
                    name="icon"
                    onClick={() => {
                      this.props.location.pathname === "/"
                        ? this.props.history.push("/vendor/dashboard")
                        : this.props.history.push("/");
                    }}
                  >
                    {this.props.location.pathname === "/" ? (
                      <Bits.Icon name="cart-basket-icon-link">
                        Dashboard
                      </Bits.Icon>
                      
                    ) : (
                      <Bits.Icon name="cart-basket-icon-link">
                        Storefront
                      </Bits.Icon>
                    )}
                    {
                      this.props.location.pathname === "/" ? 
                        <Bits.Text name="nav-icon-text">Dashboard</Bits.Text>
                       : 
                        <Bits.Text name="nav-icon-text">Store</Bits.Text>
                    }
                  </Bits.IconButton>
                )}
              </Layout>
            </Layout>
            {!interiorDesigner.isMobile() && config.shop.showMenuInNav && (
              <Block name="menu-list-nav-block">
                <Block name="menu-list-nav">
                  {_.map(this.state.categoriesList, (op) => {
                    return (
                      <Bits.Text
                        name="menu-list-nav-text"
                        onClick={() =>
                          this.props.history.push(`/shop/${op._id}`)
                        }
                      >
                        {_.capitalize(op.name).split("_").join(" ")}
                      </Bits.Text>
                    );
                  })}
                </Block>
              </Block>
            )}
            {this.state.showItemModal && (
              <ItemModal
                open={this.state.showItemModal}
                onBack={this.onClose}
                cart={this.props.cart}
                cartHelper={Helper.CartHelper}
                onClick={this.imageClick}
                onCountChange={this.cartUpdate}
                //onStackEmpty={this.onClose}
                group={this.state.group}
                groupedinventories={this.state.groupedInventories}
              />
            )}
            {this.state.showDailogFlag && (
              <DialogBox
                handleClickOpen={this.onDailogOpen}
                handleClose={this.onDailogClose}
                open={this.state.showDailogFlag}
                dialogTitle="Select Address"
                renderItem={this.renderSelectAddress(Block, Bits)}
                subText="Let us check if your location is within our delivery zones."
                heading="Delivery Zone"
              />
            )}
          </Layout>
        </Layout>
      </MainLayout>
    );
  }
  getStylesheet = (colorPallete, config) => {
    return {
      "nav-links": {
        master: {
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          display: 'inherit'
        },
        mobile: {
          display: 'none'
        }
      },
      'nav-link': {
        master: {
          margin: '10px 20px 10px 20px',
        }
      },
      "menu-list-nav-block": {
        master: {
          borderBottomStyle: "inset",
        },
      },
      "menu-list-nav-text": {
        master: {
          margin: "0px 0px 5px 17px",
          color: colorPallete.primary,
          cursor: "pointer",
          fontSize: 14,
          //textDecoration:'underline'
          fontWeight: "bold",
        },
      },
      "menu-list-nav": {
        master: {
          display: "flex",
          justifyContent: "center",
        },
      },
      "logo-text-block": {
        master: {
          width: 116,
          height: 48,
          marginLeft: 10,
        },
      },
      "logo-text": {
        master: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
      banner: {
        master: {
          backgroundColor: colorPallete.secondary,
          width: "100%",
        },
      },
      "banner-text": {
        master: {
          backgroundColor: colorPallete.primary,
          color: colorPallete.secondary,
          margin: 0,
          padding: "7px 0px",
          textAlign: "center",
          fontSize: 12,
          lineHeight: 1.5,
          letterSpacing: "1px",
          fontWeight: 100,
        },
      },
      "icon-block": {
        master: {
          margin: "0px 30px",
        },
        mobile: {
          margin: "0px 10px",
        },
      },
      "search-icon-link": {
        master: {
          color: colorPallete.primary,
        },
      },
      "top-bar-search": {
        master: {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        },
      },
      "top-bar-container": {
        master: {
          height: 100,
          position: 'relative',
          zIndex: 2,
        },
        mobile: {
          height: 70,
        },
      },
      "top-bar-outer": {
        master: {
          zIndex: 100,
          borderBottom: "1px solid #C8C8C8",
          backgroundColor: colorPallete.bg,
          position: Config.isTestRunning ? "relative" : "fixed",
          width: "100%",
          top: 0,
          left: 0,
        },
        mobile: {
          height: 70,
        },
      },
      "back-container": {
        master: {
          cursor: "pointer",
          marginLeft: tokenManager.type === "staff" ? 280 : 50,
          opacity: this.props.hideBackButton ? 0 : 1,
        },
        mobile: {
          margin: "0px 0px 0px 20px",
          display: this.props.hideBackButton ? "none" : "block",
        },
      },
      "back-icon": {
        master: {
          color: interiorDesigner.colorPallete.primary,
          // width: '1.7rem',
          // height: '1.7rem',
          border: `2px solid ${colorPallete.primary}`,
          borderRadius: "50%",
          padding: 4,
        },
      },
      "top-bar": {
        master: {
          padding: 10,
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
        },
        mobile: {
          padding: "5px 20px",
          display: !this.props.hideBackButton ? "none" : "flex",
          marginLeft: tokenManager.type === "staff" ? 40 : "unset",
        },
      },
      "nav-icons": {
        master: {
          padding: "0px 30px",
          display: "flex",
          alignItems: "center",
          height: 100,
          backgroundColor: colorPallete.primary,
        },
        mobile: {
          height: 70,
          padding: "0px 20px",
        },
      },
      logo: {
        master: {
          width: 80,
          // height:100,
          height: 80,
        },
        mobile: {
          width: 60,
          height: 60,
          marginLeft: "-6px",
        },
      },
      "logo-img": {
        master: {
          width: "100%",
          height: "100%",
          objectFit: "contain",
        },
      },
      "shop-title": {
        master: {
          fontSize: 26,
          fontWeight: 500,
          margin: 0,
          color: colorPallete.primary,
        },
        mobile: {
          fontSize: 17,
        },
      },
      "shop-caption": {
        master: {
          margin: 0,
          fontSize: 12,
          color: "grey",
        },
        mobile: {
          fontSize: 10,
        },
      },
      header: {
        master: {},
        mobile: {},
      },
    };
  };
}

export default Entities.createPlace(SimpleNav, {
  name: "SimpleNav",
});
