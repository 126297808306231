import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import Button from '../../../Input/button';
// import Modal from '../modal';
// import ImageUploader from '../../../Input/imageUploader';
// const defaultImage = require('../../../../../../assets/user/default-user.jpg');
import AvatarProfile from '@material-ui/core/Avatar';

const getInitials = (name) => {
    const chunks = _.split(name, ' ');
        return _.slice(name, 0, 2);
};


class Avatar extends React.Component {
    constructor(props) {
    super(props);
        this.state = {
            edit: props.edit,
        };
        this.avatarUpload = React.createRef();
    }

    toggleEdit = (state) => () => {
        this.setState({
            edit: state,
        });
    }


    // onUpdate = async (values) => {
    //     const images = await this.avatarUpload.current.upload();
    //     if (_.includes(images[0], 'https://')) {
    //         const user = await api.User.update(tokenManager.user, {
    //             profile : { 
    //                 avatar: images[0],
    //             }
    //         });
    //         tokenManager.init({ name: user.name, avatar: user.profile.avatar });
    //         this.props.onUpdate(user);
    //     }
    //     this.toggleEdit(false)();
    // }

    render() {
        return (
            <div
                className={classNames('avatar__container', this.props.className, { edit: this.props.edit })}
                onClick={this.props.onClick}
                ref={this.props.getRef}
            >
                <div className="avatar__inner">
                    {
                        this.props.user && this.props.user.avatar ?
                            <div
                                className="avatar__pic"
                                style={{ backgroundImage: `url('${this.props.user.avatar}')` }}
                            /> :
                            <div>
                                <AvatarProfile style={{background:'orange', margin: '0 auto', width: this.props.size === 'medium' ? 40 :64,height:this.props.size === 'medium' ? 40:64, fontSize:this.props.size === 'medium' ? 20:28}}>{getInitials(_.upperCase(this.props.user.name))}</AvatarProfile>
                            </div>
                    }
                </div>
                {
                    this.props.editable && 
                        <div className="avatar__edit">
                            <Button className="rounded" variant="fab" color="secondary" aria-label="Edit" onClick={this.toggleEdit(true)}>
                                <i class="fas fa-pencil-alt edit__icon pointer" onClick={this.onFocus}></i>
                            </Button>
                        </div>
                }
                
            </div>
        );
    }
}

Avatar.defaultProps = {
    onClick: _.noop,
    onUpload: _.noop,
    getRef: _.noop,
    editable: false,
};
            
export default Avatar;


// <Modal
//                     open={this.state.edit}
//                     onClose={this.toggleEdit(false)}
//                     okButton={{
//                         label: 'Save',
//                         onClick: this.onUpdate,
//                     }}
//                     cancelButton={{
//                         label: 'Cancel',
//                         onClick: this.toggleEdit(false),
//                     }}
//                 >
//                     <div className="avatar__edit__modal">
//                         <ImageUploader.input
//                             label="Upload picture"
//                             assetOf="avatar"
//                             innerRef={this.avatarUpload}
//                             value={[_.get(this.props.user, 'avatar', defaultImage)]}
//                             isMultiple={false}
//                         />
//                     </div>
//                 </Modal>
