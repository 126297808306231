import React from 'react';
import _ from 'lodash';
import { TextField, InputAdornment } from '@material-ui/core';
import { withFormsy } from 'formsy-react';
import { SocialLinks } from 'social-links';
import { Facebook, Twitter, LinkedIn, Instagram, YouTube } from '@material-ui/icons'
const socialLinks = new SocialLinks();

class SocialMediaLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
        this.props.setValue(props.defaultValue);
    }

    onChange = (e) => {

        const isValid = socialLinks.isValid(_.toLower(this.props.medium), e.target.value);
        if (isValid) {
            const res = socialLinks.getProfileId(_.toLower(this.props.medium), e.target.value)
            this.setState({
                errorMessage: '',
                errorValue: null
            });
            this.props.setValue(res)
        } else {
            this.setState({
                errorMessage: 'Not a valid input.',
                errorValue: e.target.value
            })
            this.props.setValue(null)
        }
    }

    getValue = () => this.state.value

    render() {
        const value = this.getValue();
        return (
            <div>
                <TextField
                    fullWidth
                    {...this.props}
                    onChange={this.onChange}
                    value={this.state.errorValue || this.getValue()}
                    error={!!this.state.errorMessage}
                    helperText={this.state.errorMessage}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {socialMediaIconMap[this.props.medium]}
                            </InputAdornment>
                        )
                    }}
                >
                </TextField>
                {
                    value && 
                        <a className="color-blue" target="_blank" href={this.props.getPreviewLink(value)}>Preview link</a>
                }
            </div>
        )
    }
}

export default withFormsy(SocialMediaLink);

const socialMediaIconMap = {
    Facebook: <Facebook />,
    LinkedIn: <LinkedIn />,
    Instagram: <Instagram />,
    YouTube: <YouTube />
}
