import React from 'react';
import View from './View';
import Text from './Text';
import Image from './Image';
import Currency from './Currency';
import { Divider, Button, IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import BulletList from './bulletList'
import Link from './Link';
import createBit from '../lib/createBit';
import BottomNavigation from '@web-bricks/Shared/view/components/bottomNav';
import {
    Facebook,
    Instagram,
    Twitter,
    YouTube,
    Dashboard,
    Person,
    Phone,
    Mail,
    Sms,
    AccountTreeOutlined,
    Add,
    ArrowRightAlt,
    ShoppingBasket, Close, ArrowBack, Search, WhatsApp, ArrowForwardTwoTone, Storefront
} from '@material-ui/icons';

// temp map for icons - to be replaced with dynamic imports

const IconMap = {
    ShoppingBasket,
    Dashboard,
    Close,
    ArrowBack,
    Search,
    WhatsApp,
    ArrowForwardTwoTone,
    Person,
    Phone,
    Mail,
    AccountTreeOutlined,
    Add,
    Sms,
    Storefront,
    Facebook,
    Instagram,
    Twitter,
    YouTube,
    ArrowRightAlt
};
const IconBit = createBit((function (props) {

    const Icon = IconMap[props.children]
    if (Icon) {

        return React.createElement(Icon, props);
    } else {
        throw(`${props.children} Icon is unavailable in Icon Collection. Add this new entry to IconMap in IconBit Component`)
    }

}), { name: 'Icon' });

const IconButtonBit = createBit(IconButton, { name: 'IconButton' });

const ButtonBit = createBit(Button, { name: 'Button' });
const BottomNav = createBit(BottomNavigation, { name: 'BottomNav' })
const SkeletonBit = createBit(Skeleton, { name: 'Skeleton' });
const LinkBit = createBit(Link, { name: 'Link' });


export {
    View,
    Text,
    LinkBit as Link,
    Image,
    Divider,
    Currency,
    BulletList,
    IconBit as Icon,
    ButtonBit as Button,
    IconButtonBit as IconButton,
    BottomNav,
    SkeletonBit as Skeleton,
}