// import React from 'react';


// const Wrap = (path, props) => {

//     const [ Comp, setCompFetched ] = React.useState(null);
//     React.useEffect(async () => {
//         const T = await import(path);
//         setCompFetched(T.default)
//     });
//     console.log(props)
//     return Comp ? <Comp key={Comp} {...props} /> : <CircularProgress key={Comp} />;
// }
// const Prefetcher = (path) => (...args) => Wrap(path, ...args)

// export default Prefetcher;

import React from 'react';
import Loadable from 'react-loadable';

import { CircularProgress } from "@material-ui/core";


const LoadableComponent = Loadable({
  loader: () => import('@core/modules/Vendor/views/Places/index'),
  loading: () => <CircularProgress style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}} />,
});

LoadableComponent.preload()

export default LoadableComponent;