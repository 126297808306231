import React from 'react';
import Entities from '@toolkit/entities';
import Caption from './caption';

class Message extends React.PureComponent {
    constructor(props) {
        super(props)
    }
    renderSurface(values, { MainLayout, Layout, Block, Bits, View }) {
        return (
            <Layout name="message-container">
                <Block name="message-block">
                    <Bits.Text name="message-line">{this.props.storyLine}</Bits.Text>
                    <Caption caption={this.props.caption}/>
                </Block>
            </Layout>
            
    
        )
    }

    getStylesheet = (colorPallete) => {
        return {
            'message-block': {
                master: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                },
            },
            'message-line': {
                master: {
                    fontSize: 54,
                    fontWeight: 'bold',
                    textAlign: 'center'
                }
            }
        }
    }
}

export default Entities.createPlace(Message, {
    name: "Message",
  });
