import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import MuiDrawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import { withRouter } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import interiorDesigner from "@toolkit/lib/interiorDesigner";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    position: "absolute",
    left: 23,
    top: 10,
    zIndex: 1000,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: {
    minHeight: 100,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: "0px 24px",
  },
  list: {
    width: "100%",
  },
}));

function Drawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  //const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    //setMobileOpen(!mobileOpen);
    setOpen(!open)
  };

  const getList = (list, styles) => {
    return (
      <div
        className={props.className}
        style={{
          ...styles,
        }}
      >
        <div className="drawer-align">
          {_.map(list, (category) => {
            return (
              <List className={classes.list} style={styles}>
                {_.map(
                  _.compact(category.options),
                  (option) =>
                    option && (
                      <ListItem
                        button
                        key={option.label}
                        onClick={
                          option.onClick ||
                          (() => props.history.push(option.path))
                        }
                        style={{
                          backgroundColor: _.isEqual(
                            props.location.pathname,
                            option.path
                          )
                            ? interiorDesigner.colorPallete.bg
                            : props.backgroundColor,
                        }}
                      >
                        {
                          option.Icon &&
                          <ListItemIcon>
                          <option.Icon
                            style={{
                              color: _.isEqual(
                                props.location.pathname,
                                option.path
                              )
                                ? interiorDesigner.colorPallete.primary
                                : interiorDesigner.colorPallete.bg,
                            }}
                          />
                        </ListItemIcon>
                        }
                        <ListItemText
                          primary={option.label}
                          style={{
                            color: _.isEqual(
                              props.location.pathname,
                              option.path
                            )
                              ? interiorDesigner.colorPallete.primary
                              : interiorDesigner.colorPallete.bg,
                          }}
                        />
                      </ListItem>
                    )
                )}
              </List>
            );
          })}
        </div>
      </div>
    );
  };

  const topList = getList(props.topList, {
    flex: 1,
  });
  const bottomList = getList(props.bottomList, {});
  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <MuiDrawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={open}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {/* {drawer} */}

            {topList}
            {bottomList}
          </MuiDrawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <MuiDrawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {/* {drawer} */}
            {topList}
            {bottomList}
          </MuiDrawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {props.children}
        <div className={classes.toolbar}></div>
      </main>
    </div>
  );
}

Drawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withRouter(Drawer);
