import React from 'react';
import _ from 'lodash';
import  { withFormsy } from 'formsy-react';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import customFormHoc from './customFormHoc';

require('../../styles/main.scss');

class RadioGroupForm extends React.Component {

    constructor(props) {
        super(props);
        this.props.setValue(props.defaultValue);
    }

    onChange = (e) => {
        this.props.setValue(e.target.value)
        this.props.onChange(e, e.target.value);
    }

    render() {
        return (
            <RadioGroup
                key={this.props.value}
                value={this.props.value || this.props.defaultValue}
                name={this.props.name}
                onChange={this.onChange}
                required={this.props.required}
            >
            <div className={this.props.displayInRow ? (this.props.displayInRowClass || 'flex-row') : 'flex-column'}>
                {
                    _.map(this.props.list, (item, index) => {
                        return (
                            <div>
                                <FormControlLabel
                                    disabled={item.disabled}
                                    name={this.props.name}
                                    key={index}
                                    value={item.value || item.label}
                                    control={<Radio />}
                                    label={_.capitalize(_.upperCase(item.label))}
                                    checked={item.checked}
                                    color='white'
                                    //style={{color: item.checked?'white':'black', margin:10}}
                                />
                                { item.caption && <p >{item.caption}</p> }
                                { item.secondaryText && <p style={{color: 'grey',fontSize: 15,paddingLeft: 31, marginTop:0}}>{item.secondaryText}</p>}
                            </div>
                        )
                    })
                }
                
            </div>   
            </RadioGroup>
        )
    }

}

RadioGroupForm.defaultProps = {
    onChange: _.noop,
}

const nonFormsyHOC = customFormHoc(RadioGroupForm);

export { nonFormsyHOC as RadioGroupForm };
export default withFormsy(RadioGroupForm);
