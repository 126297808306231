import _ from 'lodash'
import config from '@config';
import transitionRegistry from './transitionRegistry'

const colors = {
    'blue-30': '#46B2E1',
    'orange-50': '#F49E3F',
    'lightgreen-40': '#54B749',
    'darkgreen-60': '#4CA856',
    'darkred-40': '#D03C3C',
    'blue-100': '#0062ff'
};

const colorMixins = _.reduce(_.keys(colors), (colorMixins, colorLabel) => {

    colorMixins[`bg-${colorLabel}`] = {
        master: {
            backgroundColor: colors[colorLabel]
        }
    };

    colorMixins[`text-${colorLabel}`] = {
        master: {
            color: colors[colorLabel],
        }
    }

    return colorMixins;
}, {});

class InteriorDesigner {

    constructor({ overridingStylesheet, colorPallete }) {
        this.overridingStylesheet = overridingStylesheet;
        this.colorPallete = { ...colors, ...colorPallete };
    }

    isMobile = () => {
        let mobileWidth = window.matchMedia("(max-width: 700px)")
        let value = mobileWidth.matches
        return value
    }

    parentStylesheet(stylesheet) {
        this._stylesheet = stylesheet;
        return this;
    }

    getSheet(elementId, parentStyle = {}) {
        let sheet = parentStyle;

        const stylesheet = {
            ...colorMixins
        };
        _.merge(stylesheet, this._stylesheet);
        _.merge(stylesheet, this.overridingStylesheet);


        let relatedKeys = _.filter(_.keys(stylesheet), key => {
            return _.last(key.split('.')) === _.last(_.split(elementId, '.'));
        })


        relatedKeys = _.sortBy(relatedKeys, (key) => {
            return _.findIndex(_.keys(key), elementId)
        })

        _.each(relatedKeys, key => {
            _.merge(sheet, stylesheet[key])
        })

        return sheet;
    }

    getDisabledStyles = () => {
        return ({
            opacity: 0.6,
            cursor: 'not-allowed',
            pointerEvents: 'none'
        })
    }
    
    getStyles(elementId, parentStyle = {}, options = {}) {

        const sheet = this.getSheet(elementId);        
        let reqStyles = sheet.master || {};
        let stylesFromOptions = {}
        if (this.isMobile()) {
            _.merge(reqStyles, sheet.mobile);
        }

        if (options.disabled) {
            stylesFromOptions = this.getDisabledStyles()
        }
        return { ...parentStyle, ...reqStyles, ...stylesFromOptions };
    }

    getChildrenStylesheet(elementId) {
        const elementIds = _.keys(this._stylesheet);

        const childElementIds = _.filter(elementIds, eId => (_.includes(eId, elementId) && !_.endsWith(eId, elementId)))
        return _.pick(this._stylesheet, childElementIds)
    }


    getConfig(elementId) {
        const sheet = this.getSheet(elementId);        
        return _.get(sheet, 'config', {});
    }

    getPropsFromConfig(elementId) {
        const config = this.getConfig(elementId)
        return _.get(config, 'props', {})
    }

    getTransition(elementId) {

        const sheet = this.getSheet(elementId);
        let transitionName = sheet.transition;

        if (transitionName) {

            const Component = transitionRegistry[_.replace(transitionName, 'children-', '')];
            return { Component, toChildren: _.startsWith(transitionName, 'children-') };

        } else return null;
        // return { ...parentStyle, ...reqStyles };
    }

}


export default new InteriorDesigner({
    overridingStylesheet: config.getConfig('overridingStylesheet'),
    colorPallete: config.getConfig('colorPallete')
});

