import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import withSpinner from '@web-bricks/Shared/components/withSpinner';
import './index.css';
import 'react-virtualized/styles.css';
import toolkit from '@toolkit';
import * as Bits from '@toolkit/bits';
import Widgets from '@toolkit/widgets';

toolkit.setNativeDependecies({
  Router,
  withSpinner: withSpinner,
  Bits,
  Widgets,
  UI: {
    // components,
  },
})

const App = require('./App').default;


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
