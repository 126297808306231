import api from '@api';
import _ from 'lodash';
import tokenManager from '@utils/lib/tokenManager'

class DraftsHelper {
    constructor() {
        //console.log(66)
    }

    create = async (values) =>{
        if(values.schedule === 'recurrence'){
            let body = {
                frequency : values.frequency,
                ...values.recurrence,
                ...values.__recurrence[0].recurrence
            }
            let recObject = await api.CronJobs.getRecObject(body)
            values.recObj = recObject
        }
        await api.NotificationDrafts.createDraft(values)
    }

    getAllDrafts = async () =>{
        let notificationDrafts = await api.NotificationDrafts.find({query:{}, options:{expandFields: {
            templateId : 'template'
        }}});
        return notificationDrafts
    }

    resendDraft = async (draftId) =>{
        await api.NotificationDrafts.resendDraft(draftId)
    }
}

export default DraftsHelper
