import React from 'react';
import _ from 'lodash';
import Entities from "@toolkit/entities";
import InventoryMedia from "@core/modules/Inventory/views/Pieces/inventoryMedia";
import withCart from "@core/modules/Cart/views/lib/withCart";

class PrimaySelfItem extends React.Component {


    constructor(props) {
      super(props)
    }
  
    renderSurface(values, {
      Layout,
      Block,
      Bits,
    }) {
      const { shelfItem } = this.props;
      return (
        <Layout
          name="front-courousel"
        >
          <Block name="priamry-image-block" >
            <InventoryMedia
              media={shelfItem.media}
              name="primary-item-image"
              onClick={this.expandView}
            />
          </Block>
          <Block name="primary-item-name-block">
            <Block>
              <Bits.Text name="primary-block-title" type="p">
                Exclusives
              </Bits.Text>
            </Block>
            <Bits.Text onClick={this.expandView} name="name" type="p">
              {_.capitalize(shelfItem.label)}
            </Bits.Text>
            <Bits.Text name="description" type="p">
              {_.capitalize(shelfItem.description)}
            </Bits.Text>
            <Bits.Text
              name="moreInfo"
              type="p"
              onClick={this.expandView}
            >
              Know More
            </Bits.Text>
          </Block>
          {this.renderExpandView()}
        </Layout>
      );
    }
  
    getStylesheet = (colorPallete) => ({
      "front-courousel": {
        master: {
          maxWidth: 700,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "auto",
        },
        mobile: {
          justifyContent: "flex-start",
          alignItems: "flex-start",
          flexDirection: "column",
          width: "80%",
          margin: "0px 30px",
        },
      },
      "primary-shelf-block": {
        master: {
          height: "calc(100vh - 300px)",
          width: "100%",
          minHeight: 300,
        },
      },
      "primary-item-name-block": {
        master: {
          marginLeft: 30,
        },
        mobile: {
          margin: 0,
        },
      },
      "primary-item-name-block.name": {
        master: {
          borderRadius: 4,
          textTransform: "capitalize",
          padding: "10px 0px",
          fontSize: 34,
          fontWeight: 600,
          margin: 0,
          // color: colorPallete.bg,
          color: colorPallete.white,
          letterSpacing: 0.8,
        },
        mobile: {
          fontSize: 24,
          margin: 0,
        },
      },
      "primary-item-info": {
        master: {
          padding: "0px 40px",
        },
        mobile: {
          padding: "0px 30px",
        },
      },
      price: {
        master: {
          textAlign: "center",
        },
      },
      image: {
        master: {
          width: 140,
          objectFit: "contain",
          borderRadius: 4,
        },
      },
      "priamry-image-block": {
        master: {
          width: 200,
          height: 200,
          background: "unset",
          padding: 20,
          borderRadius: 4,
          marginLeft: 50,
        },
        mobile: {
          width: 150,
          height: 150,
          marginLeft: 0,
        },
      },
      "primary-item": {
        master: {
          display: "flex",
          flexDirection: "column",
          padding: "0px 40px",
          cursor: "pointer",
        },
        mobile: {
          display: "flex",
          padding: "0px 30px",
        },
        transition: "children-children-",
      },
      "primary-item-image": {
        master: {
          width: "100%",
          height: "100%",
          objectFit: "contain",
          borderRadius: 4,
        },
      },
      "primary-block-title": {
        master: {
          backgroundColor: colorPallete.teritary,
          width: 95,
          fontWeight: 600,
          padding: "5px 0px 5px 16px",
          fontSize: 10,
          letterSpacing: 1.8,
          textTransform: "uppercase",
          margin: 0,
          borderRadius: 50,
          color: colorPallete.white,
          opacity: 0.9,
        },
        mobile: {
          width: 89,
          padding: "4px 0px 4px 16px",
          margin: "25px 6px 0px 0px",
          fontSize: 11,
          letterSpacing: 1,
        },
      },
      "block-title": {
        master: {
          color: colorPallete.primary,
          fontWeight: 600,
          fontSize: 30,
          letterSpacing: 1,
          fontFamily: "initial",
          margin: 0,
          opacity: 0.95,
        },
        mobile: {
          margin: "10px 20px 0px",
          fontSize: 24,
        },
        transition: "slideDown",
      },
      "block-subtitle": {
        master: {
          fontSize: 16,
          color: colorPallete.primary,
          margin: 0,
        },
        mobile: {
          fontSize: 12,
          margin: "5px 0px",
          opacity: 0.8,
        },
      },
      "primary-shelf.block-header": {
        master: {
          fontSize: 16,
        },
        transition: "children-slideRight",
      },
      "primary-shelf": {
        master: {
          // display: 'flex',
          alignItems: "center",
          padding: "20px 0px",
          margin: "20px auto",
          // backgroundColor: '#f1e5d9',
          // backgroundColor: '#f3e8de',
          backgroundColor: colorPallete.primary,
          justifyContent: "center",
          borderRadius: 6,
          width: "100%",
        },
        mobile: {
          // display: 'flex',
          // flexDirection: 'column',
          // alignItems: 'unset',
          padding: "50px 0px",
          width: "100%",
          borderRadius: 0,
        },
        transition: "children-slideUp",
      },
    })
  }
  
export default Entities.createThing(withCart(PrimaySelfItem), {
    name: 'PrimaySelfItem'
  })