import React, { Component } from 'react';
import _ from 'lodash';
import Entities from '@toolkit/entities';
import Form from "@web-bricks/Shared/form/components/form";
import FormMaker from '@web-bricks/Shared/form/components/formMaker';
import Container from "@core/modules/shared/views/Things/container.js";
import Helpers from '@core/helpers';
import config from '@config';
// const html = require('../../../../../../../emailTemplate.html')
class AddTemplate extends Component {
    constructor(props) {
      super(props)
      this.state = {
          templateId : props.match.params._id,
      };
      this.templateHelper = new Helpers.TemplateHelper()
    }

    async getData() { 
        if(this.props.match.params._id){
        const template = await this.templateHelper.getTemplateDetails(this.state.templateId)
        this.setState({
            template: template,
            templateType : this.props.match.params._id && template.type 
        })
    }
    const smsTypes = [{
        label: "Free delivery",
        text: `${config.getConfig().shop.name} - Get free delivery. Shop above ₹2000 to avail the offer. Shop now at ${config.getConfig().shop.webAddress}`,
        value: 'FREE-DELIVERY'
    },
    {
        label: "Flash sale",
        text: `${config.getConfig().shop.name} - Exclusive flash sale is on now! Get up to 20% discount by shopping above ₹5000. Shop now at ${config.getConfig().shop.webAddress} `,
        value: 'SALE'
    },{
        label: 'Coupons & discounts',
        text: `${config.getConfig().shop.name} - Get up to 20% OFF by using code OFFER-CODE by shopping above 5000. Ofer apply site-wide. Shop now at ${config.getConfig().shop.webAddress} to avail the offer`,
        value: 'COUPON'
    },{
        label : 'Custom',
        text : ' ',
        value: 'CUSTOM'
    }]
    this.setState({
        smsTypes : smsTypes,
    })
    };
    

    onChange = (e, templateType) => {
        this.setState({
            templateType
        })
    }

    onSubmit = async (values) => {
        if(this.props.match.params._id){
            await this.templateHelper.UpdateTemplate(this.props.match.params._id, {
                ...values
            })
        }else{
            await this.templateHelper.addNew({
                ...values
            })
        }
        values.onSuccess()
        this.props.history.goBack()
    } 

    onCategorySelect = (e) =>{
        const obj = _.find(this.state.smsTypes, {value : e.target.value})
        this.setState({
            template : {
                subject : obj.label,
                body : obj.text
            }
        })
    }

  renderSurface(values, {
    MainLayout,
    Layout,
    Block,
    View,
    Bits
  }) {
    return (
        <MainLayout>
            <Container 
                page={{ title:this.props.match.params._id ? 'Update Template' :'Add Template' }}
                previousPage={[{title:'Templates', onClick: () => this.props.history.push('/vendor/templateList')}]}
                activePage={this.props.match.params._id ? 'Update Template' : 'Add Template'}
            >   
                <View>
                    <Layout>
                        {/* <iframe srcdoc={html} /> */}
                        <Block>
                            <Form submitButton={{ label: this.props.match.params._id ?"Update" :"Add", variant: "contained"    }}  onSubmit={this.onSubmit}   >
                                <FormMaker fields={[
                                    {
                                        type: 'radioGroup',
                                        title: 'Type',
                                        props: {
                                            name: "type",
                                            required: true,
                                            list: [
                                                {
                                                    label: "SMS",
                                                    value: "SMS"
                                                },
                                                {
                                                    label: "Email",
                                                    value: "EMAIL",
                                                }
                                            ],
                                            defaultValue : this.state.template? this.state.template.type : '',
                                            onChange: this.onChange,
                                            }
                                    },
                                    this.state.templateType === 'SMS' && {
                                        type: 'radioGroup',
                                        title: 'Select',
                                        props: {
                                            name: "subject",
                                            displayInRow: true,
                                            required: true,
                                            list: this.state.smsTypes,
                                            defaultValue : this.state.template? this.state.template.type : 'CUSTOM',
                                            onChange: this.onCategorySelect,
                                            }
                                    },
                                    this.state.templateType === 'EMAIL' && {
                                        type: 'textField',
                                        title: 'Subject',
                                        props: {
                                            //required: true,
                                            name: 'subject',
                                            defaultValue : this.state.template ? this.state.template.subject : ' '
                                        }
                                    },
                                     this.state.templateType === 'EMAIL' && {
                                        type: "HTMLInput",
                                        title: "HTML Message",
                                        props: {
                                            required: true,
                                            name: 'body',
                                            label: 'Custom Message',  
                                            defaultValue : this.state.template? this.state.template.body : ' ' 
                                        }
                                    },         
                                    this.state.templateType === 'SMS' && {
                                        type: "textArea",
                                        title: "Send Notification",
                                        props: {
                                            required: true,
                                            name: 'body',
                                            label: 'Message',
                                            rows: 4,
                                            placeHolder: "Write Your Message within 160 Characters",
                                            style:{ width:600 },
                                            defaultValue : this.state.template? this.state.template.body : ''
                                        }
                                    },       
                                ]} />       
                            </Form> 
                        </Block>
                    </Layout>
                </View>
            </Container>
        </MainLayout>
    )
  }
}

export default Entities.createPlace(AddTemplate, {
    name: 'AddTemplate'
});