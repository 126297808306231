import React from "react"
import _ from 'lodash';
import tokenManager from '@utils/lib/tokenManager';
import Avatar from '@web-bricks/Shared/view/components/customAvatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Helper from '@core/helpers'
import Entities from '@toolkit/entities';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
// import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import config from '@config/index';
import Button from '@web-bricks/Shared/Input/button';
import interiorDesigner from "@toolkit/lib/interiorDesigner";
import { AccountCircleOutlined, FileCopyOutlined, MoreHoriz, Share } from "@material-ui/icons";
import copy from 'copy-to-clipboard';
import Container from '@core/modules/shared/views/Things/container.js';
// import EditIcon from '@material-ui/icons/Edit';
// import Select from '@web-bricks/Shared/Input/select';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MenuButton from '@web-bricks/Shared/view/components/menuButton';
// import persistentStorage from '@utils/lib/localStorage';


class Profile extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            profileId: tokenManager.user,
            user: {}
        }
        this.helper = new Helper.UserSigninHelper()
        this.userHelper = new Helper.UserHelper()
        this.orderHelper = new Helper.OrderHelper()
        this.signInHelper = new Helper.UserSigninHelper()
    }

    async getData() {
        if (tokenManager.user) {
            const user = await this.helper.getUserById(tokenManager.user);
            if (user) {
                if(tokenManager.type === 'ADMIN'){
                    this.props.history.push('/vendor/ManageAccount')
                }else if(tokenManager.type === 'CUSTOMER'){
                    const AllOrders = await this.orderHelper.sortBydate()
                    this.setState({
                        user: user,
                        AllOrders: AllOrders
                    })

                    // if referralCode has not been generated
                    if (!user.referralCode) {
                        this.userHelper.referrals.createCode(user._id)
                    }
                    return;
                }else{
                    this.props.history.push('/')
                }
            }
        }

        this.setState({
            noProfile: true
        })
    }

    getLabelValues = (_, transformers) => {
        return ({
            userName: _.get(this.state.user, 'name'),
            userPhone: _.get(this.state.user, 'phone'),
            userEmail: _.get(this.state.user, 'email'),
            referralCode: _.get(this.state.user, 'referralCode')
        })
    }

    gotoOrders = () => {
        this.goToView('AllOrders')
    }

    gotoAddressbook = () => {
        this.goToView('manageAddresses')
    }

    logout = () => {
        this.signInHelper.signOut()
        this.props.history.push('/')
    }

    renderEmptyProfile = ({ Layout, Block, Bits }) => {
        return (
            <Layout name="empty-profile-container">
                <Block>
                    <AccountCircleOutlined style={{
                        fontSize: 200,
                        opacity: 0.5,
                        color: interiorDesigner.colorPallete.primary,
                        margin: 'auto',
                    }} />
                </Block>
                <Block name="empty-caption">
                    <Bits.Text>
                        Sign In to view your profile
                   </Bits.Text>
                </Block>
                <Block>
                    <Button variant="contained" color="primary" fullWidth size={'large'} onClick={() => this.goToView('/account/signin')} style={{ margin: 'auto' }}>Sign In</Button>
                </Block>
            </Layout>
        );
    }
    copycoupon = (value) => {
        copy(value)
    }
    shareCouponCode = () => {
        window.navigator.share(config.getConfig('shop.referrals.inviteSMS'))
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
        Bits,
        View,
        Switch,
        interiorDesigner
    }) {
        return (
            
            <MainLayout name="profile-page">
                {
                    this.state.noProfile ?
                        this.renderEmptyProfile({ Layout, Block, Bits }) :
                        <Container
                            page={{ title: 'Profile' }}
                            actionBar={
                                <MenuButton
                                    items={{
                                        list: [{
                                            label: 'Edit',
                                            onClick: () => this.goToView('edit')
                                        }, {
                                            label: 'Sign out',
                                            onClick: () => {this.logout()}
                                            
                                        }]
                                    }}
                                />
                            }
                        >
                    
                        <View>
                            <Layout name="profile-container">
                                <Layout>
                                    {/* <Block name="profile-page-header">
                                        <Bits.Text type="p" name="profile-header">Profile</Bits.Text>
                                    </Block> */}
                                    <Block name='profile-block-layout'>
                                            <Layout name="name-layout">
                                                <Block name='profile'>
                                                    <Avatar user={this.state.user} />
                                                </Block>
                                                <Block name='profile-details'>
                                                    <Bits.Text name='profile-name'>{_.upperFirst(values.userName)}</Bits.Text>
                                                    {values.userEmail !== null && <Bits.Text name='profile-email'>{values.userEmail}</Bits.Text>}
                                                    <Bits.Text name='profile-phone'>{values.userPhone}</Bits.Text>
                                                </Block>
                                            </Layout>
                                    </Block>
                                    <Layout>
                                        <Block name='profile-block-layout'>
                                            <Bits.Text name='header-title'>STORE</Bits.Text>
                                            <List component="nav" aria-label="profile">
                                                <ListItem button onClick={this.gotoOrders}>
                                                    <ListItemIcon>
                                                        <Block name='order-bg'></Block>
                                                    </ListItemIcon>
                                                    <ListItemText primary='Orders' secondary='Look at your past and ongoing orders from your store' />
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" aria-label="comments">
                                                            <ArrowForwardIosIcon fontSize="small" style={{ color: 'grey', opacity: 0.9 }} />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <ListItem button onClick={this.gotoAddressbook}>
                                                    <ListItemIcon>
                                                        <Block name='address-bg'></Block>
                                                    </ListItemIcon>
                                                    <ListItemText primary='Address Book' secondary='Add or remove address and set your delivery address' />
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" aria-label="comments">
                                                            <ArrowForwardIosIcon fontSize="small" style={{ color: 'grey' }} />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </List>
                                        </Block>
                                    </Layout>
                                     {/*<Layout>
                                        <Block name='main-layout'>
                                            <Bits.Text name='header-title'>ACCOUNT</Bits.Text>
                                            <List component="nav" aria-label="profile">
                                                <ListItem button onClick={this.logout}>
                                                    <ListItemIcon>
                                                        <ExitToAppOutlinedIcon style={{ color: interiorDesigner.colorPallete.backLinkTextColor }} />
                                                    </ListItemIcon>
                                                    <ListItemText primary='Sign out' style={{ color: interiorDesigner.colorPallete.backLinkTextColor }} />
                                                </ListItem>
                                            </List> 
                                        </Block>
                                    </Layout>*/}
                                </Layout>

                            </Layout>
                            {
                                !_.isEmpty(config.getConfig('shop.referrals')) &&
                                    <Layout name='referral-Layout'>
                                
                                <Layout name='header-Layout'>
                                    <Block name='header'>
                                        <Bits.Text name='header-referral'>{config.getConfig('shop.referrals.profileBlock.description')}</Bits.Text>
                                    </Block>
                                    <Block name='caption'>
                                        <Bits.Text name='caption-text'>
                                            {config.getConfig('shop.referrals.profileBlock.detail')}
                                        </Bits.Text>
                                    </Block>
                                </Layout>
                                <Layout name='referral-code-Layout'>
                                    <Block name='copy-text'>
                                        <Bits.Text name='copy-text'>Copy your code</Bits.Text>
                                    </Block>
                                    <Block name='referral-text'>
                                        <Bits.Text name='referral-code'>{values.referralCode}</Bits.Text>
                                    </Block>
                                    <Block name='copy-icon'>
                                        <Button style={{ margin: 0, padding: 0, minWidth: "100%", }} onClick={() => this.copycoupon(values.referralCode)}><FileCopyOutlined style={{ color: 'grey', fontSize: 18, paddingTop: 10 }} fontSize='small' />
                                        </Button>
                                    </Block>
                                </Layout>
                                <Layout>
                                    <Block name='Share-Button'>
                                        <Button variant='contained' color='secondary' style={{ boxShadow: 'unset', textTransform: 'capitalize' }} startIcon={<Share />} onClick={this.shareCouponCode}>Share</Button>
                                    </Block>
                                    <Block name='referral-bg'></Block>
                                </Layout>
                            </Layout>
                            }

                        </View>
                    </Container>
                }
            </MainLayout>
        )
    }

    getStylesheet = (colorPallete) => {
        return ({
            'empty-profile-container': {
                master: {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center'
                },
                transition: 'children-slideRight',
            },
            'empty-caption': {
                textAlign: 'center',
                fontSize: 20,
                fontWeight: 'bold',
                color: colorPallete.primary
            },
            'list-title': {
            },
            'profile-options-list': {
                master: {
                    width: '100%',
                    listStyle: 'none'
                },
            },
            'edit-bg': {
                master: {
                    backgroundImage: `url(${require('@core/assests/images/Icons/edit.png').default}`,
                    width: 24,
                    height: 24,
                    // height:100,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    cursor: 'pointer',
                    opacity: 0.9,
                    paddingRight: 20,
                },
                mobile: {
                    width: 18,
                    height: 18
                }
            },
            'referral-bg': {
                master: {
                    backgroundImage: `url(${require('@core/assests/images/referral.png').default}`,
                    width: 450,
                    height: 155,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    right: 250,
                    top: 81,
                    zIndex: -1,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    position: 'absolute',
                },
                mobile: {
                    width: '100%',
                    height: 103,
                    left: 0,
                    top: 0,
                    right: 'unset',
                    zIndex: 0,
                    position: 'relative',
                }
            },
            'back-button': {
                master: {
                    display: 'flex',
                    alignItems: 'center',
                    padding: '0px 20px',
                    cursor: 'pointer'
                },
            },
            'back': {
                master: {
                    fontSize: 20,
                    margin: '5px 0px'
                }
            },
            'order-bg': {
                master: {
                    backgroundImage: `url(${require('@core/assests/images/Icons/orderBox.png').default}`,
                    width: 40,
                    height: 40,
                    // height:100,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    opacity: 0.9
                }
            },
            'address-bg': {
                master: {
                    backgroundImage: `url(${require('@core/assests/images/Icons/addressBook.png').default}`,
                    width: 40,
                    height: 40,
                    // height:100,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    opacity: 0.9
                }
            },
            'profile-page': {
                master: {
                    backgroundColor: colorPallete.white,
                }
            },
            
            'profile-container': {
                master: {
                    width: 800,
                    margin: '0px auto',
                    padding: 20,
                },
                mobile: {
                    width: '100%',
                    padding: 10,
                },
                transition: 'slideRight'
            },
            'profile-page-header': {
                master: {
                    display: 'flex',
                    alignItems: 'center',
                }
            },
            'profile-card': {
                master: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                },
            },
            'name-layout': {
                master: {
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1
                },
            },
            'profile-header': {
                master: {
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    fontSize: 30,
                    textAlign: 'center',
                    zIndex: -1
                },
                mobile: {
                    margin: 0
                }
            },
            'header-title': {
                master: {
                    fontSize: 13,
                    margin: 0,
                    marginLeft: 18,
                    color: 'grey'
                },
                transition: 'slideRight'
            },
            'profile-block-layout': {
                master: {
                    padding: '20px 0px'
                }
            },
            'back': {
                master: {
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                },
                mobile: {
                    fontSize: 16
                }
            },
            'profile-details': {
                master: {
                    marginLeft: 10
                }
            },
            'profile': {
                master: {
                    display: 'flex',
                    padding: '0px 10px 0px'
                }
            },
            'profile-name': {
                master: {
                    fontSize: 20,
                    margin: '5px 0px'
                }
            },
            'profile-email': {
                master: {
                    fontSize: 13,
                    color: 'grey',
                    margin: 1,
                    padding: '2px 0px'
                }
            },
            'profile-phone': {
                master: {
                    fontSize: 13,
                    color: 'grey',
                    margin: 1,
                    padding: '2px 0px'
                }
            },
            'referral-Layout': {
                master: {
                    backgroundColor: colorPallete.bg,
                    padding: '30px 50px',
                    position: 'relative',
                    height: 235,
                    zIndex: 0,
                    border: '1px dashed',
                    borderColor: colorPallete.primary,
                    boxSizing: 'border-box'
                },
                mobile: {
                    padding: '30px 40px 0px',
                    height: '100%',
                    marginBottom: 90
                },
                transition: 'slideRight'
            },
            'header-referral': {
                master: {
                    fontSize: 18,
                    color: colorPallete.darkGrey,
                    fontWeight: 'bold',
                    margin: 0,
                    lineHeight: '20px',
                    letterSpacing: 0.57,
                    paddingBottom: 1,
                }
            },
            'caption-text': {
                master: {
                    fontSize: 16,
                    paddingTop: 5,
                    color: colorPallete.darkGrey,
                    margin: 0
                },
                mobile: {
                    fontSize: 14
                }
            },
            'detail-text': {
                master: {
                    fontSize: 14,
                    paddingTop: 3,
                    color: colorPallete.subtext,
                    margin: 0
                },
                mobile: {
                    fontSize: 14
                }
            },
            'referral-code-Layout': {
                master: {
                    display: 'flex',
                    alignItems: 'center',

                }
            },
            'copy-text': {
                master: {
                    fontSize: 16,
                    paddingRight: 5,
                    paddingBottom: 0,
                    color: colorPallete.darkGrey,
                }
            },
            'referral-text': {
                master: {
                    paddingRight: '3px',
                    fontWeight: 'bold',
                    fontSize: 16
                }
            },
            'Share-Button': {
                master: {
                    // color: 'white',
                    // backgroundColor: colorPallete.teritary
                },
                mobile:{
                    marginBottom: 20
                }
            }
        })
    }

}

export default Entities.createPlace(Profile, {
    name: 'Profile'
});
