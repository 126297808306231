import React from "react"
import InventoryCard from './inventoryCard';
import _ from 'lodash';
import Helper from '@core/helpers';
import Entities from '@toolkit/entities';
import ItemModal from "@core/modules/Inventory/views/Things/itemModal";


class InventoriesList extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            group: false
        }

        this.renderInventoryBlock = this.renderInventoryBlock.bind(this);
    }


    getLabelValues = (_, transformers) => {
        return ({
        })
    }

    cartUpdate = (count, cartItem) => {
        Helper.CartHelper.updateCart(count, cartItem)
    }

    imageClick = (group) => {
        this.props.onClick(group)
        this.setState({
            group: group
        })
    }

    renderInventoryBlock(Block) {
        const self = this;
        return function (group, index) {
            return (
                <Block name='item-block' key={index}>
                    <InventoryCard
                        index={index}
                        key={index}
                        group={group}
                        onClick={() => self.imageClick(group)}
                        onCountChange={self.cartUpdate}
                    />
                </Block>
            )
        }
    }

    onClose = (group) => {
        this.setState({
            group: false
        });
        this.props.onClose()
    }

    renderSurface(values, {
        MainLayout,
        Layout,
        Block,
    }) {
        return (
            <MainLayout>
                <Layout>
                    <Block name='items-layout'>
                        <Layout name="items-grid-container" >
                            {
                                _.map(this.props.groupedinventories, this.renderInventoryBlock(Block))
                            }
                        </Layout>
                    </Block>
                </Layout>
                {
                    this.state.group && <ItemModal
                        open={this.state.group}
                        onBack={this.onClose}
                        cart={this.props.cart}
                        cartHelper={Helper.CartHelper}
                        onClick={this.imageClick}
                        onCountChange={this.cartUpdate}
                        onStackEmpty={this.onClose}
                        group={this.state.group}
                        groupedinventories={this.props.groupedinventories}
                    />
                }
            </MainLayout>
        )
    }
    getStylesheet = () => {
        return ({
            'item-block': {
                master: {
                    margin: 20,
                    cursor: 'pointer',
                    display: 'inline-block'
                },
                mobile: {
                    height: 270,
                    margin: 5
                }
            },
            'items-grid-container': {
                master: {
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(230px, 1fr))'
                },
                mobile: {
                    gridTemplateColumns: 'repeat(auto-fill, minmax(calc(50vw - 50px), 1fr))'
                },
            },
            'items-layout': {
                master: {
                    padding: '10px 0px 10px',
                    overflow: 'auto',
                    height: '100%'
                },
                mobile: {
                    padding: '10px 0px 95px 0px',
                    height: 'auto'
                }
            }
        })
    }
}

InventoriesList.defaultProps = {
    onClick: _.noop,
    onClose: _.noop
}


export default Entities.createThing(InventoriesList, {
    name: 'InventoriesList'
});
