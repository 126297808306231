import _ from 'lodash';
import persistentStorage from './localStorage';

class TokenManager {
    constructor() {
        this.handler = this.handler.bind(this);
        this.init = this.init.bind(this);
        this.isLoggedIn = this.isLoggedIn.bind(this);
        this.onChangeHooks = [];
    }

    setStorage = (storage) => {
        this.storage = storage;
    }

    init(config) {
        _.extend(this, config);
        // save it persistently
        persistentStorage.set(_.mapValues(config, value => value || ''));
        _.each(this.onChangeHooks, hook => {
            try {
                hook();
            } catch (err) {
                console.log(err);
            }
        })
    }

    isStaff = () =>{
        
        if(this.type === "STAFF" || this.type === "ADMIN"){
            // console.log(1)
            return true
        }else return false
    }

    wakeup() {
        const user = persistentStorage.get('user');
        const token = persistentStorage.get('token');
        const name = persistentStorage.get('name');
        const email = persistentStorage.get('email')
        const phone = persistentStorage.get('phone')
        const type = persistentStorage.get('type')
        _.extend(this, { user, token, name, email, phone, type });
    }
    
    reset() {
        this.phone = null;
        this.user = null;
        this.name = null;
        persistentStorage.remove('user');
        persistentStorage.remove('token');
        this.storage.remove('user');
        this.storage.remove('token');
        this.onChange();
    }

    onChange = () => {
        _.each(this.onChangeHooks, hook => {
            try {
                hook(this);
            } catch (err) {
                console.log(err);
            }
        });
    }

    isLoggedIn() {
        return !!this.user;
    }

    addOnChangeHook(fn) {
        this.onChangeHooks.push(fn);
    }

    handler(request) {
        request.set("Content-Type", "application/json")
        if (this.token) {
            request.set('Authorization', `Bearer ${this.token}`);
        }
        return request;
    }
}

export default new TokenManager();
